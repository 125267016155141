<template>
    <div
        class="return-link"
        :class="{
            'return-link--hidden': isHidden
        }"
    >
        <transition
            name="fade-absolute"
            mode="out-in"
        >
            <router-link
                v-if="returnLink && returnLink.route"
                :key="returnLink.label"
                :to="returnLink.route"
                class="return-link__link"
            >
                <svg-icon icon="chevron-left" /> {{ returnLink.label || '' }}
            </router-link>

            <span
                v-else-if="isHomePage"
                key="title"
                class="return-link__title"
            >
                {{ community.name || 'LawyerlinQ' }}
            </span>
        </transition>
    </div>
</template>

<script>
'use strict';

export default {
    computed: {
        community() {
            return this.$store.getters['community/community'];
        },

        returnLink() {
            const returnLink = this.$store.getters['ui/returnLink'];

            if (this.isHomePage) {
                return false;
            }

            if (returnLink && returnLink.route) {
                return returnLink;
            }

            return {
                label: this.community.name || 'LawyerlinQ',
                route: {
                    path: '/'
                }
            };
        },

        isHidden() {
            const hiddenRoutes = [
                'dashboard'
            ];

            return hiddenRoutes.includes(this.$route.name);
        },

        isHomePage() {
            const homeRoutes = [
                'dashboard',
                'community-portal.index'
            ];

            return homeRoutes.includes(this.$route.name);
        }
    }
};
</script>

<style lang="less">
@import './return-link.less';
</style>
