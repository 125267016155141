<template>
    <banner
        v-if="isClient()"
        :icon="null"
        :title="$t('banner.lawyers.title')"
        class="no-results--large"
    >
        <template v-slot:content>
            <p>
                {{ $t('banner.lawyers.intro') }}
            </p>

            <ul
                class="list"
                v-html="$t('banner.lawyers.suggestions')"
            />

            <card
                status="open"
                class="no-results__card"
            >
                <template v-slot:body>
                    <strong>{{ $t('banner.lawyers.card.intro') }}</strong>
                    <p>{{ $t('banner.lawyers.card.body') }}</p>
                </template>
                <template v-slot:actions>
                    <router-link
                        class="btn btn--secondary"
                        :to="{name: $router.currentRoute.name}"
                    >
                        {{ $t('banner.lawyers.card.btn') }}
                    </router-link>
                </template>
            </card>

            <strong>{{ $t('banner.lawyers.need-help.title') }}</strong>
            <p>{{ $t('banner.lawyers.need-help.body') }}</p>
            <ul class="list list--clean">
                <li>
                    <a href="tel:+31203080006"><icon
                        icon="phone"
                        class="icon--large"
                        fill
                        bottom
                    />&nbsp;&nbsp;020 - 3080006</a>
                </li>
                <li>
                    <a href="mailto:moderator@lawyerlinq.com"><icon
                        icon="message"
                        class="icon--large"
                        fill
                        bottom
                    />&nbsp;&nbsp;moderator@lawyerlinq.com</a>
                </li>
            </ul>
        </template>
    </banner>
</template>

<script>
'use strict';

import Icon from '~/patterns/atoms/icon/icon.vue';
import Banner from '~/patterns/molecules/banner/banner.vue';
import Card from '~/patterns/molecules/card/card.vue';

export default {
    components: {
        Banner, Card, Icon
    }
};
</script>
