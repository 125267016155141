'use strict';

export default {
    users(state, users) {
        state.users = users;
    },

    filteredUsers(state, users) {
        state.filteredUsers = users;
    },

    pendingUsers(state, pendingUsers) {
        state.pendingUsers = pendingUsers;
    },

    setUser(state, user) {
        state.user = Object.assign({}, state.user, user);
    },

    selectedLawyerAndSupplierIds(state, userIds) {
        if (!userIds) {
            return false;
        }

        state.selectedLawyerAndSupplierIds = userIds;
    }
};
