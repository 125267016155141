
<!-- TODO: Add modal here -->
<span
    v-if="hasSupplier"
    class="supplier-info"
    :class="{
        'supplier-info--standalone': !inline
    }"
>
    <template v-if="inline">
        {{ $t('supplierInfo.description', { supplier: user.supplier.title }) }}
    </template>

    <template v-else>
        <svg-icon
            class="supplier-info__icon"
            icon="colleague"
        />
        {{ user.supplier.title }}
    </template>
</span>
