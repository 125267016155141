
<nav class="table-of-contents">
    <ul>
        <li
            v-for="(link, index) in links"
            :key="index"
            class="table-of-contents__item"
            :data-active="activeLinks.includes(link)"
        >
            <button
                type="button"
                @click="scrollToAnchor(link)"
            >
                {{ link.label }}
            </button>
        </li>
    </ul>
</nav>
