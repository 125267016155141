
<div
    class="tariff-calculation-total"
    :class="`tariff-calculation-total--${type}`"
>
    <div class="tariff-calculation-total__row tariff-calculation-total__row--fee">
        <div class="tariff-calculation-total__spacer">
            {{ $t('tariffCalculationTotal.message') }}
        </div>

        <template v-if="type === 'range'">
            <span class="tariff-calculation-total__cell tariff-calculation-total__cell--hide-small">
                <template v-if="getTotal(workloads, 'lower_boundary') !== null">
                    {{ $n(getTotal(workloads, 'lower_boundary'), 'currency') }}
                </template>
            </span>
            <span class="tariff-calculation-total__cell tariff-calculation-total__cell--hide-small">
                <template v-if="getTotal(workloads, 'upper_boundary') !== null">
                    {{ $n(getTotal(workloads, 'upper_boundary'), 'currency') }}
                </template>
            </span>
        </template>

        <template v-else>
            <span class="tariff-calculation-total__cell">
                {{ $n(parsedTotal, 'currency') }}
            </span>
        </template>
    </div>
</div>
