<template src="./file-attach.html" />

<script>
'use strict';

import axios from 'axios';
import filesApi from '~/api/files';

export default {
    props: {
        value: {
            type: Object,
            required: false,
            default: undefined
        },
        accept: {
            type: String,
            required: false,
            default: undefined
        },
        hideIcon: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            file: null,
            path: null,
            uploading: false,
            axiosSource: null
        };
    },

    computed: {
        uploadedFile() {
            return this.value;
        }
    },

    watch: {
        value(value) {
            if (!value) {
                this.file = null;
                this.path = null;
            }
        }
    },

    methods: {
        openFileDialog() {
            if (!this.uploading && !this.file) {
                this.$refs.fileInput.click();
            }
        },

        cancelUpload() {
            this.axiosSource.cancel();
            this.uploading = false;
            this.clearFile();
        },

        clearFile() {
            this.$refs.fileInput.value = '';
            // For IE11 and some other browsers
            this.$refs.fileInput.type = '';
            this.$refs.fileInput.type = 'file';
            // Set component state
            this.file = null;
            this.path = null;

            this.setAttachment();
        },

        uploadFile(e) {
            this.uploading = true;
            const files = e.target.files || e.dataTransfer.files;

            if (!files.length) {
                return;
            }

            const formData = new FormData();
            formData.append('file', files[0]);

            const CancelToken = axios.CancelToken;
            this.axiosSource = CancelToken.source();

            return filesApi.upload(formData, {
                cancelToken: this.axiosSource.token
            }).then((response) => {
                this.file = files[0];
                this.path = response.data.path;

                this.setAttachment();
                this.uploading = false;
            }).catch(() => {
                this.$store.dispatch('toast/add', {
                    content: this.$i18n.t('fileAttach.error.generic'),
                    severity: 'error'
                });

                this.uploading = false;
            });

            // @TODO upload to temporary storage here
        },

        setAttachment() {
            if (!this.file || !this.path) {
                return this.$emit('input', null);
            }

            this.$emit('input', {
                name: this.file.name,
                path: this.path
            });
        }
    }
};
</script>

<style lang="less">
@import './file-attach.less';
</style>
