<template>
    <div
        class="avatar"
        :data-actionable="linkToProfile"
        @click="maybeTriggerModal"
    >
        <div
            v-if="user && avatarUrl"
            key="avatar-image"
            v-lazy:background-image="{
                src: avatarUrl
            }"
            class="avatar__image"
        />

        <div
            v-if="!avatarUrl"
            key="avatar-initials"
            class="avatar__initials"
        >
            <slot>{{ initials }}</slot>
        </div>

        <activity-indicator
            :is-active="isUnread"
            class="activity-indicator--bordered"
        />
    </div>
</template>

<script>
'use strict';

import ActivityIndicator from '~/patterns/atoms/activity-indicator/activity-indicator.vue';

export default {
    components: {
        ActivityIndicator
    },

    props: {
        user: {
            type: Object,
            default: null,
            required: false
        },
        linkToProfile: {
            type: Boolean,
            default: true,
            required: false
        },
        size: {
            type: String,
            required: false,
            default: 'small'
        },
        isUnread: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    computed: {
        initials() {
            if (!this.user) {
                return false;
            }

            const firstLetter = this.user.first_name ? this.user.first_name.charAt(0) : '',
                lastLetter = this.user.last_name ? this.user.last_name.charAt(0) : '';

            return `${firstLetter}${lastLetter}`;
        },

        avatarUrl() {
            if (!this.user) {
                return false;
            }

            switch (this.size) {
            case 'small':
                return this.getImage(this.user.avatar_small);
            default:
                return this.getImage(this.user.avatar);
            }
        }
    },

    methods: {
        maybeTriggerModal(event) {
            if (this.linkToProfile) {
                if (event) {
                    event.preventDefault();
                }

                this.$store.dispatch('modal/openModal', {
                    name: 'user-profile',
                    data: { userId: this.user.id }
                });
            }
        }
    }
};
</script>

<style lang="less">
@import './avatar.less';
</style>
