<template>
    <section class="user-profile-header">
        <div class="user-profile-header__avatar">
            <avatar
                :link-to-profile="false"
                size="large"
                :user="user"
                class="avatar--large"
            />
        </div>
        <div class="user-profile-header__personalia">
            <h3 data-margin="none">{{ user.full_name }}</h3>
            <div v-if="user.lawyer_title">
                {{ user.lawyer_title }}

                <supplier-info
                    v-if="hasSupplier"
                    inline
                    :user="user"
                />
            </div>
            <supplier-info
                v-else-if="hasSupplier"
                :user="user"
            />
            <div v-if="user.city"><icon icon="location-pin" /> {{ user.city }}</div>
        </div>
        <div class="user-profile-header__actions" />
        <div class="user-profile-header__languages">
            <!--eslint-disable-next-line vue/no-v-html-->
            <span
                v-if="translatedLanguages"
                v-html="translatedLanguages.join(', ')"
            />
        </div>
        <div class="user-profile-header__tags">
            <tag
                v-for="(field) in user.fields_of_law"
                :key="field"
                class="tag--inline"
            >
                {{ $t(`fieldsOfLaw.${field}`) }}
            </tag>
        </div>
    </section>
</template>

<script>
'use strict';

import { isEmpty, isArray } from 'lodash-es';
import Avatar from '~/patterns/atoms/avatar/avatar.vue';
import Tag from '~/patterns/atoms/tag/tag.vue';
import Icon from '~/patterns/atoms/icon/icon.vue';
import SupplierInfo from '~/patterns/molecules/supplier-info/supplier-info.vue';

export default {
    components: {
        Avatar,
        Tag,
        Icon,
        SupplierInfo
    },

    props: {
        user: {
            type: Object,
            required: true
        }
    },

    computed: {
        translatedLanguages() {
            if (!isEmpty(this.user.business_languages) && isArray(this.user.business_languages)) {
                return this.user.business_languages.map((language) => {
                    return this.$t(`languages.${language}`);
                });
            }
            return null;
        },
        hasSupplier() {
            return this.user.supplier?.length !== 0;
        }
    }
};
</script>

<style lang="less">
@import './user-profile-header.less';
</style>
