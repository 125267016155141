
<default-view>
    <llq-notice
        v-if="$auth.error"
        severity="danger"
    >
        <p
            v-if="$route.query.error === 'access_denied' && $route.query.error_description === 'Signup disabled'"
        >
            <i18n
                path="auth.signup-attempt"
                tag="p"
            >
                <router-link to="/logout">
                    {{ $t('auth.logout') }}
                </router-link>
            </i18n>
        </p>
        <p v-else>
            <strong>{{ $auth.error }}</strong>
            {{ $t('general.error') }}
        </p>
    </llq-notice>
    <loading-indicator v-else />
</default-view>
