
<div
    v-if="tariff && llqFeePercentage !== 0"
    class="tariff-calculation"
>
    <span class="tariff-calculation__label">
        {{ $t('tariffCalculation.message') }}
    </span>
    <span>
        {{ $n(correctedTariff, 'currency') }}
    </span>
</div>
