
<div class="file-upload">
    <vue-dropzone
        :id="id"
        ref="dropzone"
        :use-custom-slot="true"
        :options="dropzoneOptions"
        @vdropzone-success="handleUploadedFile"
        @vdropzone-error="handleFailure"
        @vdropzone-removed-file="handleRemoveFile"
        @vdropzone-max-files-exceeded="handleMaxFilesExceeded"
        @vdropzone-mounted="showPreviouslyUploadedFile"
        @vdropzone-sending="handleSending"
        @vdropzone-complete="$emit('file-uploaded', file)"
    >
        <slot><p>{{ $t('fileUpload.select-file') }}</p></slot>
    </vue-dropzone>

    <span
        v-if="errorMessage"
        class="file-upload__error"
    >
        {{ $t('fileUpload.error') }}
    </span>
</div>
