'use strict';

import Axios from 'axios';

const graphqlAxiosClient = Axios.create({
    baseURL: window.appConfig.graphql.url,
    timeout: 30000, // This should equal server ex. time
    headers: {}
});

export default graphqlAxiosClient;
