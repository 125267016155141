
<div class="community-filters">
    <section v-if="hide.indexOf('title') === -1">
        <h3>{{ $t('communityFilters.title') }}</h3>
    </section>

    <section>
        <accordion :title="$t('communityFilters.filters.assignment-type.title')">
            <checkbox
                v-for="(assignmentType, index) in assignmentTypeOptions"
                :key="index"
                v-model="filterModel.assignment_types"
                class="checkbox--small"
                :value="assignmentType"
            >
                {{ $t(`assignmentTypes.${assignmentType}`) }}
            </checkbox>
        </accordion>

        <accordion :title="$t('communityFilters.filters.lawyer-type.title')">
            <multiselect
                v-model="filterModel.lawyer_types"
                class="multiselect--light"
                :options="lawyerTypeOptions"
                :placeholder="$t('communityFilters.filters.lawyer-type.placeholder')"
                :searchable="false"
                :show-labels="false"
                :multiple="true"
                :custom-label="lawyerTypeLabel"
            />
        </accordion>

        <accordion :title="$t('communityFilters.filters.field-of-law.title')">
            <multiselect
                v-model="filterModel.fields_of_law"
                class="multiselect--light"
                :options="fieldOfLawOptions"
                :placeholder="$t('communityFilters.filters.field-of-law.placeholder')"
                :searchable="false"
                :show-labels="false"
                :multiple="true"
                :custom-label="fieldOfLawLabel"
            />
        </accordion>

        <accordion
            v-if="supplierOptions.length > 0"
            :title="$t('communityFilters.filters.supplier.title')"
        >
            <checkbox
                v-for="(supplier, index) in supplierOptions"
                :key="index"
                v-model="filterModel.suppliers"
                class="checkbox--small"
                :value="supplier.id"
            >
                {{ supplier.title }}
            </checkbox>
        </accordion>

        <accordion :title="$t('communityFilters.filters.business-language.title')">
            <multiselect
                v-model="filterModel.business_languages"
                class="multiselect--light"
                :options="businessLanguageOptions"
                :placeholder="$t('communityFilters.filters.business-language.placeholder')"
                :searchable="false"
                :show-labels="false"
                :multiple="true"
                :custom-label="businessLanguageLabel"
            />
        </accordion>
    </section>

    <section>
        <search-bar />
    </section>
</div>
