
<dn-modal
    name="tender-rounds"
    class="tender-rounds-modal"
    position="centered"
    size="large"
>
    <modal-content>
        <section class="text-center">
            <h2>{{ $t(`modal.tender-rounds.${status}.title`) }}</h2>
        </section>

        <section>
            <notice severity="notice">
                <h4>{{ $t('modal.tender-rounds.notice.title') }}</h4>
                <div>{{ $t('modal.tender-rounds.notice.body') }}</div>
            </notice>

            <p>{{ $t(`modal.tender-rounds.${status}.explanation`) }}</p>

            <form
                data-vv-scope="tender-rounds"
                class="form"
                @submit.prevent="handleSubmit"
            >
                <div class="tender-rounds-modal__date-row">
                    <form-field
                        class="form-field"
                        :error-messages="errors.collect('tender-rounds.end_date')"
                    >
                        <label
                            slot="label"
                            for="date"
                        >
                            {{ $t('modal.tender-rounds.label.end-date') }}
                        </label>

                        <datepicker
                            id="date"
                            ref="date"
                            v-model="endDate"
                            name="date"
                            :disabled-dates="{to: new Date(Date.now() - 86400000)}"
                            :placeholder="$t(`modal.tender-rounds.${status}.placeholder-date`)"
                            data-vv-as="date"
                        />

                        <svg-icon
                            slot="icon"
                            icon="calendar"
                        />
                    </form-field>
                    
                    <form-field
                        class="form-field"
                        :error-messages="errors.collect('tender-rounds.end_time')"
                    >
                        <label
                            slot="label"
                            for="time"
                        >
                            {{ $t('modal.tender-rounds.label.end-time') }}
                        </label>
                        <input
                            id="time"
                            v-model="endTime"
                            type="time"
                        >
                    </form-field>

                    <div
                        v-if="$options.filters.timeRemaining(endDateTime) !== null"
                        class="tender-rounds-modal__time-notice"
                    >
                        {{ $t('modal.tender-rounds.time-notice', { time: $options.filters.timeRemaining(endDateTime) }) }}
                    </div>
                    <div
                        v-else
                        class="tender-rounds-modal__time-notice"
                    >
                        {{ $t('modal.tender-rounds.time-invalid') }}
                    </div>
                </div>

                <form-field>
                    <toggle-switch
                        id="ignore_ends_at"
                        v-model="ignore_ends_at"
                    >
                        <template #label-after>
                            <label for="ignore_ends_at">
                                {{ $t('modal.tender-rounds.label.ignore-ends-at') }}
                            </label>
                        </template>
                    </toggle-switch>
                </form-field>

                <!-- <form-field
                    class="form-field"
                    :error-messages="errors.collect('tender-rounds.message')"
                >
                    <label slot="label" for="message">
                        {{ $t('modal.tender-rounds.label.message') }}
                    </label>

                    <textarea
                        v-model="message"
                        id="message"
                        :placeholder="$t(`modal.tender-rounds.${status}.placeholder-message`)"
                        type="text"
                    />
                </form-field> -->
            </form>
        </section>

        <section class="text-right">
            <v-button
                v-if="status === 'edit'"
                class="btn btn--medium btn--inline"
                :state="endRequestState"
                @click="endTenderRound"
            >
                {{ $t(`modal.tender-rounds.${status}.end`) }}
            </v-button>

            <v-button
                class="btn btn--secondary btn--medium btn--inline"
                :state="requestState"
                :disabled="!canSubmit"
                @click="handleSubmit"
            >
                {{ $t(`modal.tender-rounds.${status}.submit`) }}
            </v-button>
        </section>
    </modal-content>
</dn-modal>
