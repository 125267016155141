<template>
    <div class="checkbox">
        <label :for="id">
            <input
                :id="id"
                type="checkbox"
                :name="name"
                :value="value"
                :class="className"
                :required="required"
                :disabled="disabled"
                :checked="state"
                @change="onChange"
            >

            <span class="checkbox__custom" />

            <span
                v-if="!!$slots.default"
                class="checkbox__label"
            ><slot /></span>
        </label>
    </div>
</template>

<script>
export default {
    model: {
        prop: 'modelValue',
        event: 'input'
    },
    props: {
        id: {
            type: String,
            default() {
                return 'checkbox-id-' + this._uid;
            }
        },

        name: {
            type: String,
            default: null
        },

        value: {
            type: [Boolean, String, Number],
            default: null,
            required: false
        },

        modelValue: {
            type: [Boolean, String, Number, Array],
            default: undefined,
            required: false
        },

        model: {},

        className: {
            type: String,
            default: null
        },

        checked: {
            type: Boolean,
            default: false
        },

        required: {
            type: Boolean,
            default: false
        },

        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        state() {
            if (this.modelValue === undefined) {
                return this.checked;
            }

            if (Array.isArray(this.modelValue)) {
                return this.modelValue.includes(this.value);
            }

            return !!this.modelValue;
        }
    },

    watch: {
        checked(newValue) {
            if (newValue !== this.state) {
                this.toggle();
            }
        }
    },

    mounted() {
        if (this.checked && !this.state) {
            this.toggle();
        }
    },

    methods: {
        onChange() {
            this.toggle();
        },

        toggle() {
            let value;

            if (Array.isArray(this.modelValue)) {
                value = this.modelValue.slice(0);
                if (this.state) {
                    value.splice(value.indexOf(this.value), 1);
                } else {
                    value.push(this.value);
                }
            } else {
                value = !this.state;
            }

            this.$emit('input', value);
        }
    }
};
</script>

<style lang="less">
@import './checkbox.less';
</style>
