'use strict';

export default {
    items: state => state.items,
    getLawyerTypes: state => state.items['lawyer-types'],
    getFieldsOfLaw: state => state.items['fields-of-law'],
    getHaircutKeywords: state => state.items['haircut-keywords'],
    getBusinessLanguageOptions: state => state.items['business-languages'],
    getAssignmentTypes: state => state.items['assignment-types'],
    getRoles: state => state.items.roles
};
