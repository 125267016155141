'use strict';

const CommunityPortalView = () => import('~/patterns/pages/community-portal/index.vue');

export default [
    {
        name: 'community-portal.index',
        path: '/',
        component: CommunityPortalView
    }
];
