<template>
    <div
        class="search-bar"
        :data-controls-visible="!!query"
    >
        <div class="search-bar__bar">
            <div class="search-bar__icon">
                <svg-icon icon="search" />
            </div>

            <input
                v-model="query"
                type="text"
                class="search-bar__input"
                :placeholder="$t('searchBar.placeholder')"
                @change="onInputChange"
            >

            <transition name="fade-absolute">
                <div
                    v-if="query"
                    class="search-bar__controls"
                >
                    <button
                        class="btn btn--clean search-bar__clear"
                        @click="clearInput"
                    >
                        <svg-icon icon="close" />
                    </button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
'use strict';

import { clone } from 'lodash-es';

export default {
    data() {
        return {
            query: this.$store.getters['search/searchQuery']
        };
    },

    watch: {
        query() {
            clearTimeout(this.timer);
            this.timer = setTimeout(this.onInputChange, 400);
        }
    },

    methods: {
        onInputChange() {
            const query = clone(this.$route.query); // Todo: get current query & use it.
            query.search = this.query;

            if (this.$route.query.cquery !== this.query) {
                this.$router.push({ query });
            }
        },

        clearInput() {
            this.query = '';
            this.onInputChange();
        }
    }
};
</script>

<style lang="less">
@import './search-bar.less';
</style>
