<template>
    <div />
</template>

<script>
'use strict';

export default {

    mounted() {
        this.logout();
    },

    methods: {
        async logout() {
            await this.$auth.logout();
        },
    }
};
</script>
