'use strict';

const styleguideView = () => import('./styleguide.vue');
const coreView = () => import('./core.vue');
const atomsView = () => import('./atoms.vue');
const moleculesView  = () => import('./molecules.vue');
const organismsView = () => import('./organisms.vue');

export default [
    {
        path: '/styleguide',
        component: styleguideView,
        children: [
            {
                path: '',
                component: coreView,
                name: 'styleguide.core'
            },
            {
                path: 'atoms',
                component: atomsView,
                name: 'styleguide.atoms'
            },
            {
                path: 'molecules',
                component: moleculesView,
                name: 'styleguide.molecules'
            },
            {
                path: 'organisms',
                component: organismsView,
                name: 'styleguide.organisms'
            }
        ]
    }
];
