<template>
    <span
        v-if="template"
        class="icon"
        :data-name="icon"
        v-html="template"
    />
</template>

<script>
export default {
    props: ['icon'],

    computed: {
        template() {
            return this.$store.getters['ui/icon'](this.icon);
        }
    },

    watch: {
        icon() {
            this.loadIcon();
        }
    },

    created() {
        this.loadIcon();
    },

    methods: {
        loadIcon() {
            this.$store.dispatch('ui/loadIcon', this.icon);
        }
    }
};
</script>

<style lang="less">
@import './icon.less';
</style>
