<template>
    <div
        v-if="isClient()"
        v-tooltip="{ content: $t('general.teammember') }"
        class="colleague-icon"
    >
        <svg-icon icon="colleague" />
    </div>
</template>

<script>
export default {

};
</script>

<style lang="less">
@import './colleague-icon.less';
</style>
