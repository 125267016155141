/*
 * General imports
 */
import Vue from 'vue';
import Router from 'vue-router';
import Store from '~/store/store';

// import scrollTo from '~/helpers/scroll-to';

/*
 * Styleguide
 */
import styleguideRoutes from '~/patterns/_styleguide/styleguide-routes';
import communityAppRoutes from '~/router/routes/community';
import communityPortalAppRoutes from '~/router/routes/community-portal';

/*
 * Import global views
 */
import LoginView from '~/patterns/pages/login/login.vue';
import LogoutView from '~/patterns/pages/logout/logout.vue';
import NotAllowedView from '~/patterns/pages/errors/403.vue';
import PageNotFoundView from '~/patterns/pages/errors/404.vue';
import ProfileEditView from '~/patterns/pages/profile/edit.vue';

function getAppRoutes() {
    switch (window.appConfig.context) {
    case 'community':
        return communityAppRoutes;
    case 'community-portal':
        return communityPortalAppRoutes;
    default:
        return [];
    }
}

// Use router
Vue.use(Router);

// Setup routes
const router = new Router({
    mode: 'history',
    routes: [
        ...getAppRoutes(),
        ...styleguideRoutes,

        {
            name: 'profile.edit',
            path: '/profile',
            component: ProfileEditView
        },

        {
            name: 'login',
            path: '/login',
            component: LoginView,
            meta: {
                noAuthRequired: true
            }
        },

        {
            name: 'logout',
            path: '/logout',
            component: LogoutView,
            meta: {
                noAuthRequired: true
            }
        },

        {
            name: '403',
            path: '/nope',
            component: NotAllowedView,
            meta: {
                navigationOpen: true,
                noAuthRequired: true
            }
        },

        {
            name: '404',
            path: '/404',
            component: PageNotFoundView,
            meta: {
                navigationOpen: true
            }
        },

        {
            path: '*',
            redirect: '/404'
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
});

router.beforeEach((to, from, next) => {
    const authGuard = Vue.$auth.guard(to);

    if (!to.matched.length) {
        return next('/404');
    }

    authGuard.then(() => {
        next();
    }).catch((status) => {
        if (status === 401) {
            return router.push({
                name: 'login',
                params: {
                    requestedRoute: to
                }
            });
        }

        if (status === 403) {
            return router.push({
                name: '403'
            });
        }
    });
});

router.afterEach((to) => {
    if (to.matched[0].meta.header && to.matched[0].meta.header.visible) {
        Store.commit('ui/headerCollapsed', false);
    } else {
        Store.commit('ui/headerCollapsed', true);
    }

    // Reset the return link and tag
    Store.commit('ui/returnLink', false);

    // window.scrollTo({
    //     top: 0,
    //     left: 0,
    //     behavior: 'smooth'
    // });

    // scrollTo(document.documentElement, 0, 0);
});

export default router;
