
<dn-modal
    name="broadcastModal"
    size="medium"
    position="centered"
>
    <modal-content
        v-if="modalData"
        class="modal-content--form"
    >
        <h1>{{ $t('modal.broadcast.title') }}</h1>

        <form
            class="form"
            data-vv-scope="broadcast"
            @submit.prevent="sendBroadcast"
        >
            <form-field>
                <template>
                    <label class="label label--inline">
                        {{ $t('modal.broadcast.to.label') }}:
                    </label>

                    <selected-users :users="modalData.users" />
                </template>
            </form-field>

            <form-field
                class="form-field--spacing-sm"
                :error-messages="errors.collect('broadcast.message')"
            >
                <label
                    slot="label"
                    for="broadcastMessage"
                >
                    {{ $t('modal.broadcast.message.label') }}:
                </label>

                <textarea
                    id="broadcastMessage"
                    key="broadcastMessage"
                    v-model="formData.message"
                    v-validate.disable="{required: !attachment}"
                    name="message"
                    :placeholder="$t('modal.broadcast.message.placeholder')"
                    rows="6"
                    @input="revalidateFields"
                />
            </form-field>

            <form-field :error-messages="errors.collect('broadcast.attachment')">
                <file-attach
                    v-model="attachment"
                    v-validate.disable="{required: !formData.message.length}"
                    name="attachment"
                    @input="revalidateFields"
                />
            </form-field>

            <llq-button
                type="submit"
                class="btn--secondary btn--wide btn--icon-right"
                :disabled="formData.message.length === 0 && !attachment"
                :state="requestState"
            >
                {{ $t('modal.broadcast.submit') }}
                <svg-icon
                    class="icon--small hidden-xxs hidden-xs"
                    middle
                    flip
                    icon="arrow"
                />
            </llq-button>
        </form>
    </modal-content>
</dn-modal>
