<template>
    <div
        class="toast"
        :class="{
            'toast--warning': severity === 'warning',
            'toast--error': severity === 'error'
        }"
    >
        <span
            class="toast__content"
            v-html="content"
        />
    </div>
</template>

<script>
'use strict';

import { mapActions } from 'vuex';

export default {
    props: {
        content: {
            type: String,
            required: true
        },
        severity: {
            type: String,
            default: 'none'
        },
        duration: {
            type: Number,
            default: 6000
        }
    },

    computed: {
        icon() {
            if (this.severity === 'error') {
                return 'close';
            }

            return false;
        }
    },

    created() {
        this.interval = setTimeout(() => this.removeToast(), this.duration);
    },

    methods: {
        ...mapActions({
            removeToast: 'toast/remove'
        })
    }
};
</script>

<style lang="less">
@import './toast.less';
</style>
