<template>
    <div
        class="notice"
        :class="{
            'notice--info': severity === 'info',
            'notice--success': severity === 'success',
            'notice--warning': severity === 'warning',
            'notice--danger': severity === 'danger'
        }"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        severity: {
            type: String,
            default: 'info',
            validator(value) {
                return ['info', 'success', 'warning', 'danger'].includes(value);
            }
        }
    }
};
</script>

<style lang="less">
@import './notice.less';
</style>
