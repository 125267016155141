<template>
    <div class="tariff-table">
        <table class="tariff-table__table">
            <tr>
                <th>{{ $t((tariffs.length > 0) ? 'tariffTable.rate_type' : 'tariffTable.item') }}</th>

                <template v-if="tariffs.length > 0">
                    <th>{{ $t('tariffTable.tariff') }}</th>
                </template>

                <template v-else-if="workloads.length > 0">
                    <template v-if="showRange">
                        <th>{{ $t('tariffTable.lower_bound') }}</th>
                        <th>{{ $t('tariffTable.upper_bound') }}</th>
                    </template>
                    <template v-if="showTotalWorkload">
                        <th>{{ $t('tariffTable.total_estimate') }}</th>
                    </template>
                </template>
            </tr>

            <template v-if="tariffs.length > 0">
                <tr
                    v-for="tariff in tariffs"
                    :key="tariff.id"
                >
                    <td>{{ tariff.type }}</td>
                    <td>{{ $n(tariff.amount, 'currency') }}</td>
                </tr>
            </template>

            <template v-if="workloads.length > 0">
                <tr
                    v-for="(workload, index) in workloads"
                    :key="workload.id"
                    :class="{
                        'last-row': (index + 1) === workloads.length
                    }"
                >
                    <td>
                        {{ workload.description }}
                        <template v-if="workload.remarks?.length">
                            <br>
                            <em
                                style="white-space: pre-wrap;"
                            >{{ workload.remarks }}</em>
                        </template>
                    </td>

                    <template v-if="showRange">
                        <td>{{ $n(workload.lower_boundary, 'currency') }}</td>
                        <td>{{ $n(workload.upper_boundary, 'currency') }}</td>
                    </template>

                    <template v-if="showTotalWorkload">
                        <td>
                            {{ $n(workload.total_estimate, 'currency') }}
                        </td>
                    </template>
                </tr>

                <tr class="tariff-table__totals">
                    <td>{{ $t('tariffTable.total') }}</td>
                    <template v-if="showRange">
                        <td>
                            {{ $n(getTotal(workloads, 'lower_boundary'), 'currency') }}
                        </td>
                        <td>
                            {{ $n(getTotal(workloads, 'upper_boundary'), 'currency') }}
                        </td>
                    </template>

                    <template v-if="showTotalWorkload">
                        <td>
                            {{ $n(getTotal(workloads, 'total_estimate'), 'currency') }}
                        </td>
                    </template>
                </tr>
            </template>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        tariffs: {
            type: Array,
            default: () => { return []; }
        },
        workloads: {
            type: Array,
            default: () => { return []; }
        },
        workloadType: {
            type: String,
            default: null
        }
    },

    computed: {
        showRange() {
            return this.workloadType === null || this.workloadType === 'range';
        },
        showTotalWorkload() {
            return this.workloadType === null || this.workloadType === 'total';
        }
    },

    methods: {
        getTotal(items, key) {
            let sum = 0;

            items.forEach((item) => {
                sum += Number(item[key]);
            });

            return sum;
        }
    }
};
</script>

<style lang="less">
@import './tariff-table.less';
</style>
