<template>
    <div class="toasts">
        <transition-group
            name="toast"
            tag="div"
        >
            <toast
                v-for="(message, index) in messages"
                :key="message.content"
                :content="message.content"
                :severity="message.severity"
                :duration="message.duration"
                :index="index"
            />
        </transition-group>
    </div>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import toast from '~/patterns/atoms/toast/toast.vue';

export default {
    components: { toast },

    computed: {
        ...mapGetters({
            messages: 'toast/messages'
        })
    }
};
</script>

<style lang="less">
@import './toasts.less';
</style>
