'use strict';

import enValidations from 'vee-validate/dist/locale/en';
import nlValidations from 'vee-validate/dist/locale/nl';

const dictionary = {
    en: enValidations,
    nl: nlValidations
};

/*
 * Custom dutch messages
 */
dictionary.nl.messages.url = () => 'Voer een geldige URL in. Vergeet niet http:// of https:// aan de url toe te voegen.';
dictionary.nl.messages.required = () => 'Dit veld is verplicht.';
dictionary.nl.messages.numeric = () => 'Alleen cijfers zijn toegestaan.';
dictionary.nl.messages.decimal = () => 'Dit veld moet een nummer zijn en mag decimalen bevatten.';
dictionary.nl.messages.year = () => 'Dit is geen geldig jaartal.';
dictionary.nl.messages.digits = (field, [length]) => `Hier zou je ${length} cijfers moeten invullen.`;

export default dictionary;
