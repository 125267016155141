<template>
    <dn-modal
        name="proposal-preview"
        position="centered"
        size="medium"
    >
        <modal-content>
            <section class="text-center">
                <h2>{{ $t('modal.proposal-preview.title') }}</h2>
            </section>

            <section>
                <proposal-preview
                    :show-heading="false"
                    :preview-fields="requestedInformation"
                    :custom-descriptions="customDescriptions"
                    :workload-fields="workloadFields"
                    :open-questions="openQuestions"
                />
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import informationTypes from '~/constants/proposal-information-types';

import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import ProposalPreview from '~/patterns/molecules/proposal-preview/proposal-preview.vue';

export default {
    components: {
        DnModal,
        ModalContent,
        ProposalPreview
    },

    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen',
            modalData: 'modal/data'
        }),

        modalOpen() {
            return this.isOpen('assignment-details');
        },

        requestedInformation() {
            return informationTypes.filter(option => !this.modalData?.proposal_form_config?.hidden_fields?.includes(option));
        },

        customDescriptions() {
            return this.modalData?.proposal_form_config?.field_descriptions;
        },

        workloadFields() {
            return this.modalData?.proposal_form_config?.workload_fields;
        },

        openQuestions() {
            return this.modalData?.proposal_form_config?.open_questions;
        }
    }
};
</script>
