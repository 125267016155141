<template>
    <div class="file-upload">
        <vue-dropzone
            :id="id"
            ref="dropzone"
            :use-custom-slot="true"
            :options="dropzoneOptions"
            @vdropzone-success="handleUploadedFile"
            @vdropzone-error="handleFailure"
            @vdropzone-removed-file="handleRemoveFile"
            @vdropzone-max-files-exceeded="handleMaxFilesExceeded"
            @vdropzone-mounted="showPreviouslyUploadedFile"
            @vdropzone-sending="handleSending"
            @vdropzone-complete="$emit('file-uploaded', file)"
        >
            <slot><p>{{ $t('fileUpload.select-file') }}</p></slot>
        </vue-dropzone>

        <span
            v-if="errorMessage"
            class="file-upload__error"
        >
            {{ $t('fileUpload.error') }}
        </span>
    </div>
</template>

<script>
'use strict';

export default {

    components: {
        vueDropzone: () => import('vue2-dropzone')
    },

    props: {
        id: {
            type: String,
            default: 'file-upload'
        },
        thumbnail: {
            String,
            default: ''
        }
    },
    data() {
        return {
            file: null,
            xhr: null,
            formData: null,
            errorMessage: '',
            cacheBustingToken: Math.random().toString().substring(3, 15),
            accessToken: ''
        };
    },

    computed: {
        previewUrl() {
            return this.thumbnail;
        },

        dropzoneOptions() {
            return {
                url: `${window.appConfig.api.url}/v1.0/files`,
                // thumbnailWidth: '100%,
                maxFilesize: 5,
                addRemoveLinks: true,
                maxFiles: 1,
                acceptedFiles: 'image/*'
            };
        }
    },

    async created() {
        this.accessToken = await this.$auth.getTokenSilently();
    },

    methods: {
        handleSending(file, xhr, formData) {
            formData.append('token', this.accessToken);
            this.$emit('file-uploading', file, xhr, formData);
        },

        handleUploadedFile(file, response) {
            this.errorMessage = '';
            this.$emit('input', response.path);
            this.$emit('new-file', response.path);
        },

        handleFailure(file, message) {
            // Remove all uploaded files
            this.$refs.dropzone.removeAllFiles(true);

            // Show the file that was already in the database
            this.showPreviouslyUploadedFile();

            // Add toast for error messages
            this.dropzoneValidationHandler(message);
        },

        handleRemoveFile() {
            this.$emit('removed-file');
            this.$emit('input', null);
            this.errorMessage = '';
        },

        showPreviouslyUploadedFile() {
            if (this.thumbnail) {
                this.$http.head(this.thumbnail).then((res) => {
                    const mockFile = { name: 'filename', size: 999, type: res.headers['content-type'] },
                        settings = {
                            dontSubstractMaxFiles: false,
                            addToFiles: true
                        };
                        // Show the file in the dropzone area
                        // This is a really ugly workaround but dropzone refuses to load files that do not have an extension
                        // It doesn't seem to matter what that actual extension is, though, so just hardcode jpeg.
                    this.$refs.dropzone.manuallyAddFile(mockFile, this.previewUrl + '.jpeg', null, null, settings);
                });
            }
        },

        handleMaxFilesExceeded(file) {
            // Remove current file and replace it with the new one
            // That way there is always just one file
            this.$refs.dropzone.removeAllFiles();
            this.$refs.dropzone.addFile(file);
            this.errorMessage = '';
        },

        dropzoneValidationHandler(message) {
            if (!message) {
                return;
            }

            if (message.errors && message.errors.file) {
                if (message.message) {
                    this.errorMessage = message.message;
                }

                for (const fileInvalidMessage of message.errors.file) {
                    this.errorMessage += ` ${fileInvalidMessage}`;
                }
            } else if (message.errors) {
                this.errorMessage = message.errors;
            } else if (message.message) {
                this.errorMessage = message.message;
            } else {
                this.errorMessage = true;
            }
        }
    }
};
</script>

<style lang="less" src="./file-upload.less" />
<style lang="less" src="./dropzone.less" />
