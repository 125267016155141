
<header class="section-header">
    <div class="section-header__body">
        <div
            v-if="!!$slots.prefix"
            class="section-header__prefix"
        >
            <slot name="prefix" />
        </div>

        <h2 class="section-header__title">
            <slot name="title" />
        </h2>

        <div
            v-if="!!$slots.actions"
            class="section-header__actions"
        >
            <slot name="actions" />
        </div>
    </div>
</header>
