
<section class="form-section">
    <header
        v-if="!!$slots.header || !!$slots.description"
        class="form-section__header"
    >
        <h4 v-if="!!$slots.header">
            <slot name="header" />
        </h4>
        <div
            v-if="!!$slots.description"
            class="form-section__description"
        >
            <slot name="description" />
        </div>
    </header>
    <slot />
</section>
