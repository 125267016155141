
<article
    v-if="expert"
    class="expert-preview"
    :class="{
        'expert-preview--pull-up': !fieldsOfLaw && pullUp,
        'expert-preview--actionable': linkToProfile
    }"
>
    <div class="expert-preview__avatar">
        <a
            v-if="linkToNewTab"
            class="expert-preview__link"
            :href="`/users/${expert.id}`"
            target="_blank"
        >
            <avatar
                :user="expert"
                :link-to-profile="false"
            />
        </a>
        <avatar
            v-else
            :user="expert"
            :link-to-profile="linkToProfile"
        />
    </div>

    <div class="expert-preview__body">
        <slot name="body">
            <span
                v-if="!!$slots.prefix"
                class="expert-preview__prefix"
            >
                <slot name="prefix" />
            </span>

            <h3 class="expert-preview__title">
                <!-- eslint-disable-next-line vue/require-component-is -->
                <component v-bind="titleAttributes">
                    {{ expert.first_name }} {{ expert.last_name }}

                    <template v-if="!hideJobTitle && expert.lawyer_types?.length">
                        <span class="separator">&mdash;</span>
                        <span
                            v-for="(type, index) in expert.lawyer_types"
                            :key="`function${index}`"
                            class="function"
                        >
                            {{ $t(`lawyerTypes.${type.toLowerCase()}`) }}<template v-if="index+1 !== expert.lawyer_types.length">,&nbsp;</template>
                        </span>
                    </template>


                    <colleague-icon v-if="isColleague && !isMe" />
                </component>

                <supplier-info
                    v-if="!hideSupplier"
                    :user="expert"
                />

                <llq-tag
                    v-if="expert.pivot?.is_rejected === true || expert.assignments?.[0]?.pivot.is_rejected === true"
                    class="tag--no-margin"
                >
                    {{ $t('rejected') }}
                </llq-tag>
            </h3>

            <div
                v-if="expert.lawyer_types && expert.lawyer_types.length"
                class="expert-preview__job-title"
            >
                <span
                    v-for="(type, index) in expert.lawyer_types"
                    :key="`function${index}`"
                >
                    {{ $t(`lawyerTypes.${type.toLowerCase()}`) }}<template v-if="index+1 !== expert.lawyer_types.length">,&nbsp;</template>
                </span>
            </div>

            <div
                v-if="(fieldsOfLaw && !hideFieldsOfLaw) || $slots.meta"
                class="expert-preview__meta"
            >
                <slot name="meta">
                    <span
                        v-for="(field, index) in fieldsOfLaw"
                        :key="index"
                    >{{ field }}</span>
                </slot>
            </div>
        </slot>
    </div>
</article>
