
<div class="toasts">
    <transition-group
        name="toast"
        tag="div"
    >
        <toast
            v-for="(message, index) in messages"
            :key="message.content"
            :content="message.content"
            :severity="message.severity"
            :duration="message.duration"
            :index="index"
        />
    </transition-group>
</div>
