<template>
    <dn-modal
        v-if="assignment"
        name="assignment-response"
        position="centered"
        size="medium"
        :close-prompt="$t('modal.assignment-response.close-prompt')"
        class="assignment-details-modal"
    >
        <modal-content>
            <template #default>
                <h1 class="assignment-details-modal__title">
                    {{ assignment.title }}
                </h1>

                <!--eslint-disable vue/no-v-html-->
                <p
                    class="lead assignment-details-modal__text"
                    v-html="assignment.content"
                />
                <!--eslint-enable vue/no-v-html-->

                <assignment-meta :assignment="assignment" />
            </template>
        </modal-content>

        <template #footer>
            <llq-button
                v-if="!assignment.me?.[0]?.pivot?.is_rejected"
                class="btn--secondary"
                @click="handleClick('proposal')"
            >
                {{ $t('modal.assignment-response.send-fee-quote') }}
            </llq-button>

            <llq-button @click="handleClick('interest')">
                {{ $t('modal.assignment-response.interest') }}
            </llq-button>

            <llq-button @click="handleClick('question')">
                {{ $t('modal.assignment-response.ask-question') }}
            </llq-button>
        </template>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';

import { STATUS_OPEN } from '../../../../constants/assignment-status';
import ModalContent from '../../modal-content/modal-content.vue';
import AssignmentMeta from '../../../molecules/assignment-meta/assignment-meta.vue';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import Button from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        AssignmentMeta,
        ModalContent,
        DnModal,
        'llq-button': Button
    },

    data() {
        return {
            loading: false,
            STATUS_OPEN
        };
    },

    methods: {
        async handleClick(action) {
            const assignment = this.assignment;

            this.$store.dispatch('modal/closeModal');

            if (this.$route.name !== 'assignment.thread') {
                const route = {
                    name: 'assignment.detail',
                    params: {
                        id: assignment.id,
                    }
                };
                await this.$router.push(route);
            }


            switch (action) {
            case 'proposal':
                this.$store.dispatch('modal/openModal', {
                    name: 'create-proposal',
                    assignmentId: this.assignment?.id
                });

                this.$store.commit('ui/preventModal', true);
                break;
            case 'question':
                this.$store.commit('ui/commentBoxSelected', true);
                this.$store.commit('ui/preventModal', true);
                break;
            case 'interest':
                this.$store.commit('ui/commentBoxPrefill', this.$t('modal.assignment-response.prefilled-message'));
                this.$store.commit('ui/commentBoxSelected', true);
                this.$store.commit('ui/preventModal', true);
                break;
            default:
                break;
            }
        }
    },

    // eslint-disable-next-line vue/order-in-components
    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen',
            modalData: 'modal/data'
        }),

        modalOpen() {
            return this.isOpen('assignment-response');
        },

        assignmentId() {
            return this.modalData?.assignment.id;
        },

        assignment() {
            return this.modalData?.assignment;
        }
    }
};
</script>

<style lang="less">
.assignment-details-modal {
    .modal__footer,
    .modal-content {
        padding: 2rem 1.875rem;
    }
}

.assignment-details-modal__title {
    .font-primary-regular();
}

.assignment-details-modal__text {
    white-space: pre-wrap;

    ol,
    ul {
        font-size: 1rem;
    }
}
</style>
