
<div
    v-show="withToggle ? true : active"
    class="note-field"
    :class="{
        'note-field--active': active
    }"
>
    <v-button
        v-if="!active && withToggle"
        class="note-field__add btn--medium btn--small"
        @click="toggleNoteField"
    >
        <svg-icon icon="note-plus" />
        {{ $t('noteField.button') }}
    </v-button>



    <form-field v-if="active">
        <label class="form-field__label">{{ $t('noteField.label') }}</label>
        <div class="note-field__field-wrapper">
            <MarkupTextarea
                v-model="note"
                :placeholder="$t('noteField.placeholder')"
                rows="5"
            />

            <v-button
                v-if="withToggle"
                class="note-field__remove"
                @click="removeNote"
            >
                <svg-icon icon="chat-error" />
            </v-button>
        </div>
    </form-field>
</div>
