<template>
    <default-view>
        <article class="article">
            <h1>{{ $t('errorsView.404.title') }}</h1>

            <p class="lead">{{ $t('errorsView.404.description') }}</p>
        </article>
    </default-view>
</template>

<script>
'use strict';

export default {

};
</script>
