
<dn-modal
    name="proposal-created"
    position="centered"
    size="small"
>
    <modal-content class="modal-content--centered">
        <h1>{{ $t('modal.proposal-created.title') }}</h1>

        <p>{{ $t('modal.proposal-created.description') }}</p>

        <p>
            <button
                class="btn btn--secondary"
                @click="$store.dispatch('modal/closeModal');"
            >
                {{ $t('modal.proposal-created.cta') }}
            </button>
        </p>
    </modal-content>
</dn-modal>
