'use strict';

import Loader from '~/services/loader/loading-service';
import CommunityApi from '~/api/community';

export default {
    fetchTerms(context) {
        Loader.get('terms').start();

        CommunityApi.getTerms().then((response) => {
            if (response.data) {
                context.commit('terms', response.data);
            }

            Loader.get('terms').success();
        }).catch((err) => {
            Loader.get('terms').fail(err);
        });
    }
};
