export default [
    'APPROACH',
    'START_DATE',
    'TARIFF_ANY',
    'FIXED_FEE',
    'AVAILABILITY_ANY',
    'WORKLOAD_ANY',
    'MEDIA',
    'RESUME'
];
