<template>
    <div class="cta-bar">
        <div class="cta-bar__body">
            <slot name="body" />
        </div>

        <div class="cta-bar__cta">
            <slot name="cta" />
        </div>
    </div>
</template>

<script>
'use strict';

export default {

};
</script>

<style lang="less">
@import './cta-bar.less';
</style>
