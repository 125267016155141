
<ul class="labelled-list">
    <li
        v-for="item in items"
        :key="item.label"
        class="labelled-list__item"
    >
        <label class="labelled-list__label">
            <slot
                name="label"
                :item="item"
            >{{ item.label }}</slot>
        </label>
        <span class="labelled-list__value">
            <slot
                name="value"
                :item="item"
            >{{ item.value }}</slot>
        </span>
    </li>
</ul>
