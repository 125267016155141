
<dn-modal
    name="proposal-accepted"
    position="centered"
    size="small"
>
    <modal-content class="modal-content--centered">
        <h1>{{ $t('modal.proposal-accepted.title') }}</h1>

        <p v-if="tenderRoundsResponse">{{ $t('modal.proposal-accepted.description-tender-round') }}</p>
        <p v-else>{{ $t('modal.proposal-accepted.description') }}</p>

        <p>
            <button
                class="btn btn--secondary"
                @click="$store.dispatch('modal/closeModal');"
            >
                {{ $t('modal.proposal-accepted.cta') }}
            </button>
        </p>
    </modal-content>
</dn-modal>
