
<dn-modal
    name="user-invite"
    position="centered"
    size="medium"
>
    <modal-content>
        <section class="text-center">
            <h2>{{ $t('modal.user-invite.title') }}</h2>
        </section>

        <section>
            <form class="form">
                <div class="column-grid form-field">
                    <form-field
                        :error-messages="errors.collect('role')"
                        columns-sm-min="4/8"
                    >
                        <label
                            slot="label"
                            for="role"
                        >
                            {{ $t('modal.user-invite.role.label') }}
                        </label>

                        <multiselect
                            v-model="formData.role"
                            :options="roleOptions"
                            :placeholder="$t('modal.user-invite.role.placeholder')"
                            :searchable="false"
                            :show-labels="false"
                            :multiple="false"
                            :custom-label="roleLabel"
                            :allow-empty="false"
                        />
                    </form-field>
                </div>

                <form-field
                    :error-messages="errors.collect('name')"
                >
                    <label
                        slot="label"
                        for="name"
                    >
                        {{ $t('modal.user-invite.name.label') }}
                    </label>

                    <input
                        id="name"
                        v-model="formData.name"
                        v-validate="'required'"
                        type="text"
                        name="name"
                        :placeholder="$t('modal.user-invite.name.placeholder')"
                    >
                </form-field>

                <form-field
                    :error-messages="errors.collect('email')"
                >
                    <label
                        slot="label"
                        for="email"
                    >
                        {{ $t('modal.user-invite.email.label') }}
                    </label>

                    <input
                        id="email"
                        v-model="formData.email"
                        v-validate="'required|email'"
                        type="email"
                        name="email"
                        :placeholder="$t('modal.user-invite.email.placeholder')"
                    >
                </form-field>
            </form>
        </section>

        <section class="text-right">
            <v-button
                class="btn btn--secondary btn--medium"
                :state="requestState"
                @click="inviteUser"
            >
                {{ $t('modal.user-invite.submit') }}
            </v-button>
        </section>
    </modal-content>
</dn-modal>
