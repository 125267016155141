<template>
    <transition name="nav-dropdown">
        <div
            v-show="!!activeTab"
            class="nav-dropdown"
            :data-active-content="currentTab"
            :style="{ height: activeTabHeight ? activeTabHeight + 'px' : null }"
        >
            <transition
                name="nav-tab"
                mode="out-in"
            >
                <div
                    v-if="activeTab === 'notifications'"
                    ref="notifications"
                    key="notifications"
                    class="nav-dropdown__tab"
                    data-tab-name="notifications"
                >
                    <notification-list />
                </div>

                <div
                    v-if="activeTab === 'account'"
                    ref="account"
                    key="account"
                    class="nav-dropdown__tab"
                    data-tab-name="account"
                >
                    <ul class="nav-dropdown__list">
                        <li>
                            <router-link :to="{ name: 'profile.edit' }">
                                {{ $t('navDropdown.links.profile') }}
                            </router-link>
                        </li>
                        <li>
                            <a :href="communityPortalUrl">
                                {{ $t('navDropdown.links.community-overview') }}

                                <activity-indicator :is-active="hasActivity" />
                            </a>
                        </li>

                        <li v-if="isLawyer() || isSupplier()">
                            <a
                                :href="timeTrackingUrl"
                                target="_blank"
                                rel="noopener"
                            >
                                {{ $t('navDropdown.links.time-tracking') }}
                            </a>
                        </li>

                        <li>
                            <router-link :to="{ name: 'logout' }">{{ $t('navDropdown.links.logout') }}</router-link>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';

import Vue from 'vue';
import NotificationList from '~/patterns/molecules/notification-list/notification-list.vue';
import ActivityIndicator from '~/patterns/atoms/activity-indicator/activity-indicator.vue';

export default {
    components: {
        NotificationList,
        ActivityIndicator
    },

    props: {
        activeTab: {
            type: [String, Boolean],
            default: false
        }
    },

    data() {
        return {
            previousTab: false,
            communityPortalUrl: window.appConfig.communityPortalUrl,
            activeTabHeight: null
        };
    },

    computed: {
        ...mapGetters({
            hasUpdatedCommunities: 'activity/hasUpdatedCommunities',
            user: 'auth/user'
        }),

        hasActivity() {
            return this.hasUpdatedCommunities && window.appConfig.context !== 'community-portal';
        },

        timeTrackingUrl() {
            const formUrl = 'https://forms.lawyerlinq.com/F/form/llqTS1/formperma/76fbqGltQWdWHRopf4EGm9REbKd8IWPI2Zj2oPoM9XY';
            if (this.user && this.user.email) {
                return `${formUrl}?email=${encodeURIComponent(this.user.email)}`;
            }
            return formUrl;
        },

        currentTab() {
            if (this.activeTab) {
                return this.activeTab;
            }

            if (!this.activeTab && this.previousTab) {
                return this.previousTab;
            }

            return false;
        }
    },

    watch: {
        activeTab(newTab, previousTab) {
            this.previousTab = previousTab;
            if (typeof this.$refs[newTab] !== 'undefined') {
                Vue.nextTick(() => {
                    this.activeTabHeight = this.$refs[newTab].scrollHeight;
                });
            }
        }
    },

    methods: {
        isActiveTab(tabName) {
            if (this.activeTab === tabName) {
                return true;
            }

            return !this.activeTab && this.previousTab === tabName;
        },

        async logout() {
            await this.$auth.logout();
        }
    }
};
</script>

<style lang="less">
@import './nav-dropdown.less';
</style>
