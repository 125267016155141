
<dn-modal
    name="proposals-share"
    size="medium"
    position="centered"
>
    <modal-content class="modal-content--article">
        <h1 class="text-center">{{ $t('modal.proposals-share.modal-title') }}</h1>
        <p>{{ $t('modal.proposals-share.modal-text') }}</p>
        <form
            v-if="!guestLoginUrl"
            class="form"
            @submit.prevent="getGuestLoginUrl"
        >
            <div class="column-grid form-field">
                <form-field
                    columns-sm-min="9/12"
                    :required="true"
                >
                    <input
                        v-if="!isLoading"
                        v-model="email"
                        type="email"
                        placeholder="Email"
                    >
                </form-field>
                <form-field columns-sm-min="3/12">
                    <llq-select
                        v-if="!isLoading"
                        v-model="expiration"
                        :options="selectOptions"
                    />
                    <content-placeholders v-else>
                        <content-placeholders-img
                            style="height: 46px;"
                        />
                    </content-placeholders>
                </form-field>
            </div>
            <div class="column-grid">
                <form-field columns-sm-min="12/12">
                    <llq-button
                        :disabled="isLoading || guestLoginUrl"
                        class="btn btn--secondary btn--wide"
                        :state="requestState"
                        type="submit"
                    >
                        {{ $t('modal.proposals-share.modal-submit') }}
                    </llq-button>
                </form-field>
            </div>
        </form>
        <form
            v-else
            class="form"
            @submit.prevent=""
        >
            <div class="column-grid form-field">
                <form-field
                    class="guest-sign-in-url"
                    columns-sm-min="12/12"
                >
                    <input
                        v-model="guestLoginUrl"
                        type="text"
                        readonly
                    >
                    <CopyShareButton
                        :url="guestLoginUrl"
                        :show-success-toast="false"
                        :show-error-toast="false"
                        @success="handleCopy"
                    >
                        <svg-icon icon="copy" />
                    </CopyShareButton>
                </form-field>
            </div>
            <div class="column-grid">
                <form-field columns-sm-min="12/12">
                    <llq-button
                        :disabled="isLoading || !!guestLoginUrl"
                        class="btn btn--secondary btn--wide"
                        :state="requestState"
                        type="submit"
                    >
                        {{ $t('modal.proposals-share.modal-submit') }}
                    </llq-button>
                </form-field>
            </div>
        </form>
    </modal-content>
</dn-modal>
