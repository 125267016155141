<template>
    <!-- eslint-disable vue/no-v-html -->

    <dn-modal
        name="select-participants"
        class="select-participants"
        size="large"
        data-aside="reverse"
    >
        <template #aside>
            <community-filters
                class="select-participants__filters"
                @has-filters="updateFilters"
            />
        </template>

        <template #default>
            <load-status loader-name="users">
                <expert-list
                    v-if="lawyersAndSuppliers.length"
                    class="select-participants__list"
                    :users="hasFilters ? filteredLawyersAndSuppliers : lawyersAndSuppliers"
                    :force-selected-user-ids="forcedLawyerAndSupplierIds"
                    :use-store="true"
                    :allow-selection="true"
                    :smaller-preview="true"
                    :link-experts-to-new-tab="true"
                    @selectNone="emitCurrentSelection"
                    @selectAll="emitCurrentSelection"
                    @selectLawyer="emitCurrentSelection"
                />

                <no-lawyers
                    v-if="!lawyersAndSuppliers.length"
                    class="select-participants__banner"
                />
            </load-status>
        </template>

        <template
            v-if="lawyersAndSuppliers.length"
            #footer
        >
            <cta-bar
                v-if="assignmentId"
                class="cta-bar--static"
            >
                <template v-slot:body>
                    <div
                        v-html="$tc(
                            'modal.assignment-participants.add.cta-description',
                            addedSelectedLawyerAndSupplierIds.length,
                            { count: addedSelectedLawyerAndSupplierIds.length }
                        )"
                    />
                </template>

                <template v-slot:cta>
                    <v-button
                        class="btn--secondary"
                        :disabled="selectedLawyerCount <= 0"
                        :state="requestState"
                        @click="nextAdd"
                    >
                        {{ $t('modal.assignment-participants.add.cta') }}
                    </v-button>
                </template>
            </cta-bar>

            <cta-bar
                v-else
                class="cta-bar--static"
            >
                <template v-slot:body>
                    <div
                        v-html="$tc(
                            'modal.assignment-participants.create.cta-description',
                            selectedLawyerCount,
                            { count: selectedLawyerCount }
                        )"
                    />
                </template>

                <template v-slot:cta>
                    <checkbox
                        id="save_favorite"
                        v-model="saveSelection"
                        :disabled="addedSelectedLawyerAndSupplierIds.length <= 0"
                        class="checkbox--inline"
                        name="save_favorite"
                    >
                        {{ $t('modal.assignment-participants.create.favorite') }}
                    </checkbox>
                    <button
                        type="button"
                        class="btn btn--secondary"
                        :disabled="selectedLawyerCount <= 0"
                        @click="nextNew"
                    >
                        {{ $t('modal.assignment-participants.create.cta') }}
                    </button>
                </template>
            </cta-bar>
        </template>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import AssignmentApi from '~/api/assignments';

import CommunityFilters from '~/patterns/organisms/community-filters/community-filters.vue';
import ExpertList from '~/patterns/organisms/selectable-list/presets/expert-list.vue';
import CtaBar from '~/patterns/molecules/cta-bar/cta-bar.vue';
import NoLawyers from '~/patterns/molecules/banner/presets/no-lawyers.vue';
import VButton from '~/patterns/atoms/button/button.vue';
import Checkbox from '~/patterns/atoms/checkbox/checkbox.vue';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import LoadStatus from '~/patterns/organisms/load-status/load-status.vue';

export default {
    components: {
        ExpertList,
        CtaBar,
        CommunityFilters,
        NoLawyers,
        VButton,
        Checkbox,
        DnModal,
        LoadStatus
    },

    data() {
        return {
            requestState: undefined,
            saveSelection: false,
            savedSelection: [],
            hasFilters: false
        };
    },

    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen',
            lawyersAndSuppliers: 'users/lawyersAndSuppliers',
            currentSelection: 'users/selectedLawyerAndSupplierIds',
            filteredLawyersAndSuppliers: 'users/filteredLawyersAndSuppliers'
        }),

        modalOpen() {
            return this.isOpen('select-participants');
        },

        assignmentId() {
            return this.$store.getters['modal/data']?.assignmentId;
        },

        forcedLawyerAndSupplierIds() {
            return this.assignmentId && this.$store.getters['modal/data']?.existingLawyerAndSupplierIds?.length ? this.$store.getters['modal/data']?.existingLawyerAndSupplierIds : [];
        },

        addedSelectedLawyerAndSupplierIds() {
            return this.currentSelection.filter(id => !this.forcedLawyerAndSupplierIds.includes(id));
        },

        filteredSelection() {
            if (this.filteredLawyersAndSuppliers === null) {
                return [];
            }

            const filtered = this.filteredLawyersAndSuppliers.filter((user) => {
                return this.currentSelection.includes(user.id);
            });

            return filtered;
        },

        filteredSelectionIds() {
            return this.filteredSelection.map(user => user.id);
        },

        selectedLawyerCount() {
            return this.hasFilters ? this.filteredSelection.length : this.currentSelection.length;
        }
    },

    watch: {
        modalOpen(open) {
            if (open === true) {
                const { staticSelection } = this.currentSelection;
                this.savedSelection = staticSelection;
            }
        }
    },

    created() {
        // Don't preselect all lawyers when some have been manually selected already or when adding to assignment with existing lawyers
        if (this.forcedLawyerAndSupplierIds?.length || this.currentSelection?.length) {
            return;
        }

        this.$store.dispatch('users/selectAllLawyersAndSuppliers');
    },

    methods: {
        emitCurrentSelection() {
            this.$eventBus.$emit('custom-participants-selected', { ids: this.hasFilters ? this.filteredSelectionIds : this.currentSelection });
        },

        updateSelectedLawyers() {
            if (this.hasFilters) {
                this.$store.dispatch('users/selectLawyersAndSuppliers', this.filteredSelectionIds);
            } else {
                this.$store.dispatch('users/selectLawyersAndSuppliers', this.currentSelection);
            }
        },

        updateFilters(value) {
            this.hasFilters = value;
        },

        /**
         * Invite lawyers to a new assignment
         */
        nextNew() {
            if (this.saveSelection) {
                this.$store.dispatch('modal/openModal', {
                    name: 'save-user-selection-modal',
                    data: { selection: this.hasFilters ? this.filteredSelectionIds : this.currentSelection }
                });
                this.saveSelection = false;
            } else {
                this.updateSelectedLawyers();
                this.emitCurrentSelection();

                this.$store.dispatch('modal/closeModal', { name: 'select-participants' });
            }
        },

        /**
         * Add lawyers to an existing (published) assignment
         */
        nextAdd() {
            if (!this.assignmentId || !this.addedSelectedLawyerAndSupplierIds?.length) {
                return false;
            }

            this.requestState = 'loading';
            const assignmentId = this.assignmentId;

            AssignmentApi.postParticipants(this.assignmentId, this.addedSelectedLawyerAndSupplierIds).then(() => {
                this.requestState = 'success';

                this.$store.dispatch('modal/closeModal', { name: 'select-participants' });

                this.$store.dispatch('toast/add', {
                    content: this.$i18n.t('modal.assignment-participants.add.request-success')
                });

                this.$router.push({
                    name: 'assignment.detail.candidates',
                    params: {
                        id: assignmentId
                    }
                });
            }).catch((e) => {
                console.error(e);
                this.requestState = 'fail';

                this.$store.dispatch('toast/add', {
                    content: this.$i18n.t('modal.assignment-participants.add.request-fail')
                });
            }).finally(() => {
                setTimeout(() => {
                    this.requestState = undefined;
                }, 2000);
            });
        },

        fetchAssignment() {
            this.$store.dispatch('assignments/fetchById', this.assignmentId);
        }
    }
};
</script>

<style lang="less">
.select-participants {
    .modal__wrapper {
        height: calc(100% - 1rem);

        @media @q-sm-min {
            height: calc(100% - 10rem);
        }
    }
}

.select-participants__filters {
    padding: 1.75rem 1.5rem;
    box-shadow: 1px 0 1px rgba(0, 0, 0, .06);
}

.select-participants__list {
    padding: 1.75rem 1.5rem;

    @media @q-lg-min {
        padding: 0 3rem;
    }
}

.select-participants__banner {
    height: 100%;
    background-color: transparent;
}
</style>
