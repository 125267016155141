'use strict';

import ProposalApi from '~/api/proposals';
import Loader from '~/services/loader/loading-service';

export default {
    fetchById(context, id) {
        Loader.get(`proposal-${id}`).start();

        ProposalApi.getById(id).then((response) => {
            context.commit('proposal', response.data);

            Loader.get(`proposal-${id}`).success();
        }).catch((err) => {
            Loader.get(`proposal-${id}`).fail(err);
        });
    },

    closeProposal(context, id) {
        Loader.get(`proposal-${id}.closing`).start();

        return new Promise((resolve, reject) => {
            ProposalApi.closeProposal(id).then((response) => {
                context.commit('proposal', response.data);

                Loader.get(`proposal-${id}.closing`).success();

                resolve(response);
            }).catch((err) => {
                Loader.get(`proposal-${id}.closing`).fail(err);
                reject(err);
            });
        });
    }
};
