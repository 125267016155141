export function scrollIntoView(elem) {
    const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;

    if (isSmoothScrollSupported) {
        elem.scrollIntoView({
            behavior: 'smooth'
        });
    } else {
        elem.scrollIntoView(false);
    }
}
