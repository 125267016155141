<template>
    <dn-modal
        name="proposals-share"
        size="medium"
        position="centered"
    >
        <modal-content class="modal-content--article">
            <h1 class="text-center">{{ $t('modal.proposals-share.modal-title') }}</h1>
            <p>{{ $t('modal.proposals-share.modal-text') }}</p>
            <form
                v-if="!guestLoginUrl"
                class="form"
                @submit.prevent="getGuestLoginUrl"
            >
                <div class="column-grid form-field">
                    <form-field
                        columns-sm-min="9/12"
                        :required="true"
                    >
                        <input
                            v-if="!isLoading"
                            v-model="email"
                            type="email"
                            placeholder="Email"
                        >
                    </form-field>
                    <form-field columns-sm-min="3/12">
                        <llq-select
                            v-if="!isLoading"
                            v-model="expiration"
                            :options="selectOptions"
                        />
                        <content-placeholders v-else>
                            <content-placeholders-img
                                style="height: 46px;"
                            />
                        </content-placeholders>
                    </form-field>
                </div>
                <div class="column-grid">
                    <form-field columns-sm-min="12/12">
                        <llq-button
                            :disabled="isLoading || guestLoginUrl"
                            class="btn btn--secondary btn--wide"
                            :state="requestState"
                            type="submit"
                        >
                            {{ $t('modal.proposals-share.modal-submit') }}
                        </llq-button>
                    </form-field>
                </div>
            </form>
            <form
                v-else
                class="form"
                @submit.prevent=""
            >
                <div class="column-grid form-field">
                    <form-field
                        class="guest-sign-in-url"
                        columns-sm-min="12/12"
                    >
                        <input
                            v-model="guestLoginUrl"
                            type="text"
                            readonly
                        >
                        <CopyShareButton
                            :url="guestLoginUrl"
                            :show-success-toast="false"
                            :show-error-toast="false"
                            @success="handleCopy"
                        >
                            <svg-icon icon="copy" />
                        </CopyShareButton>
                    </form-field>
                </div>
                <div class="column-grid">
                    <form-field columns-sm-min="12/12">
                        <llq-button
                            :disabled="isLoading || !!guestLoginUrl"
                            class="btn btn--secondary btn--wide"
                            :state="requestState"
                            type="submit"
                        >
                            {{ $t('modal.proposals-share.modal-submit') }}
                        </llq-button>
                    </form-field>
                </div>
            </form>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';
import Bugsnag from '@bugsnag/js';

import { mapGetters } from 'vuex';
import { CopyShareButton } from '@digitalnatives/share-button';

import LlqButton from '~/patterns/atoms/button/button.vue';

import FormField from '~/patterns/molecules/form-field/form-field.vue';

import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';

import LlqSelect from '~/patterns/atoms/select/select.vue';
import UsersApi from '~/api/users';

export default {
    components: {
        DnModal,
        ModalContent,
        FormField,
        LlqButton,
        LlqSelect,
        CopyShareButton
    },

    data() {
        return {
            expiration: 3600,
            requestState: null,
            isLoading: false,
            guestLoginUrl: null,
            email: ''
        };
    },

    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen',
            loggedInUser: 'auth/user',
            modalData: 'modal/data'
        }),

        modalOpen() {
            return this.isOpen('proposals-share');
        },

        selectOptions() {
            return [
                {
                    label: '1 day',
                    value: 60*60*24
                },
                {
                    label: '1 week',
                    value: 60*60*24*7
                },
                {
                    label: '1 month',
                    value: 60*60*24*30
                }
            ];
        }
    },

    watch: {
        modalOpen(open) {
            if (open) {
                this.isLoading = true;
            } else {
                // Reset form on modal close
                this.requestState = null;
                this.guestLoginUrl = null;
                this.email = '';
            }
            this.isLoading = false;
        }
    },

    methods: {
        handleCopy() {
            this.$store.dispatch('toast/add', {
                content: this.$t('modal.proposals-share.copy-toast')
            });
        },

        async getGuestLoginUrl() {
            this.isLoading = true;
            this.requestState = 'loading';

            try {
                const result = await UsersApi.inviteGuest(
                    this.expiration,
                    this.email,
                    this.modalData.assignment?.id
                );

                if (!result || !result.data || !result.data.url) {
                    this.requestState = 'fail';
                }

                this.guestLoginUrl = result.data.url;
                this.requestState = 'success';
            } catch (error) {
                Bugsnag.notify(error);
                this.requestState = 'fail';
            }

            this.isLoading = false;
        }
    }
};
</script>

<style lang="less">
.guest-sign-in-url {
    .form-field__input-wrapper {
        display: flex;
        width: 100%;
        align-items: center;

        .icon {
            margin-left: 1rem;
        }
    }
}

.v-toast.v-toast--bottom {
    z-index: 3000; // Modal is 2000
}

</style>
