'use strict';

import Vue from 'vue';

// The initial state
const State = {
        messages: []
    },

    // Getters get stuff from the store. This is where you can modify data before sending it to your component.
    Getters = {
        messages: ({ messages }) => messages
    },

    // Actions don't modify the state directly,
    // but instead handle more complex logic and then commit something to the state using mutations
    Actions = {
        add({ commit }, message) {
            return commit('addMessage', message);
        },
        remove({ commit }) {
            return commit('removeOldest');
        }
    },

    // Mutations change the actual data in the state
    Mutations = {
        clearAll(state) {
            Vue.set(state.messages, []);
        },

        addMessage(state, payload) {
            const isPayloadObject = typeof payload !== 'string',
                content = isPayloadObject ? payload.content : payload;

            // Check duplicates
            if (!state.messages.find(foundMessage => foundMessage.content === content)) {
                state.messages.push({
                    severity: isPayloadObject && payload.severity ? payload.severity : 'none',
                    content,
                    duration: isPayloadObject && payload.duration ? payload.duration : 6000
                });
            }
        },

        removeOldest(state) {
            state.messages.shift();
        }
    };

// Export everything.
export default {
    namespaced: true,
    state: State,
    getters: Getters,
    actions: Actions,
    mutations: Mutations
};
