
<article
    v-if="previewFields && previewFields.length"
    class="proposal-preview"
>
    <h5
        v-if="showHeading"
        class="proposal-preview__heading"
    >
        {{ $t('proposalPreview.heading') }}
    </h5>
    <proposal-meta
        class="proposal-preview__meta"
        :proposal="stubProposal"
    >
        <template #starts_at>
            {{ $t('proposalPreview.date', { date: stubDate }) }}
        </template>

        <template #availability>
            {{ $t('proposalPreview.availability', {
                availability: $tc(
                    `availabilityWithAmount.${stubProposal.availability_per_time_unit}`,
                    stubProposal.availability_amount
                )
            }) }}
        </template>
    </proposal-meta>
</article>
