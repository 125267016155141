
<selectable-list
    :selectable-items="users"
    :allow-selection="allowSelection"
    :show-header="showHeader"
    :force-selected-ids="forceSelectedUserIds"
    :preselect-ids="preselectIds"
    @selectAll="selectAllLawyersAndSuppliers"
    @deselectAll="deselectAllLawyersAndSuppliers"
    @input="passThroughInput"
    @toggle="maybeToggleInStore"
>
    <template #title>
        {{ $tc('selectableList.expert-list.title', users.length, { count: users.length }) }}
    </template>

    <template #header_actions>
        <slot name="header_actions" />
    </template>

    <template #preview="{ item }">
        <expert-preview
            :expert="item"
            :class="{ 'expert-preview--select': !!smallerPreview }"
            :link-to-new-tab="linkExpertsToNewTab"
        />
    </template>

    <template #cta>
        <slot name="cta" />
    </template>
</selectable-list>
