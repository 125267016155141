
<div
    class="avatar"
    :data-actionable="linkToProfile"
    @click="maybeTriggerModal"
>
    <div
        v-if="user && avatarUrl"
        key="avatar-image"
        v-lazy:background-image="{
            src: avatarUrl
        }"
        class="avatar__image"
    />

    <div
        v-if="!avatarUrl"
        key="avatar-initials"
        class="avatar__initials"
    >
        <slot>{{ initials }}</slot>
    </div>

    <activity-indicator
        :is-active="isUnread"
        class="activity-indicator--bordered"
    />
</div>
