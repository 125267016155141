<span class="file-attach link--clean" data-has-file="!!uploadedFile">
    <template v-if="!uploadedFile && !file && !uploading">
        <svg-icon icon="attachments" @click.native="openFileDialog" />

        <span class="file-attach__title" @click="openFileDialog">
            {{ $t('fileAttach.select-file') }}
        </span>
    </template>

    <template v-if="uploading">
        <svg-icon icon="attachment" />

        <span class="file-attach__preview">
            {{ $t('fileAttach.uploading') }}
        </span>

        <svg-icon icon="close" @click.native="cancelUpload" />
    </template>

    <template v-if="uploadedFile">
        <svg-icon v-if="!hideIcon" icon="attachments" />

        <span class="file-attach__preview">
            {{ uploadedFile.name }}
        </span>

        <svg-icon class="file-attach__clear" icon="close" @click.native="clearFile" />
    </template>

    <input name="file" @change="uploadFile" class="file-attach__input" type="file" ref="fileInput" :accept="accept" />
</span>
