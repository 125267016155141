<template>
    <nav
        v-if="filteredLinks && filteredLinks.length > 0"
        class="sub-navigation"
    >
        <div
            v-if="!!$slots.header"
            class="sub-navigation__header"
        >
            <slot name="header" />
        </div>
        <div class="sub-navigation__container">
            <div class="sub-navigation__body">
                <ol :data-link-count="filteredLinks.length">
                    <li
                        v-for="(link, index) in filteredLinks"
                        :key="index"
                    >
                        <router-link
                            :to="{
                                name: link.routeName,
                                params: link.params || {}
                            }"
                            exact
                        >
                            {{ link.prettyName }}

                            <activity-indicator :is-active="link.isUnread" />
                        </router-link>
                    </li>
                </ol>

                <div
                    v-if="isActivePage"
                    class="sub-navigation__underline"
                    :data-active-link="activeRouteIndex + 1"
                />
            </div>
            <div
                v-if="$slots.subnavigationAside"
                class="sub-navigation__aside"
            >
                <slot name="subnavigationAside" />
            </div>
        </div>
    </nav>
</template>

<script>
import ActivityIndicator from '~/patterns/atoms/activity-indicator/activity-indicator.vue';

export default {

    components: {
        ActivityIndicator
    },
    props: {
        links: {
            required: false,
            type: Array,
            default: () => []
        }
    },

    computed: {
        isActivePage() {
            return this.activeRouteIndex > -1;
        },

        filteredLinks() {
            if (this.links.length === 0) {
                return [];
            }

            if (this.isGuest()) {
                return this.links.filter(({ routeName }) => routeName === 'assignment.detail.proposals');
            }

            return this.links;
        },

        activeRouteIndex() {
            const activeRouteName = this.$route.name;

            return this.links.findIndex(route => activeRouteName === route.routeName);
        }
    }
};
</script>

<style lang="less">
@import './sub-navigation.less';
</style>
