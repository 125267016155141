<template>
    <default-view>
        <llq-notice
            v-if="$auth.error"
            severity="danger"
        >
            <p
                v-if="$route.query.error === 'access_denied' && $route.query.error_description === 'Signup disabled'"
            >
                <i18n
                    path="auth.signup-attempt"
                    tag="p"
                >
                    <router-link to="/logout">
                        {{ $t('auth.logout') }}
                    </router-link>
                </i18n>
            </p>
            <p v-else>
                <strong>{{ $auth.error }}</strong>
                {{ $t('general.error') }}
            </p>
        </llq-notice>
        <loading-indicator v-else />
    </default-view>
</template>

<script>
'use strict';

import DefaultView from '~/patterns/templates/default-view/default-view.vue';
import LoadingIndicator from '~/patterns/molecules/loading-indicator/loading-indicator.vue';
import LlqNotice from '~/patterns/atoms/notice/notice.vue';

export default {
    components: {
        DefaultView,
        LoadingIndicator,
        LlqNotice
    },

    computed: {
        isAuthenticated() {
            return this.$auth.isAuthenticated;
        }
    },

    async mounted() {
        const callback = async() => {
            if (this.isAuthenticated) {
                return this.grantAccess();
            }

            if (!this.$auth.error) {
                await this.$auth.loginWithRedirect({
                    appState: { targetUrl: this.$route.params.requestedRoute?.fullPath },
                    authorizationParams: {
                        ui_locales: window.appConfig.language || 'en'
                    }
                });
            }
        };

        if (this.$auth.loading === false) {
            return await callback();
        }

        // Watch for the loading property to change before we check isAuthenticated
        this.$auth.$watch('loading', async(loading) => {
            if (loading === false) {
                return await callback();
            }
        });
    },

    methods: {
        grantAccess() {
            if (this.$route.params.requestedRoute) {
                return this.$router.replace(this.$route.params.requestedRoute.fullPath);
            }

            return this.$router.replace('/');
        }
    }
};
</script>
