<template>
    <div class="select">
        <select
            class="select__control"
            @input="e => $emit('input', e.target.value)"
            @change="e => $emit('change', e.target.value)"
            @blur="e => $emit('blur', e.target.value)"
        >
            <option
                v-if="placeholder"
                value=""
                disabled
                :selected="!value"
            >
                {{ placeholder }}
            </option>

            <option
                v-for="option in options"
                :key="option.value"
                :selected="option.value === value"
                :value="option.value"
            >
                {{ option.label }}
            </option>
        </select>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: [String, Number],
            required: false,
            default: null
        },
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="less">
.select {
    .input();

    display: grid;
    grid-template-areas: 'select';

    &:focus-within {
        border-color: var(--color-action);
    }
}

.select__control {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    // Additional resets for further consistency
    background-color: transparent;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    outline: none;
    border: 0;

    // Specific styles
    grid-area: select;
    display: grid;
    cursor: pointer;
    padding: var(--spacing-y) var(--spacing-x);
}

.select:after {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5em;
    grid-area: select;
    justify-self: end;
    align-self: center;
    margin-right: var(--spacing-y);

    background: url('@/assets/img/icons/chevron-down.svg') no-repeat center center;
    background-size: 100%;
}

.select option[disabled] {
    color: var(--color-black-50);
}

.dn-form-field[data-has-errors='true'] .select {
    border-color: var(--color-highlight-error);
}
</style>
