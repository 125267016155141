<template>
    <div class="closed-message">
        <svg-icon
            icon="close"
            class="icon--danger"
        /><span class="closed-message__text"><slot /></span>
    </div>
</template>

<script>
'use strict';

export default {

};
</script>

<style lang="less">
@import './closed-message.less';
</style>
