
<div
    class="search-bar"
    :data-controls-visible="!!query"
>
    <div class="search-bar__bar">
        <div class="search-bar__icon">
            <svg-icon icon="search" />
        </div>

        <input
            v-model="query"
            type="text"
            class="search-bar__input"
            :placeholder="$t('searchBar.placeholder')"
            @change="onInputChange"
        >

        <transition name="fade-absolute">
            <div
                v-if="query"
                class="search-bar__controls"
            >
                <button
                    class="btn btn--clean search-bar__clear"
                    @click="clearInput"
                >
                    <svg-icon icon="close" />
                </button>
            </div>
        </transition>
    </div>
</div>
