
<aside-view
    v-if="loggedInUser"
    class="aside-view--compact aside-view--hide-sidebar-mobile"
    :sticky="true"
>
    <template v-slot:sidebar>
        <table-of-contents :links="tocLinks" />
    </template>

    <template v-slot:body>
        <!--
    Profile
    -->
        <panel
            id="profile"
            :title="$t('profileView.title.profile')"
        >
            <form
                class="form"
                data-vv-scope="profile"
            >
                <form-field>
                    <file-upload
                        :id="'profile-edit__avatar'"
                        v-model="formData.profile.avatar"
                        name="avatar"
                        class="file-upload--avatar"
                        :thumbnail="formData.profile.avatar ? getImage(formData.profile.avatar) : ''"
                    />
                </form-field>

                <form-field
                    class="form-field--horizontal"
                    :error-messages="errors.collect('profile.first_name')"
                >
                    <label
                        slot="label"
                        for="first_name"
                    >
                        {{ $t('profileView.first_name.label') }}
                    </label>

                    <input
                        id="first_name"
                        v-model="formData.profile.first_name"
                        v-validate="'required'"
                        type="text"
                        name="first_name"
                        :placeholder="$t('profileView.first_name.placeholder')"
                    >
                </form-field>

                <form-field
                    class="form-field--horizontal"
                    :error-messages="errors.collect('profile.last_name')"
                >
                    <label
                        slot="label"
                        for="last_name"
                    >
                        {{ $t('profileView.last_name.label') }}
                    </label>

                    <input
                        id="last_name"
                        v-model="formData.profile.last_name"
                        v-validate="'required'"
                        type="text"
                        name="last_name"
                        :placeholder="$t('profileView.last_name.placeholder')"
                    >
                </form-field>

                <form-field
                    class="form-field--horizontal"
                    :error-messages="errors.collect('profile.city')"
                >
                    <label
                        slot="label"
                        for="city"
                    >
                        {{ $t('profileView.city.label') }}
                    </label>

                    <input
                        id="city"
                        v-model="formData.profile.city"
                        type="text"
                        name="city"
                        :placeholder="$t('profileView.city.placeholder')"
                    >
                </form-field>

                <form-field class="form-field--last text-right">
                    <v-button
                        class="btn--secondary"
                        :state="requestState.profile"
                        @click="submit('profile')"
                    >
                        {{ $t('profileView.save') }}
                    </v-button>
                </form-field>
            </form>
        </panel>

        <!--
    Domain
    -->
        <panel
            id="discipline"
            :title="$t('profileView.title.discipline')"
        >
            <form
                class="form"
                data-vv-scope="discipline"
            >
                <form-field
                    class="form-field--horizontal"
                    :error-messages="errors.collect('discipline.lawyer_title')"
                >
                    <label
                        slot="label"
                        for="lawyer_title"
                    >
                        {{ $t('profileView.lawyer_title.label') }}
                    </label>

                    <input
                        id="lawyer_title"
                        v-model="formData.discipline.lawyer_title"
                        type="text"
                        name="lawyer_title"
                        :placeholder="$t('profileView.lawyer_title.placeholder')"
                    >
                </form-field>

                <form-field
                    class="form-field--horizontal form-field--label-centered"
                    :error-messages="errors.collect('discipline.assignment_types')"
                >
                    <label
                        slot="label"
                        for="assignment_types"
                    >
                        {{ $t('profileView.assignment_types.label') }}
                    </label>

                    <checkbox
                        v-model="formData.discipline.assignment_types"
                        class="checkbox--inline"
                        name="assignment_types"
                        value="interim"
                    >
                        {{ $t('profileView.assignment_types.interim') }}
                    </checkbox>

                    <checkbox
                        v-model="formData.discipline.assignment_types"
                        class="checkbox--inline"
                        name="assignment_types"
                        value="advice"
                    >
                        {{ $t('profileView.assignment_types.advice') }}
                    </checkbox>

                    <checkbox
                        v-model="formData.discipline.assignment_types"
                        class="checkbox--inline"
                        name="assignment_types"
                        value="litigation"
                    >
                        {{ $t('profileView.assignment_types.litigation') }}
                    </checkbox>
                </form-field>

                <form-field
                    class="form-field--horizontal form-field--label-centered"
                    :error-messages="errors.collect('discipline.lawyer_types')"
                >
                    <label
                        slot="label"
                        for="lawyer_types"
                    >
                        {{ $t('profileView.lawyer_types.label') }}
                    </label>

                    <multiselect
                        v-model="formData.discipline.lawyer_types"
                        :options="lawyerTypeOptions"
                        :placeholder="$t('profileView.lawyer_types.placeholder')"
                        :searchable="false"
                        :show-labels="false"
                        :multiple="true"
                        :custom-label="lawyerTypeLabel"
                    />
                </form-field>

                <form-field
                    class="form-field--horizontal form-field--label-centered"
                    :error-messages="errors.collect('discipline.fields_of_law')"
                >
                    <label
                        slot="label"
                        for="fields_of_law"
                    >
                        {{ $t('profileView.fields_of_law.label') }}
                    </label>

                    <multiselect
                        v-model="formData.discipline.fields_of_law"
                        :options="fieldOfLawOptions"
                        :placeholder="$t('profileView.fields_of_law.placeholder')"
                        :searchable="true"
                        :show-labels="false"
                        :multiple="true"
                        :custom-label="fieldOfLawLabel"
                    />
                </form-field>

                <form-field
                    class="form-field--horizontal form-field--label-centered"
                    :error-messages="errors.collect('discipline.specialisations')"
                >
                    <label
                        slot="label"
                        for="specialisations"
                    >
                        {{ $t('profileView.specialisations.label') }}
                    </label>

                    <multiselect
                        v-model="formData.discipline.specialisations"
                        :tag-placeholder="$t('profileView.specialisations.tag-placeholder')"
                        :placeholder="$t('profileView.specialisations.placeholder')"
                        tag-position="bottom"
                        :options="specialisationOptions"
                        :multiple="true"
                        :taggable="true"
                        :show-no-options="false"
                        @tag="addSpecialisationTag"
                    />
                </form-field>

                <form-field
                    class="form-field--horizontal form-field--label-centered"
                    :error-messages="errors.collect('discipline.domain_expertises')"
                >
                    <label
                        slot="label"
                        for="domain_expertises"
                    >
                        {{ $t('profileView.domain_expertises.label') }}
                    </label>

                    <multiselect
                        v-model="formData.discipline.domain_expertises"
                        :tag-placeholder="$t('profileView.domain_expertises.tag-placeholder')"
                        :placeholder="$t('profileView.domain_expertises.placeholder')"
                        tag-position="bottom"
                        :options="domainExpertiseOptions"
                        :multiple="true"
                        :taggable="true"
                        :show-no-options="false"
                        @tag="addDomainExpertiseTag"
                    />
                </form-field>

                <form-field
                    class="form-field--horizontal form-field--label-centered"
                    :error-messages="errors.collect('discipline.business_languages')"
                >
                    <label
                        slot="label"
                        for="business_languages"
                    >
                        {{ $t('profileView.business_languages.label') }}
                    </label>

                    <multiselect
                        v-model="formData.discipline.business_languages"
                        :options="businessLanguageOptions"
                        :placeholder="$t('profileView.business_languages.placeholder')"
                        :searchable="true"
                        :show-labels="false"
                        :multiple="true"
                        :custom-label="businessLanguageLabel"
                    />
                </form-field>

                <form-field class="form-field--last text-right">
                    <v-button
                        class="btn--secondary"
                        :state="requestState.discipline"
                        @click="submit('discipline')"
                    >
                        {{ $t('profileView.save') }}
                    </v-button>
                </form-field>
            </form>
        </panel>

        <!--
    Contact
    -->
        <panel
            id="contact"
            :title="$t('profileView.title.contact')"
        >
            <form
                class="form"
                data-vv-scope="contact-info"
            >
                <form-field
                    class="form-field--horizontal"
                    :error-messages="errors.collect('contact-info.contact_email')"
                >
                    <label
                        slot="label"
                        for="contact_email"
                    >
                        {{ $t('profileView.contact_email.label') }}
                    </label>

                    <input
                        id="contact_email"
                        v-model="formData['contact-info'].contact_email"
                        v-validate="'email'"
                        type="email"
                        name="contact_email"
                        :placeholder="$t('profileView.contact_email.placeholder')"
                    >
                </form-field>

                <form-field
                    class="form-field--horizontal"
                    :error-messages="errors.collect('contact-info.phone_number')"
                >
                    <label
                        slot="label"
                        for="phone_number"
                    >
                        {{ $t('profileView.phone_number.label') }}
                    </label>

                    <input
                        id="phone_number"
                        v-model="formData['contact-info'].phone_number"
                        type="tel"
                        name="phone_number"
                        :placeholder="$t('profileView.phone_number.placeholder')"
                    >
                </form-field>

                <form-field
                    class="form-field--horizontal"
                    :error-messages="errors.collect('contact-info.website_url')"
                >
                    <label
                        slot="label"
                        for="website_url"
                    >
                        {{ $t('profileView.website_url.label') }}
                    </label>

                    <input
                        id="website_url"
                        v-model="formData['contact-info'].website_url"
                        v-validate="'url:require_protocol'"
                        type="url"
                        name="website_url"
                        :placeholder="$t('profileView.website_url.placeholder')"
                    >
                </form-field>

                <form-field
                    class="form-field--horizontal"
                    :error-messages="errors.collect('contact-info.linkedin_url')"
                >
                    <label
                        slot="label"
                        for="linkedin_url"
                    >
                        {{ $t('profileView.linkedin_url.label') }}
                    </label>

                    <input
                        id="linkedin_url"
                        v-model="formData['contact-info'].linkedin_url"
                        v-validate="'url:require_protocol'"
                        type="url"
                        name="linkedin_url"
                        :placeholder="$t('profileView.linkedin_url.placeholder')"
                    >
                </form-field>

                <form-field class="form-field--last text-right">
                    <v-button
                        class="btn--secondary"
                        :state="requestState['contact-info']"
                        @click="submit('contact-info')"
                    >
                        {{ $t('profileView.save') }}
                    </v-button>
                </form-field>
            </form>
        </panel>

        <!--
    Bio
    -->
        <panel
            id="bio"
            :title="$t('profileView.title.bio')"
        >
            <form
                class="form"
                data-vv-scope="bio"
            >
                <form-field
                    class="form-field--horizontal"
                    :error-messages="errors.collect('bio.bio')"
                >
                    <label
                        slot="label"
                        for="bio"
                    >
                        {{ $t('profileView.bio.label') }}
                    </label>

                    <textarea
                        id="bio"
                        v-model="formData.bio.bio"
                        v-validate="'max:1000'"
                        name="bio"
                        rows="8"
                        :placeholder="$t('profileView.bio.placeholder')"
                    />
                </form-field>

                <form-field class="form-field--last text-right">
                    <v-button
                        class="btn--secondary"
                        :state="requestState['bio']"
                        @click="submit('bio')"
                    >
                        {{ $t('profileView.save') }}
                    </v-button>
                </form-field>
            </form>
        </panel>

        <!--
    Highlights
     -->
        <panel
            id="highlights"
            :title="$t('profileView.title.highlights')"
        >
            <form
                class="form"
                data-vv-scope="highlights"
            >
                <form-field
                    :error-messages="errors.collect('highlights.highlights')"
                >
                    <highlight-field
                        v-model="formData.highlights.highlights"
                        :sortable="false"
                    />
                </form-field>

                <form-field class="form-field--last text-right">
                    <v-button
                        class="btn--secondary"
                        :state="requestState['highlights']"
                        @click="submit('highlights')"
                    >
                        {{ $t('profileView.save') }}
                    </v-button>
                </form-field>
            </form>
        </panel>
        <highlight-form-modal />

        <!--
    Timeline
    -->
        <panel
            id="timeline"
            :title="$t('profileView.title.timeline')"
        >
            <form
                class="form"
                data-vv-scope="timeline"
            >
                <form-field
                    :error-messages="errors.collect('timeline.timeline')"
                >
                    <timeline-field
                        v-model="formData.timeline.timeline"
                        :sortable="false"
                    />
                </form-field>

                <form-field class="form-field--last text-right">
                    <v-button
                        class="btn--secondary"
                        :state="requestState['timeline']"
                        @click="submit('timeline')"
                    >
                        {{ $t('profileView.save') }}
                    </v-button>
                </form-field>
            </form>
        </panel>

        <!--
    Account
    -->
        <panel
            id="account"
            :title="$t('profileView.title.account')"
        >
            <form-field>
                <router-link
                    class="btn btn--link-secondary btn--small"
                    :to="{ name: 'logout' }"
                >
                    {{ $t('navDropdown.links.logout') }}
                </router-link>
            </form-field>

            <form-field>
                <a
                    class="btn btn--link-secondary btn--small"
                    :href="`mailto:moderator@lawyerlinq.com?subject=Please remove my profile&body=Please remove my profile with the e-mail address: ${loggedInUser.email}`"
                >
                    {{ $t('profileView.delete_profile') }}
                </a>
            </form-field>
        </panel>
    </template>
</aside-view>
