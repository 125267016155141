import Vue from 'vue';

export default {
    getTerms() {
        return Vue.axios.get('/v1.0/terms');
    },

    acceptTerms() {
        return Vue.axios.post('/v1.0/terms/accept');
    }
};
