
<div class="repeater">
    <div
        v-for="(item, index) in items"
        :key="index"
        class="repeater__item"
        :class="{ 'column-grid': columns }"
    >
        <slot
            name="items"
            :index="index"
        />

        <div class="repeater__buttons">
            <slot
                name="buttons"
                :index="index"
            />

            <v-button
                v-if="items.length > 1 && item.required !== true"
                class="repeater__button"
                @click="removeItem(index)"
            >
                <svg-icon icon="trash" />
            </v-button>

            <v-button
                v-if="index === items.length - 1"
                class="repeater__button btn--secondary"
                @click="addItem"
            >
                <svg-icon icon="plus" />
            </v-button>
        </div>

        <slot
            name="info"
            :index="index"
        />
    </div>

    <slot name="total" />
</div>
