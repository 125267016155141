
<div class="cta-bar">
    <div class="cta-bar__body">
        <slot name="body" />
    </div>

    <div class="cta-bar__cta">
        <slot name="cta" />
    </div>
</div>
