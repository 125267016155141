'use strict';

import { clone } from 'lodash-es';
import Vue from 'vue';

export default {
    hideProposal(state, proposal) {
        state.hiddenProposals.push(proposal);
    },

    showProposal(state, { id }) {
        Vue.set(state, 'hiddenProposals', state.hiddenProposals.filter(proposal => proposal.id !== id));
    },

    showWithdrawnProposals(state) {
        state.showWithdrawnProposals = true;
    },

    hideWithdrawnProposals(state) {
        state.showWithdrawnProposals = false;
    },

    resetHiddenProposals(state) {
        Vue.set(state, 'hiddenProposals', []);
    },

    headerCollapsed(state, collapsed) {
        state.header.collapsed = collapsed;
    },

    sidebarOpen(state, sidebarOpen) {
        document.body.setAttribute('data-sidebar-open', sidebarOpen ? 'true' : 'false');
        state.sidebarOpen = sidebarOpen;
    },

    returnLink(state, returnLink) {
        state.returnLink = returnLink;
    },

    icon(state, payload) {
        if (!payload.name || !payload.template) {
            return false;
        }

        const icons = clone(state.icons);
        icons[payload.name] = payload.template;

        state.icons = icons;
    },

    requestIcon(state, iconName) {
        if (!iconName || state.requestedIcons.includes(iconName)) {
            return false;
        }

        state.requestedIcons.push(iconName);
    },

    haircutTooltipDoNotShow(state, item) {
        state.haircutTooltipDoNotShow.push(item);
    },

    haircutTooltipClosed(state, item) {
        state.haircutTooltipClosed.push(item);
    },

    commentBoxSelected(state, selected) {
        state.commentBoxSelected = selected;
    },

    commentBoxPrefill(state, content) {
        state.commentBoxPrefill = content;
    },

    preventModal(state, prevent) {
        state.preventModal = prevent;
    },

    /**
     * cacheKey
     *
     * Set a new cacheKey, which can be used to cache-bust images.
     *
     * @param  {Object} state Store's state
     * @param  {String/Number} cacheKey New cacheKey
     */
    cacheKey(state, cacheKey) {
        if (!cacheKey || cacheKey === state.cacheKey) {
            return false;
        }

        state.cacheKey = cacheKey;
    }
};
