
<button
    class="btn"
    :type="type"
    :data-state="buttonState ? buttonState : 'default'"
    :data-show-label="!buttonState || !showState ? 'true' : 'false'"
    :disabled="isDisabled"
    @click="onClick"
>
    <span class="btn__label"><slot /></span>

    <transition name="fade-absolute">
        <span
            v-if="buttonState === 'loading'"
            key="loading"
            class="btn__state"
        >
            <spinner class="spinner--button" />
        </span>

        <span
            v-else-if="buttonState === 'success' && showState"
            key="success"
            class="btn__state"
        >{{ successLabel }}</span>

        <span
            v-else-if="buttonState === 'fail' && showState"
            key="fail"
            class="btn__state"
        >{{ failLabel }}</span>
    </transition>
</button>
