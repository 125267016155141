'use strict';

import { isEmpty } from 'lodash-es';

import { UPDATED_COMMUNITIES, NEW_ASSIGNMENTS, UPDATED_ASSIGNMENTS, UPDATED_THREADS } from '~/constants/activities';

export default {
    [UPDATED_COMMUNITIES](state, updatedCommunityIds) {
        if (!updatedCommunityIds) {
            state[UPDATED_COMMUNITIES] = false;
        }

        state[UPDATED_COMMUNITIES] = updatedCommunityIds;
    },

    [NEW_ASSIGNMENTS](state, newAssignmentIds) {
        if (!newAssignmentIds) {
            state[NEW_ASSIGNMENTS] = false;
        }
        state[NEW_ASSIGNMENTS] = newAssignmentIds;
    },

    [UPDATED_ASSIGNMENTS](state, updatedAssignmentsObject) {
        if (!updatedAssignmentsObject || isEmpty(updatedAssignmentsObject)) {
            state[UPDATED_ASSIGNMENTS] = false;
        }

        state[UPDATED_ASSIGNMENTS] = updatedAssignmentsObject;
    },

    [UPDATED_THREADS](state, updatedThreadIds) {
        if (!updatedThreadIds) {
            state[UPDATED_THREADS] = false;
        }

        state[UPDATED_THREADS] = updatedThreadIds;
    }
};
