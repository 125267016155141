<template>
    <dn-modal
        name="assignment-push"
        size="medium"
        position="centered"
    >
        <modal-content class="modal-content--article">
            <h1 class="text-center">{{ $t('modal.assignment-push.modal-title') }}</h1>
            <p>{{ $t('modal.assignment-push.modal-text') }}</p>

            <form
                class="form"
                @submit.prevent="pushAssignment"
            >
                <form-field>
                    <llq-select
                        v-if="!isLoading"
                        v-model="selectedCommunityId"
                        :options="selectOptions"
                    />
                    <content-placeholders v-else>
                        <content-placeholders-img
                            style="height: 46px;"
                        />
                    </content-placeholders>
                </form-field>
                <div class="column-grid">
                    <form-field
                        columns-sm-min="7/12"
                        column-centered
                    >
                        <llq-checkbox
                            id="ask_for_cv"
                            v-model="notifyOnPush"
                            class="checkbox--inline"
                            name="notify_on_push"
                        >
                            {{ $t('modal.assignment-push.notify-client') }}
                        </llq-checkbox>
                    </form-field>
                    <form-field columns-sm-min="5/12">
                        <llq-button
                            :disabled="isLoading || !selectedCommunity || !assignment"
                            class="btn btn--secondary btn--wide"
                            :state="requestState"
                            type="submit"
                        >
                            {{ $t('modal.assignment-push.modal-submit') }}
                        </llq-button>
                    </form-field>
                </div>
            </form>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import Bugsnag from '@bugsnag/js';

import LlqButton from '~/patterns/atoms/button/button.vue';
import LlqCheckbox from '~/patterns/atoms/checkbox/checkbox.vue';

import FormField from '~/patterns/molecules/form-field/form-field.vue';

import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';

import CommunitiesQuery from '~/graphql/queries/community/Communities.graphql';
import AssignmentPushData from '~/graphql/queries/assignment/AssignmentPushData.graphql';
import LlqSelect from '~/patterns/atoms/select/select.vue';

export default {
    components: {
        DnModal,
        ModalContent,
        FormField,
        LlqButton,
        LlqCheckbox,
        LlqSelect
    },

    data() {
        return {
            assignment: null,
            selectedCommunityId: null,
            autoSelectedCommunity: null,
            requestState: null,
            isLoading: false,
            targetCommunities: null,
            notifyOnPush: false
        };
    },

    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen',
            loggedInUser: 'auth/user',
            modalData: 'modal/data'
        }),

        modalOpen() {
            return this.isOpen('assignment-push');
        },

        selectedCommunity() {
            return this.targetCommunities?.find(community => community.id === this.selectedCommunityId);
        },

        selectOptions() {
            return this.targetCommunities?.map((community) => {
                return { label: community.name, value: community.id };
            });
        }
    },

    watch: {
        async modalData() {
            if (this.modalOpen && this.modalData?.assignment?.id) {
                this.isLoading = true;
                const { data } = await this.$executeGqlQuery(AssignmentPushData, { id: this.modalData.assignment.id });
                this.assignment = data.assignment;
                this.isLoading = false;
            }
        },
        async modalOpen(open) {
            if (open) {
                this.isLoading = true;

                try {
                    const { data } = await this.$executeGqlQuery(CommunitiesQuery, { isTargetCommunity: true, isManager: true });

                    // Remove current community from the list
                    const targetCommunities = data.communities.filter((community) => {
                        return community.id !== this.$store.getters['community/community'].id ? community : false;
                    });

                    // Preselect a community called "LawyerlinQ usergroup", if present.
                    const userGroupCommunity = targetCommunities.find((community) => {
                        return community.name === 'LawyerlinQ usergroup' ? community : false;
                    });

                    this.selectedCommunityId = userGroupCommunity?.id || targetCommunities?.[0]?.id;
                    this.targetCommunities = targetCommunities;

                    this.isLoading = false;
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.error(error);
                    Bugsnag.notify(error);
                }
            }
        }
    },

    methods: {
        pushAssignment() {
            if (!this.selectedCommunity) {
                return;
            }

            if (!this.assignment || !this.assignment.id) {
                this.requestState = 'fail';
                return;
            }
            this.requestState = 'loading';

            // Instantiate a new client which creates an assignment concept on the target community API
            const axiosClient = this.createAxiosClient(this.selectedCommunity.app_url);

            axiosClient.post('/v1.0/assignments/autosave', {
                type: 'assignment',
                title: this.assignment.title,
                content: this.assignment.content,
                ends_at: this.assignment.ends_at,
                notify_on_push: this.notifyOnPush,
                original_community_id: this.$store.getters['community/community'].id,
                original_assignment_id: this.assignment.id
            }).then((response) => {
                this.requestState = 'success';
                const newAssignmentUrl = this.selectedCommunity.app_url + '/assignment/create?id=' + response.data.id;
                window.location.replace(newAssignmentUrl);
            }).catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
                this.requestState = 'fail';
            }).then(() => {
                setTimeout(() => {
                    this.requestState = null;
                }, 2000);
            });
        },

        createAxiosClient(baseUrl) {
            return this.axios.create({
                baseURL: baseUrl + '/api/'
            });
        }
    }
};
</script>
