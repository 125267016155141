
<page-header
    :collapsible="true"
    :is-collapsed="headerCollapsed"
>
    <template #preTitle>
        <community-logo />
    </template>

    <template #title>
        {{ community.name }}
    </template>
</page-header>
