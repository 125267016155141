
<dn-modal
    name="assignment-push"
    size="medium"
    position="centered"
>
    <modal-content class="modal-content--article">
        <h1 class="text-center">{{ $t('modal.assignment-push.modal-title') }}</h1>
        <p>{{ $t('modal.assignment-push.modal-text') }}</p>

        <form
            class="form"
            @submit.prevent="pushAssignment"
        >
            <form-field>
                <llq-select
                    v-if="!isLoading"
                    v-model="selectedCommunityId"
                    :options="selectOptions"
                />
                <content-placeholders v-else>
                    <content-placeholders-img
                        style="height: 46px;"
                    />
                </content-placeholders>
            </form-field>
            <div class="column-grid">
                <form-field
                    columns-sm-min="7/12"
                    column-centered
                >
                    <llq-checkbox
                        id="ask_for_cv"
                        v-model="notifyOnPush"
                        class="checkbox--inline"
                        name="notify_on_push"
                    >
                        {{ $t('modal.assignment-push.notify-client') }}
                    </llq-checkbox>
                </form-field>
                <form-field columns-sm-min="5/12">
                    <llq-button
                        :disabled="isLoading || !selectedCommunity || !assignment"
                        class="btn btn--secondary btn--wide"
                        :state="requestState"
                        type="submit"
                    >
                        {{ $t('modal.assignment-push.modal-submit') }}
                    </llq-button>
                </form-field>
            </div>
        </form>
    </modal-content>
</dn-modal>
