
<dn-modal
    v-if="userId"
    name="user-profile"
    position="centered"
    size="large"
>
    <user-profile :id="userId" />

    <template
        v-if="isClient() || isAdmin()"
        #aside
    >
        <user-notes :user-id="userId" />
    </template>
</dn-modal>
