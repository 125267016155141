<template>
    <div class="loading-indicator">
        <spinner class="spinner--small" />
    </div>
</template>

<script>
'use strict';

import Spinner from '~/patterns/atoms/spinner/spinner.vue';

export default {
    components: {
        Spinner
    }
};
</script>

<style lang="less">
@import './loading-indicator.less';
</style>
