<template>
    <div
        v-if="tariff && llqFeePercentage !== 0"
        class="tariff-calculation"
    >
        <span class="tariff-calculation__label">
            {{ $t('tariffCalculation.message') }}
        </span>
        <span>
            {{ $n(correctedTariff, 'currency') }}
        </span>
    </div>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import ProposalFees from '~/helpers/proposal-fees';

export default {
    props: {
        tariff: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        ...mapGetters({
            loggedInUser: 'auth/user',
            community: 'community/community'
        }),

        llqFeePercentage() {
            return ProposalFees.getLawyerlinqFeePercentage(this.loggedInUser);
        },

        llqFeeDecimal() {
            return this.llqFeePercentage / 100;
        },

        correctedTariff() {
            return this.tariff - (this.tariff * this.llqFeeDecimal);
        },
    }
};
</script>

<style lang="less">
@import './tariff-calculation.less';
</style>
