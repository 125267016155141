
<div
    v-if="users"
    class="selected-users"
    :data-light="!!light"
>
    <h4
        v-if="showTitle"
        class="selected-users__title"
    >
        {{ $t('selectedUsers.title') }}
    </h4>

    <div
        v-for="(user, index) in slicedUsers"
        :key="user.id"
        class="selected-users__item"
        :style="{
            'zIndex': index
        }"
    >
        <avatar
            :user="user"
            :class="{ 'avatar--light': !!light }"
        />
    </div>

    <div
        v-if="users.length > maxUsers"
        class="selected-users__item"
        :style="{
            'zIndex': slicedUsers.length
        }"
    >
        <avatar
            class="avatar--overflow"
            :link-to-profile="false"
        >
            +{{ users.length - maxUsers }}
        </avatar>
    </div>

    <div
        v-if="allowSelect"
        class="selected-users__item"
        :style="{
            'zIndex': slicedUsers.length
        }"
    >
        <transition
            name="fade"
            mode="out-in"
        >
            <button
                v-if="slicedUsers.length > 0"
                v-tooltip="{ content: tooltipLabel }"
                class="btn btn--secondary btn--circle"
                :title="tooltipLabel"
                @click="$emit('click-select-user')"
            >
                <svg-icon icon="plus" />
            </button>

            <button
                v-else
                class="btn btn--medium"
                :title="tooltipLabel"
                @click="$emit('click-select-user')"
            >
                {{ tooltipLabel }}
            </button>
        </transition>
    </div>
</div>
