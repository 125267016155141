
<dn-modal
    name="proposal-preview"
    position="centered"
    size="medium"
>
    <modal-content>
        <section class="text-center">
            <h2>{{ $t('modal.proposal-preview.title') }}</h2>
        </section>

        <section>
            <proposal-preview
                :show-heading="false"
                :preview-fields="requestedInformation"
                :custom-descriptions="customDescriptions"
                :workload-fields="workloadFields"
                :open-questions="openQuestions"
            />
        </section>
    </modal-content>
</dn-modal>
