
<transition
    name="fade"
    mode="out-in"
>
    <div
        v-if="loader.successful()"
        key="content"
    >
        <slot />
    </div>

    <loading-indicator
        v-if="loader.loading()"
        key="loading"
    />
    <div
        v-if="loader.failed()"
        key="failed"
        class="load-status__error"
    >
        <h4 class="text-center">{{ $t('loadStatus.failed') }}</h4>
    </div>
</transition>
