
<article
    class="card"
    :data-load-status="loadStatus"
    :data-has-extras="hasExtras"
>
    <div class="card__body">
        <activity-indicator :is-active="isUnread" />

        <slot
            v-if="!!$slots.intro"
            name="intro"
        />

        <template v-if="previewLink">
            <router-link
                v-if="!!$slots.body"
                tag="div"
                :to="previewLink"
                class="card__preview"
                data-actionable
            >
                <slot name="body" />
            </router-link>
        </template>

        <template v-else>
            <div
                v-if="!!$slots.body"
                class="card__preview"
            >
                <slot name="body" />
            </div>
        </template>

        <div
            v-if="hasExtras"
            class="card__extra"
        >
            <div
                v-if="!!$slots.actions"
                class="card__actions"
            >
                <slot name="actions" />
            </div>
        </div>
    </div>

    <div
        v-if="!!$slots.meta || !!$slots.meta2 || !!$slots.status"
        class="card__meta"
    >
        <div
            v-if="!!$slots.meta"
            class="card__meta__left"
        >
            <slot name="meta" />
        </div>

        <div
            v-if="!!$slots.meta2"
            class="card__meta__right"
        >
            <slot name="meta2" />
        </div>

        <div
            v-if="!!$slots.status"
            class="card__status"
        >
            <slot name="status" />
        </div>
    </div>

    <transition name="fade-absolute">
        <div
            v-if="showLoadStatus"
            class="card__load-status"
        >
            <transition
                name="fade-absolute"
                mode="out-in"
            >
                <svg-icon
                    v-if="loadStatus === 'fail'"
                    key="fail"
                    icon="close"
                />

                <svg-icon
                    v-if="loadStatus === 'success'"
                    key="success"
                    icon="check"
                />

                <spinner
                    v-if="loadStatus === 'loading'"
                    key="loading"
                    class="spinner--small"
                />
            </transition>
        </div>
    </transition>
</article>
