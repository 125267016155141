
<div
    v-if="highlight"
    class="highlight"
    :data-editable="editable"
>
    <slot name="label">
        <div
            v-if="sortable"
            class="highlight__sort"
        >
            <icon icon="draggable" />
        </div>
    </slot>

    <slot name="title">
        <div class="highlight__title">
            {{ highlight.title }} <template v-if="highlight.organisation">({{ highlight.organisation }})</template>
        </div>
    </slot>

    <button
        v-if="editable"
        type="button"
        class="highlight__action btn btn--clean"
        @click="clickEditHighlight"
    >
        <svg-icon icon="edit" />
    </button>
</div>
