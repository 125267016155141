import { executeGqlMultipartQuery, executeGqlQuery, stringifyGqlQuery } from '@digitalnatives/graphql-client';
import axiosClient from '~/graphql/axiosClient.js';

export default {
    install(Vue, options) {
        // Vue.prototype.$gql = {};
        Vue.prototype.$executeGqlQuery = async function(...args) {
            return await executeQueryWrapper.call(this, options, args);
        };
        Vue.prototype.$executeGqlMultipartQuery = async function(...args) {
            return await executeGqlMultipartQuery.call({ $axios: axiosClient, ...this }, ...args);
        };
        Vue.prototype.$stringifyGqlQuery = function(...args) {
            return stringifyGqlQuery.call({ $axios: axiosClient, ...this }, ...args);
        };
    },
};

async function executeQueryWrapper(options, args) {
    if (!args || !args.length) {
        throw new Error('Cannot call $executeGqlQuery without any arguments');
    }

    // If some callbacks were defined in the config, set them here as defaults.
    const newArgs = [...args];

    if (options && options.callbacks) {
        if (!newArgs[3]) {
            newArgs[3] = {};
        }

        if (!newArgs[3].error && options.callbacks.error) {
            newArgs[3].error = options.callbacks.error;
        }

        if (!newArgs[3].variables && options.callbacks.variables) {
            newArgs[3].variables = options.callbacks.variables;
        }

        if (!newArgs[3].headers && options.callbacks.headers) {
            newArgs[3].headers = options.callbacks.headers;
        }

        if (!newArgs[3].request && options.callbacks.request) {
            newArgs[3].request = options.callbacks.request;
        }

        if (!newArgs[3].results && options.callbacks.results) {
            newArgs[3].results = options.callbacks.results;
        }
    }

    return await executeGqlQuery.call({ $axios: axiosClient, ...this }, ...newArgs);
}
