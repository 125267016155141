'use strict';

export default {
    header: {
        collapsed: true
    },

    sidebarOpen: false,

    returnLink: false,

    icons: {},
    requestedIcons: [],

    haircutTooltipClosed: [],
    haircutTooltipDoNotShow: [],

    hiddenProposals: [],
    showWithdrawnProposals: false,

    commentBoxSelected: false,
    commentBoxPrefill: null,

    preventModal: false,

    cacheKey: Math.floor(Date.now() / 1000)
};
