<template>
    <dn-modal
        name="highlight-form"
        position="centered"
        size="medium"
    >
        <modal-content>
            <section class="text-center">
                <h2 v-if="action === 'add'">{{ $t('modal.highlight-edit.title-add') }}</h2>
                <h2 v-else>{{ $t('modal.highlight-edit.title-edit') }}</h2>
            </section>

            <section>
                <form class="form">
                    <form-field :error-messages="errors.collect('title')">
                        <label
                            slot="label"
                            for="title"
                        >
                            {{ $t('modal.highlight-edit.title.label') }}
                        </label>

                        <input
                            id="title"
                            v-model="highlight.title"
                            v-validate="'required'"
                            type="text"
                            name="title"
                            :placeholder="$t('modal.highlight-edit.title.placeholder')"
                        >
                    </form-field>

                    <form-field :error-messages="errors.collect('description')">
                        <label
                            slot="label"
                            for="description"
                        >
                            {{ $t('modal.highlight-edit.description.label') }}
                        </label>

                        <textarea
                            id="description"
                            v-model="highlight.description"
                            v-validate="'required'"
                            rows="10"
                            name="description"
                            :placeholder="$t('modal.highlight-edit.description.placeholder')"
                        />
                    </form-field>

                    <form-field>
                        <label
                            slot="label"
                            for="image"
                        >
                            {{ $t('modal.highlight-edit.image.label') }}
                        </label>
                        <file-upload
                            v-model="highlight.image"
                            name="image"
                            :thumbnail="highlight.image ? getImage() : ''"
                            @file-uploaded="uploading = false"
                            @file-uploading="uploading = true"
                        >
                            <div
                                v-if="!highlight.image || !highlight.image.length"
                                class="file-upload__placeholder"
                            >
                                <icon icon="camera" />
                            </div>

                            <div class="file-upload__label">
                                {{ $t('modal.highlight-edit.image-upload') }}
                            </div>
                            <div class="file-upload__sublabel">
                                min. 200x200px
                            </div>
                        </file-upload>
                    </form-field>
                </form>
            </section>

            <section class="text-right">
                <button
                    v-if="action === 'edit'"
                    class="btn btn--link-error btn--medium"
                    style="margin-right: 1rem;"
                    @click="deleteHighlight"
                >
                    {{ $t('modal.highlight-edit.delete') }}
                </button>

                <button
                    class="btn btn--secondary btn--medium"
                    :disabled="uploading"
                    @click="saveHighlight"
                >
                    {{ $t('modal.highlight-edit.submit') }}
                </button>
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { clone } from 'lodash-es';

import { mapActions, mapGetters } from 'vuex';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';
import FileUpload from '~/patterns/atoms/file-upload/file-upload.vue';
import Icon from '~/patterns/atoms/icon/icon.vue';

const defaultHighlight = {
    title: '',
    description: '',
    image: '',
    order: 0
};

export default {
    data() {
        return {
            uploading: false,
            highlight: defaultHighlight
        };
    },

    computed: {
        ...mapGetters({ data: 'modal/data' }),

        action() {
            return this.data ? 'edit' : 'add';
        }
    },

    methods: {
        ...mapActions({ closeModal: 'modal/closeModal' }),

        saveHighlight() {
            this.$validator.validateAll().then((noErrors) => {
                if (!noErrors) {
                    return;
                }
                if (this.action === 'edit') {
                    this.$eventBus.$emit('update-highlight', this.highlight);
                } else {
                    this.$eventBus.$emit('add-highlight', this.highlight);
                }
                this.closeModal();
            });
        },

        deleteHighlight() {
            this.$eventBus.$emit('delete-highlight', this.highlight);
            this.closeModal();
        },

        getImage() {
            if (this.data) {
                return this.$root.getImage(`${window.appConfig.api.url}/v1.0/users/${this.data.userId}/highlights/${this.data.index}/image/highlight`);
            }
            return null;
        }
    },

    watch: {
        data() {
            if (this.data) {
                this.highlight = clone(this.data);
            } else {
                this.highlight = clone(defaultHighlight);
            }
        }
    },

    components: {
        DnModal,
        ModalContent,
        FormField,
        FileUpload,
        Icon
    }
};
</script>
