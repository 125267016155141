
<ul
    class="notification-list"
    :data-notification-count="notifications.length"
>
    <li
        v-for="(notification, index) in notifications"
        :key="index"
        class="notification-list__item"
    >
        <router-link
            :to="{
                name: 'notification.detail'
            }"
            class="notification-list__notification"
        >
            <div
                class="notification-list__notification__image"
                :style="{
                    backgroundImage: `url('${notification.image}')`
                }"
            />

            <div class="notification-list__notification__body">
                <h2>{{ notification.title }}</h2>
                <p>{{ $t('notificationList.new-reply') }} - <time>{{ notification.time }}</time></p>
            </div>
        </router-link>
    </li>
    <li class="notification-list__item">
        <router-link
            :to="{ name: 'notifications.index' }"
            class="notification-list__read-all"
        >
            {{ $t('notificationList.all-notifications') }}
        </router-link>
    </li>
</ul>
