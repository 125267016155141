
<div class="toggle-switch">
    <slot name="label-before" />
    <div
        class="toggle-switch__control"
        :class="{
            'toggle-switch--label-before': !!$slots['label-before'],
            'toggle-switch--label-after': !!$slots['label-after']
        }"
    >
        <input
            v-bind="$attrs"
            :id="id"
            type="checkbox"
            @input="$emit('input', $event.target.checked)"
        >
        <label
            class="toggle-switch__label"
            :for="id"
        />
    </div>
    <slot name="label-after" />
</div>
