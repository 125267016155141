'use strict';

import { clone, findIndex } from 'lodash-es';

export default {
    proposals(state, proposals = []) {
        if (!proposals) {
            return false;
        }

        state.proposals = proposals;
    },

    proposal(state, proposal) {
        if (!proposal) {
            return false;
        }

        const proposals = clone(state.proposals),
            proposalIndex = findIndex(proposals, { id: proposal.id });

        if (proposalIndex !== -1) {
            proposals[proposalIndex] = proposal;
        } else {
            proposals.push(proposal);
        }

        state.proposals = proposals;
    }
};
