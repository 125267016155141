
<dn-modal
    name="assignment-add-follower"
    position="centered"
    size="medium"
>
    <modal-content v-if="assignment">
        <section class="text-center">
            <h2>{{ $t('modal.assignment-add-follower.modal-title') }}</h2>
        </section>

        <section>
            <load-status loader-name="users">
                <form class="form">
                    <form-field>
                        <p>{{ $t('modal.assignment-add-follower.form-intro') }}</p>
                    </form-field>

                    <form-field class="column-grid">
                        <form-field
                            :error-messages="errors.collect('user_id')"
                            columns-sm-min="4/8"
                        >
                            <label
                                slot="label"
                                for="user_id"
                            >
                                {{ $t('modal.assignment-add-follower.user_id.label') }}
                            </label>

                            <multiselect
                                v-model="formData.userId"
                                :options="selectableClientIds"
                                :placeholder="$t('modal.assignment-add-follower.user_id.placeholder')"
                                :searchable="false"
                                :show-labels="false"
                                :multiple="false"
                                :custom-label="clientLabel"
                                :allow-empty="false"
                            />
                        </form-field>
                    </form-field>
                </form>
            </load-status>
        </section>

        <section class="text-right">
            <v-button
                class="btn btn--secondary btn--medium"
                :state="requestState"
                :disabled="!formData.userId"
                @click="addFollower"
            >
                {{ $t('modal.assignment-add-follower.submit') }}
            </v-button>
        </section>
    </modal-content>
</dn-modal>
