import Store from '~/store/store';

export function getLoggedInUser() {
    const loggedInUser = Store.getters['auth/user'];

    if (!loggedInUser) {
        return false;
    }

    return loggedInUser;
}

export function isMe(userId) {
    const loggedInUser = getLoggedInUser();

    if (!loggedInUser || !userId) {
        return false;
    }

    return userId === loggedInUser.id;
}

export function hasRole(role, user = null) {
    if (user === null) {
        user = getLoggedInUser();
    }
    if (!user || !Array.isArray(user.roles)) {
        return false;
    }

    return user.roles.includes(role) || user.roles.some(item => item.name === role);
}

export function isClient(user = null) {
    return hasRole('client', user);
}

export function isAdmin(user = null) {
    return hasRole('admin', user);
}

export function isGuest(user = null) {
    return hasRole('guest', user);
}

export function isLawyer(user = null) {
    return hasRole('lawyer', user);
}

export function isSupplier(user = null) {
    return hasRole('supplier', user);
}

export function isCommunityManager(user = null) {
    return hasRole('community_manager', user);
}
