import Vue from 'vue';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import Cookie from 'js-cookie';
import axios from '~/api/api.js';
import Store from '~/store/store';
import graphqlAxiosClient from '~/graphql/axiosClient';
import Router from '~/router/router';

let instance;

/** Returns the current instance of the SDK */
export const getInstance = () => instance;

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth0 = ({
    ...auth0Config
}) => {
    if (instance) { return instance; }

    // The 'instance' is simply a Vue object
    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                isGuestUser: false,
                accessToken: null,
                user: {},
                auth0Client: null,
                popupOpen: false,
                error: null
            };
        },

        /** Use this lifecycle method to instantiate the SDK client */
        async created() {
            if (Cookie.get('guestAuthToken')) {
                this.isAuthenticated = true;
                this.isGuest = true;
                this.accessToken = Cookie.get('guestAuthToken');

                Store.commit('auth/user', undefined);

                await this.updateApiHeaders(this.accessToken);

                await Store.dispatch('auth/fetchUser');
                Store.commit('auth/hasAccessToData', true);
                this.loading = false;
                return;
            }
            // Create a new instance of the SDK client using members of the given options object
            this.auth0Client = await createAuth0Client({
                useRefreshTokens: true,
                cacheLocation: 'memory', // valid values are: 'memory' or 'localstorage'
                useFormData: false,
                ...auth0Config,
            });

            try {
                if (window.location.search.includes('error')) {
                    const urlParams = new URLSearchParams(window.location.search);
                    throw new Error(urlParams.get('error'));
                }
                // If the user is returning to the app after authentication..
                if (
                    window.location.search.includes('code=') &&
                    window.location.search.includes('state=')
                ) {
                    // handle the redirect and retrieve tokens
                    const { appState } = await this.auth0Client.handleRedirectCallback();
                    this.error = null;

                    // Notify subscribers that the redirect callback has happened, passing the appState
                    // (useful for retrieving any pre-authentication state)
                    Router.push(
                        appState && appState.targetUrl
                            ? appState.targetUrl
                            : window.location.pathname
                    );
                }
            } catch (e) {
                this.error = e;
            } finally {
                // Initialize our internal authentication state
                await this.setAuthenticated();
                this.loading = false;
            }
        },

        methods: {
            /** Authenticates the user using a popup window */
            async loginWithPopup(options, config) {
                this.popupOpen = true;

                try {
                    await this.auth0Client.loginWithPopup(options, config);
                    await this.setAuthenticated();
                    this.error = null;
                } catch (e) {
                    this.error = e;
                    // eslint-disable-next-line
                    await this.setAuthenticated(false);
                } finally {
                    this.popupOpen = false;
                }
            },

            /** Handles the callback when logging in using a redirect */
            async handleRedirectCallback() {
                this.loading = true;
                try {
                    await this.auth0Client.handleRedirectCallback();
                    await this.setAuthenticated(true);
                    this.error = null;
                } catch (e) {
                    this.error = e;
                    await this.setAuthenticated(false);
                } finally {
                    this.loading = false;
                }
            },

            /** Authenticates the user using the redirect method */
            loginWithRedirect(o) {
                this.error = null;
                return this.auth0Client.loginWithRedirect(o);
            },

            /** Returns all the claims present in the ID token */
            getIdTokenClaims(o) {
                this.error = null;
                return this.auth0Client.getIdTokenClaims(o);
            },

            /** Returns the access token. If the token is invalid or missing, a new one is retrieved */
            async getTokenSilently(o) {
                this.error = null;
                if (Cookie.get('guestAuthToken')) {
                    this.accessToken = Cookie.get('guestAuthToken');
                } else {
                    this.accessToken = await this.auth0Client.getTokenSilently(o);
                }

                return this.accessToken;
            },

            /** Gets the access token using a popup window */
            async getTokenWithPopup(o) {
                this.error = null;
                this.accessToken = await this.auth0Client.getTokenWithPopup(o);
                return this.accessToken;
            },

            /** Logs the user out and removes their session on the authorization server */
            async logout(o) {
                this.error = null;
                await this.setAuthenticated(false);
                return this.auth0Client.logout({
                    logoutParams: {
                        returnTo: `${window.location.origin}/login`,
                        ...o
                    }
                });
            },

            /** Set internal and store states upon authentication changes */
            async setAuthenticated(state) {
                if (state === undefined) {
                    this.isAuthenticated = await this.auth0Client.isAuthenticated();
                } else {
                    this.isAuthenticated = state;
                }

                if (this.isAuthenticated === true) {
                    this.user = await this.auth0Client.getUser();
                    await this.updateApiHeaders();
                    await Store.dispatch('auth/fetchUser');
                } else {
                    Store.commit('auth/user', undefined);
                    Store.commit('auth/hasAccessToData', undefined);
                    await this.updateApiHeaders();
                }
            },

            /* The user credentials are valid, but the user doesn't have access to the requested page. */
            accessDenied() {
                Store.commit('auth/hasAccessToData', false);
            },

            /* Set API headers based on auth0 tokens */
            async updateApiHeaders(token) {
                let authToken = null;
                if (this.isAuthenticated && !token) {
                    try {
                        authToken = await this.getTokenSilently();
                    } catch (e) {
                        console.error(e);
                    }
                } else if (this.isAuthenticated) {
                    authToken = token;
                }

                if (authToken) {
                    axios.defaults.headers.common.Authorization = `Bearer ${authToken}`;
                    graphqlAxiosClient.defaults.headers.common.Authorization = `Bearer ${authToken}`;
                } else {
                    axios.defaults.headers.common.Authorization = undefined;
                    graphqlAxiosClient.defaults.headers.common.Authorization = undefined;
                }
            },

            /* Route guard for authentication-required routes */
            guard(route) {
                const noAuthRequired = route.meta && route.meta.noAuthRequired ? route.meta.noAuthRequired : false;

                this.guardPromise = new Promise((resolve, reject) => {
                    if (noAuthRequired) {
                        return resolve();
                    }

                    const isAuthenticated = this.isAuthenticated,
                        hasAccessToData = Store.getters['auth/hasAccessToData'];

                    if (!isAuthenticated) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return reject(401);
                    } else if (!hasAccessToData) {
                        // eslint-disable-next-line prefer-promise-reject-errors
                        return reject(403);
                    } else {
                        return resolve();
                    }
                });

                return this.guardPromise;
            }

        }
    });

    return instance;
};

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const Auth0Plugin = {
    install(VueInstance, options) {
        VueInstance.prototype.$auth = VueInstance.$auth = useAuth0(options);
    }
};
