<template>
    <aside-view
        v-if="loggedInUser"
        class="aside-view--compact aside-view--hide-sidebar-mobile"
        :sticky="true"
    >
        <template v-slot:sidebar>
            <table-of-contents :links="tocLinks" />
        </template>

        <template v-slot:body>
            <!--
        Profile
        -->
            <panel
                id="profile"
                :title="$t('profileView.title.profile')"
            >
                <form
                    class="form"
                    data-vv-scope="profile"
                >
                    <form-field>
                        <file-upload
                            :id="'profile-edit__avatar'"
                            v-model="formData.profile.avatar"
                            name="avatar"
                            class="file-upload--avatar"
                            :thumbnail="formData.profile.avatar ? getImage(formData.profile.avatar) : ''"
                        />
                    </form-field>

                    <form-field
                        class="form-field--horizontal"
                        :error-messages="errors.collect('profile.first_name')"
                    >
                        <label
                            slot="label"
                            for="first_name"
                        >
                            {{ $t('profileView.first_name.label') }}
                        </label>

                        <input
                            id="first_name"
                            v-model="formData.profile.first_name"
                            v-validate="'required'"
                            type="text"
                            name="first_name"
                            :placeholder="$t('profileView.first_name.placeholder')"
                        >
                    </form-field>

                    <form-field
                        class="form-field--horizontal"
                        :error-messages="errors.collect('profile.last_name')"
                    >
                        <label
                            slot="label"
                            for="last_name"
                        >
                            {{ $t('profileView.last_name.label') }}
                        </label>

                        <input
                            id="last_name"
                            v-model="formData.profile.last_name"
                            v-validate="'required'"
                            type="text"
                            name="last_name"
                            :placeholder="$t('profileView.last_name.placeholder')"
                        >
                    </form-field>

                    <form-field
                        class="form-field--horizontal"
                        :error-messages="errors.collect('profile.city')"
                    >
                        <label
                            slot="label"
                            for="city"
                        >
                            {{ $t('profileView.city.label') }}
                        </label>

                        <input
                            id="city"
                            v-model="formData.profile.city"
                            type="text"
                            name="city"
                            :placeholder="$t('profileView.city.placeholder')"
                        >
                    </form-field>

                    <form-field class="form-field--last text-right">
                        <v-button
                            class="btn--secondary"
                            :state="requestState.profile"
                            @click="submit('profile')"
                        >
                            {{ $t('profileView.save') }}
                        </v-button>
                    </form-field>
                </form>
            </panel>

            <!--
        Domain
        -->
            <panel
                id="discipline"
                :title="$t('profileView.title.discipline')"
            >
                <form
                    class="form"
                    data-vv-scope="discipline"
                >
                    <form-field
                        class="form-field--horizontal"
                        :error-messages="errors.collect('discipline.lawyer_title')"
                    >
                        <label
                            slot="label"
                            for="lawyer_title"
                        >
                            {{ $t('profileView.lawyer_title.label') }}
                        </label>

                        <input
                            id="lawyer_title"
                            v-model="formData.discipline.lawyer_title"
                            type="text"
                            name="lawyer_title"
                            :placeholder="$t('profileView.lawyer_title.placeholder')"
                        >
                    </form-field>

                    <form-field
                        class="form-field--horizontal form-field--label-centered"
                        :error-messages="errors.collect('discipline.assignment_types')"
                    >
                        <label
                            slot="label"
                            for="assignment_types"
                        >
                            {{ $t('profileView.assignment_types.label') }}
                        </label>

                        <checkbox
                            v-model="formData.discipline.assignment_types"
                            class="checkbox--inline"
                            name="assignment_types"
                            value="interim"
                        >
                            {{ $t('profileView.assignment_types.interim') }}
                        </checkbox>

                        <checkbox
                            v-model="formData.discipline.assignment_types"
                            class="checkbox--inline"
                            name="assignment_types"
                            value="advice"
                        >
                            {{ $t('profileView.assignment_types.advice') }}
                        </checkbox>

                        <checkbox
                            v-model="formData.discipline.assignment_types"
                            class="checkbox--inline"
                            name="assignment_types"
                            value="litigation"
                        >
                            {{ $t('profileView.assignment_types.litigation') }}
                        </checkbox>
                    </form-field>

                    <form-field
                        class="form-field--horizontal form-field--label-centered"
                        :error-messages="errors.collect('discipline.lawyer_types')"
                    >
                        <label
                            slot="label"
                            for="lawyer_types"
                        >
                            {{ $t('profileView.lawyer_types.label') }}
                        </label>

                        <multiselect
                            v-model="formData.discipline.lawyer_types"
                            :options="lawyerTypeOptions"
                            :placeholder="$t('profileView.lawyer_types.placeholder')"
                            :searchable="false"
                            :show-labels="false"
                            :multiple="true"
                            :custom-label="lawyerTypeLabel"
                        />
                    </form-field>

                    <form-field
                        class="form-field--horizontal form-field--label-centered"
                        :error-messages="errors.collect('discipline.fields_of_law')"
                    >
                        <label
                            slot="label"
                            for="fields_of_law"
                        >
                            {{ $t('profileView.fields_of_law.label') }}
                        </label>

                        <multiselect
                            v-model="formData.discipline.fields_of_law"
                            :options="fieldOfLawOptions"
                            :placeholder="$t('profileView.fields_of_law.placeholder')"
                            :searchable="true"
                            :show-labels="false"
                            :multiple="true"
                            :custom-label="fieldOfLawLabel"
                        />
                    </form-field>

                    <form-field
                        class="form-field--horizontal form-field--label-centered"
                        :error-messages="errors.collect('discipline.specialisations')"
                    >
                        <label
                            slot="label"
                            for="specialisations"
                        >
                            {{ $t('profileView.specialisations.label') }}
                        </label>

                        <multiselect
                            v-model="formData.discipline.specialisations"
                            :tag-placeholder="$t('profileView.specialisations.tag-placeholder')"
                            :placeholder="$t('profileView.specialisations.placeholder')"
                            tag-position="bottom"
                            :options="specialisationOptions"
                            :multiple="true"
                            :taggable="true"
                            :show-no-options="false"
                            @tag="addSpecialisationTag"
                        />
                    </form-field>

                    <form-field
                        class="form-field--horizontal form-field--label-centered"
                        :error-messages="errors.collect('discipline.domain_expertises')"
                    >
                        <label
                            slot="label"
                            for="domain_expertises"
                        >
                            {{ $t('profileView.domain_expertises.label') }}
                        </label>

                        <multiselect
                            v-model="formData.discipline.domain_expertises"
                            :tag-placeholder="$t('profileView.domain_expertises.tag-placeholder')"
                            :placeholder="$t('profileView.domain_expertises.placeholder')"
                            tag-position="bottom"
                            :options="domainExpertiseOptions"
                            :multiple="true"
                            :taggable="true"
                            :show-no-options="false"
                            @tag="addDomainExpertiseTag"
                        />
                    </form-field>

                    <form-field
                        class="form-field--horizontal form-field--label-centered"
                        :error-messages="errors.collect('discipline.business_languages')"
                    >
                        <label
                            slot="label"
                            for="business_languages"
                        >
                            {{ $t('profileView.business_languages.label') }}
                        </label>

                        <multiselect
                            v-model="formData.discipline.business_languages"
                            :options="businessLanguageOptions"
                            :placeholder="$t('profileView.business_languages.placeholder')"
                            :searchable="true"
                            :show-labels="false"
                            :multiple="true"
                            :custom-label="businessLanguageLabel"
                        />
                    </form-field>

                    <form-field class="form-field--last text-right">
                        <v-button
                            class="btn--secondary"
                            :state="requestState.discipline"
                            @click="submit('discipline')"
                        >
                            {{ $t('profileView.save') }}
                        </v-button>
                    </form-field>
                </form>
            </panel>

            <!--
        Contact
        -->
            <panel
                id="contact"
                :title="$t('profileView.title.contact')"
            >
                <form
                    class="form"
                    data-vv-scope="contact-info"
                >
                    <form-field
                        class="form-field--horizontal"
                        :error-messages="errors.collect('contact-info.contact_email')"
                    >
                        <label
                            slot="label"
                            for="contact_email"
                        >
                            {{ $t('profileView.contact_email.label') }}
                        </label>

                        <input
                            id="contact_email"
                            v-model="formData['contact-info'].contact_email"
                            v-validate="'email'"
                            type="email"
                            name="contact_email"
                            :placeholder="$t('profileView.contact_email.placeholder')"
                        >
                    </form-field>

                    <form-field
                        class="form-field--horizontal"
                        :error-messages="errors.collect('contact-info.phone_number')"
                    >
                        <label
                            slot="label"
                            for="phone_number"
                        >
                            {{ $t('profileView.phone_number.label') }}
                        </label>

                        <input
                            id="phone_number"
                            v-model="formData['contact-info'].phone_number"
                            type="tel"
                            name="phone_number"
                            :placeholder="$t('profileView.phone_number.placeholder')"
                        >
                    </form-field>

                    <form-field
                        class="form-field--horizontal"
                        :error-messages="errors.collect('contact-info.website_url')"
                    >
                        <label
                            slot="label"
                            for="website_url"
                        >
                            {{ $t('profileView.website_url.label') }}
                        </label>

                        <input
                            id="website_url"
                            v-model="formData['contact-info'].website_url"
                            v-validate="'url:require_protocol'"
                            type="url"
                            name="website_url"
                            :placeholder="$t('profileView.website_url.placeholder')"
                        >
                    </form-field>

                    <form-field
                        class="form-field--horizontal"
                        :error-messages="errors.collect('contact-info.linkedin_url')"
                    >
                        <label
                            slot="label"
                            for="linkedin_url"
                        >
                            {{ $t('profileView.linkedin_url.label') }}
                        </label>

                        <input
                            id="linkedin_url"
                            v-model="formData['contact-info'].linkedin_url"
                            v-validate="'url:require_protocol'"
                            type="url"
                            name="linkedin_url"
                            :placeholder="$t('profileView.linkedin_url.placeholder')"
                        >
                    </form-field>

                    <form-field class="form-field--last text-right">
                        <v-button
                            class="btn--secondary"
                            :state="requestState['contact-info']"
                            @click="submit('contact-info')"
                        >
                            {{ $t('profileView.save') }}
                        </v-button>
                    </form-field>
                </form>
            </panel>

            <!--
        Bio
        -->
            <panel
                id="bio"
                :title="$t('profileView.title.bio')"
            >
                <form
                    class="form"
                    data-vv-scope="bio"
                >
                    <form-field
                        class="form-field--horizontal"
                        :error-messages="errors.collect('bio.bio')"
                    >
                        <label
                            slot="label"
                            for="bio"
                        >
                            {{ $t('profileView.bio.label') }}
                        </label>

                        <textarea
                            id="bio"
                            v-model="formData.bio.bio"
                            v-validate="'max:1000'"
                            name="bio"
                            rows="8"
                            :placeholder="$t('profileView.bio.placeholder')"
                        />
                    </form-field>

                    <form-field class="form-field--last text-right">
                        <v-button
                            class="btn--secondary"
                            :state="requestState['bio']"
                            @click="submit('bio')"
                        >
                            {{ $t('profileView.save') }}
                        </v-button>
                    </form-field>
                </form>
            </panel>

            <!--
        Highlights
         -->
            <panel
                id="highlights"
                :title="$t('profileView.title.highlights')"
            >
                <form
                    class="form"
                    data-vv-scope="highlights"
                >
                    <form-field
                        :error-messages="errors.collect('highlights.highlights')"
                    >
                        <highlight-field
                            v-model="formData.highlights.highlights"
                            :sortable="false"
                        />
                    </form-field>

                    <form-field class="form-field--last text-right">
                        <v-button
                            class="btn--secondary"
                            :state="requestState['highlights']"
                            @click="submit('highlights')"
                        >
                            {{ $t('profileView.save') }}
                        </v-button>
                    </form-field>
                </form>
            </panel>
            <highlight-form-modal />

            <!--
        Timeline
        -->
            <panel
                id="timeline"
                :title="$t('profileView.title.timeline')"
            >
                <form
                    class="form"
                    data-vv-scope="timeline"
                >
                    <form-field
                        :error-messages="errors.collect('timeline.timeline')"
                    >
                        <timeline-field
                            v-model="formData.timeline.timeline"
                            :sortable="false"
                        />
                    </form-field>

                    <form-field class="form-field--last text-right">
                        <v-button
                            class="btn--secondary"
                            :state="requestState['timeline']"
                            @click="submit('timeline')"
                        >
                            {{ $t('profileView.save') }}
                        </v-button>
                    </form-field>
                </form>
            </panel>

            <!--
        Account
        -->
            <panel
                id="account"
                :title="$t('profileView.title.account')"
            >
                <form-field>
                    <router-link
                        class="btn btn--link-secondary btn--small"
                        :to="{ name: 'logout' }"
                    >
                        {{ $t('navDropdown.links.logout') }}
                    </router-link>
                </form-field>

                <form-field>
                    <a
                        class="btn btn--link-secondary btn--small"
                        :href="`mailto:moderator@lawyerlinq.com?subject=Please remove my profile&body=Please remove my profile with the e-mail address: ${loggedInUser.email}`"
                    >
                        {{ $t('profileView.delete_profile') }}
                    </a>
                </form-field>
            </panel>
        </template>
    </aside-view>
</template>

<script>
'use strict';

import Multiselect from 'vue-multiselect';
import { mapGetters } from 'vuex';

import UserApi from '~/api/users';

import { formValidationHandler } from '~/helpers/formValidationHandler';

import FormField from '~/patterns/molecules/form-field/form-field.vue';
import Panel from '~/patterns/organisms/panel/panel.vue';
import TableOfContents from '~/patterns/molecules/table-of-contents/table-of-contents.vue';
import FileUpload from '~/patterns/atoms/file-upload/file-upload.vue';
import VButton from '~/patterns/atoms/button/button.vue';
import Checkbox from '~/patterns/atoms/checkbox/checkbox.vue';
import TimelineField from '~/patterns/molecules/timeline-field/timeline-field.vue';
import HighlightField from '~/patterns/molecules/highlight-field/highlight-field.vue';
import HighlightFormModal from '~/patterns/organisms/modal/presets/highlight-form-modal.vue';

export default {
    components: {
        FormField,
        TableOfContents,
        Panel,
        VButton,
        FileUpload,
        Checkbox,
        Multiselect,
        TimelineField,
        HighlightField,
        HighlightFormModal
    },

    beforeRouteLeave(to, from, next) {
        if (to.name === from.name || !this.dataHasChanged) {
            return next();
        }

        if (this.confirmLeaveWithoutSave()) {
            return next();
        }
    },

    data() {
        return {
            preventAutoSave: true,
            dataHasChanged: false,

            /* eslint-disable */
            formData: {
                profile: {
                    first_name: '',
                    last_name: '',
                    city: '',
                    avatar: ''
                },
                'contact-info': {
                    contact_email: '',
                    phone_number: '',
                    website_url: '',
                    linkedin_url: ''
                },
                discipline: {
                    lawyer_title: '',
                    assignment_types: [],
                    lawyer_types: '',
                    fields_of_law: [],
                    specialisations: [],
                    domain_expertises: [],
                    business_languages: []
                },
                bio: {
                    bio: undefined
                },
                highlights: {
                    highlights: []
                },
                timeline: {
                    timeline: []
                }
            },
            /* eslint-enable */

            requestState: {
                profile: null,
                'contact-info': null,
                discipline: null,
                bio: null,
                timeline: null,
                highlights: null
            },

            tocLinks: [
                {
                    target: '#profile',
                    label: this.$i18n.t('profileView.title.profile')
                },
                {
                    target: '#discipline',
                    label: this.$i18n.t('profileView.title.discipline')
                },
                {
                    target: '#contact',
                    label: this.$i18n.t('profileView.title.contact')
                },
                {
                    target: '#bio',
                    label: this.$i18n.t('profileView.title.bio')
                },
                {
                    target: '#highlights',
                    label: this.$i18n.t('profileView.title.highlights')
                },
                {
                    target: '#timeline',
                    label: this.$i18n.t('profileView.title.timeline')
                }
            ]
        };
    },

    computed: {
        ...mapGetters({
            lawyerTypeOptions: 'enums/getLawyerTypes',
            fieldOfLawOptions: 'enums/getFieldsOfLaw',
            businessLanguageOptions: 'enums/getBusinessLanguageOptions'
        }),

        loggedInUser() {
            return this.$store.getters['auth/user'];
        },

        // @TODO get available tags as autocomplete
        specialisationOptions() {
            return [];
        },

        // @TODO get available tags as autocomplete
        domainExpertiseOptions() {
            return [];
        }
    },

    watch: {
        loggedInUser() {
            this.prepareData();
        },

        formData: {
            deep: true,
            handler() {
                if (this.preventAutoSave) {
                    return false;
                }

                this.dataHasChanged = true;
            }
        },

        'formData.highlights.highlights'() {
            if (this.preventAutoSave) {
                return false;
            }

            this.submit('highlights');
        },

        'formData.timeline.timeline'() {
            if (this.preventAutoSave) {
                return false;
            }

            this.submit('timeline');
        },

        'formData.profile.avatar'(value) {
            console.log(value);
            if (this.preventAutoSave) {
                return false;
            }

            this.submit('profile');
        }
    },
    created() {
        this.prepareData();

        // Wait a moment before allow some fields to auto-save
        setTimeout(() => {
            this.preventAutoSave = false;
        }, 2000);
    },

    methods: {
        submit(scope) {
            this.$validator.validateAll(scope).then((noErrors) => {
                if (!noErrors) {
                    return;
                }

                this.save(scope);
            });
        },

        save(scope) {
            if (!scope) {
                return false;
            }

            this.requestState[scope] = 'loading';

            // @TODO refactor to GQL mutations, scope isn't needed in that case any more (just send dirty fields)
            UserApi.updateProfile(this.loggedInUser.id, scope, this.formData[scope]).then((response) => {
                this.$store.commit('auth/user', response.data);
                this.$store.dispatch('ui/refreshCacheKey');
                this.requestState[scope] = 'success';
                this.dataHasChanged = false;
            }).catch((err) => {
                console.error(err);
                this.requestState[scope] = 'fail';
                if (err.response) {
                    formValidationHandler.bind(this)(err.response);
                }
            }).then(() => {
                setTimeout(() => {
                    this.requestState[scope] = undefined;
                }, 1500);
            });
        },

        lawyerTypeLabel(option) {
            return this.$i18n.t(`lawyerTypes.${option}`);
        },

        fieldOfLawLabel(option) {
            return this.$i18n.t(`fieldsOfLaw.${option}`);
        },

        businessLanguageLabel(option) {
            return this.$i18n.t(`languages.${option}`);
        },

        addSpecialisationTag(tag) {
            // this.options.push(tag)
            this.formData.discipline.specialisations.push(tag);
        },

        addDomainExpertiseTag(tag) {
            // this.options.push(tag)
            this.formData.discipline.domain_expertises.push(tag);
        },

        confirmLeaveWithoutSave() {
            return confirm(this.$i18n.t('profileView.confirm-leave-without-saving'));
        },

        /* eslint-disable */
        prepareData() {
            if (this.dataPrepared || !this.loggedInUser) {
                return false;
            }

            // Profile data
            this.formData.profile.first_name = this.loggedInUser.first_name;
            this.formData.profile.last_name = this.loggedInUser.last_name;
            this.formData.profile.city = this.loggedInUser.city;

            // Avatar
            this.formData.profile.avatar = this.loggedInUser.avatar;

            // Discipline
            this.formData.discipline.lawyer_title = this.loggedInUser.lawyer_title;
            this.formData.discipline.assignment_types = this.loggedInUser.assignment_types || [];
            this.formData.discipline.lawyer_types = this.loggedInUser.lawyer_types || [];
            this.formData.discipline.fields_of_law = this.loggedInUser.fields_of_law || [];
            this.formData.discipline.specialisations = this.loggedInUser.specialisations.map(o => o['name']) || [];
            this.formData.discipline.domain_expertises = this.loggedInUser.domain_expertises.map(o => o['name']) || [];
            this.formData.discipline.business_languages = this.loggedInUser.business_languages || [];

            // Contact data
            this.formData['contact-info'].contact_email = this.loggedInUser.contact_email || this.loggedInUser.email;
            this.formData['contact-info'].phone_number = this.loggedInUser.phone_number;
            this.formData['contact-info'].website_url = this.loggedInUser.website_url;
            this.formData['contact-info'].linkedin_url = this.loggedInUser.linkedin_url;

            // Bio
            this.formData.bio.bio = this.loggedInUser.bio;

            // Timeline
            this.formData.highlights.highlights = this.loggedInUser.highlights;

            // Timeline
            this.formData.timeline.timeline = this.loggedInUser.timeline;

            // Make sure the data gets prepared only once.
            this.dataPrepared = true;
        }
        /* eslint-enable */
    }
};
</script>
