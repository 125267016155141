
<dn-modal
    v-if="assignment"
    name="assignment-response"
    position="centered"
    size="medium"
    :close-prompt="$t('modal.assignment-response.close-prompt')"
    class="assignment-details-modal"
>
    <modal-content>
        <template #default>
            <h1 class="assignment-details-modal__title">
                {{ assignment.title }}
            </h1>

            <!--eslint-disable vue/no-v-html-->
            <p
                class="lead assignment-details-modal__text"
                v-html="assignment.content"
            />
            <!--eslint-enable vue/no-v-html-->

            <assignment-meta :assignment="assignment" />
        </template>
    </modal-content>

    <template #footer>
        <llq-button
            v-if="!assignment.me?.[0]?.pivot?.is_rejected"
            class="btn--secondary"
            @click="handleClick('proposal')"
        >
            {{ $t('modal.assignment-response.send-fee-quote') }}
        </llq-button>

        <llq-button @click="handleClick('interest')">
            {{ $t('modal.assignment-response.interest') }}
        </llq-button>

        <llq-button @click="handleClick('question')">
            {{ $t('modal.assignment-response.ask-question') }}
        </llq-button>
    </template>
</dn-modal>
