
<div class="user-profile">
    <loading-indicator v-if="loading" />

    <template v-if="!loading">
        <llq-section class="user-profile__section">
            <user-profile-header :user="user" />
        </llq-section>

        <llq-section
            v-if="hasContactFieldsSet"
            class="user-profile__section"
        >
            <h3>{{ $t('userProfile.contact.heading') }}</h3>
            <labelled-list :items="contactItems">
                <template #value="{ item }">
                    <a
                        v-if="item.type === 'url'"
                        :href="item.value"
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {{ item.value }}
                    </a>
                    <a
                        v-else-if="item.type === 'mailto'"
                        :href="`mailto:${item.value}`"
                    >
                        {{ item.value }}
                    </a>
                    <a
                        v-else-if="item.type === 'tel'"
                        :href="`tel:${item.value}`"
                    >
                        {{ item.value }}
                    </a>
                    <span v-else>{{ item.value }}</span>
                </template>
            </labelled-list>
        </llq-section>

        <llq-section
            v-if="discipline && discipline.length"
            class="user-profile__section"
        >
            <h3>{{ $t('profileView.title.discipline') }}</h3>
            <labelled-list :items="discipline" />
        </llq-section>

        <llq-section
            v-if="user.bio"
            class="user-profile__section"
        >
            <h3>{{ $t('userProfile.bio.heading') }}</h3>
            <p>{{ user.bio }}</p>
        </llq-section>

        <llq-section
            v-if="user.highlights && user.highlights.length"
            class="user-profile__section"
        >
            <h3>{{ $t('userProfile.highlights.heading') }}</h3>
            <user-highlight
                v-for="(highlight, index) in user.highlights"
                :key="index"
                :highlight="highlight"
                :user-id="user.id"
                :index="index"
            />
        </llq-section>

        <llq-section
            v-if="user.timeline"
            class="user-profile__section"
        >
            <h3>{{ $t('userProfile.timeline.heading') }}</h3>
            <labelled-list :items="user.timeline">
                <template #label="{ item }">
                    {{ item.start }}<template v-if="item.end"> - {{ item.end }}</template>
                </template>
                <template #value="{ item }">{{ item.title }}, {{ item.organisation }}</template>
            </labelled-list>
        </llq-section>
    </template>
</div>
