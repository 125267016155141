'use strict';

import { UPDATED_COMMUNITIES, NEW_ASSIGNMENTS, UPDATED_ASSIGNMENTS, UPDATED_THREADS } from '~/constants/activities';

import ActivityApi from '~/api/activity';
import Loader from '~/services/loader/loading-service';

export default {
    fetchActivity(context) {
        Loader.get('activity').start();

        ActivityApi.getActivity().then((response) => {
            if (!response.data || !response.data.data) {
                return false;
            }

            context.dispatch('storeActivities', response.data.data);

            Loader.get('activity').success();
        }).catch((err) => {
            // TODO: Connect to toast by passing the error
            Loader.get('activity').fail(err);
        });
    },

    storeActivities(context, activities) {
        // Updated communities
        context.commit(UPDATED_COMMUNITIES, activities.updated_communities);

        // Updated assignments
        context.commit(UPDATED_ASSIGNMENTS, activities.updated_assignments);

        // Updated threads
        context.commit(UPDATED_THREADS, activities.updated_threads);

        // New assignments
        context.commit(NEW_ASSIGNMENTS, activities.new_assignments);
    },

    putActivity(context, modelId) {
        Loader.get('activity.put').start();

        ActivityApi.putActivity(modelId).then((response) => {
            if (!response.data || !response.data.data) {
                return false;
            }

            context.dispatch('storeActivities', response.data.data);

            Loader.get('activity.put').success();
        }).catch((err) => {
            // TODO: Connect to toast by passing the error
            Loader.get('activity.put').fail(err);
        });
    }

};
