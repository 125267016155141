import Vue from 'vue';

export default {
    all() {
        return Vue.axios.get('/v1.0/assignments', {
            params: {
                limit: 100,
                include: [
                    'user',
                    'threads_count',
                    'proposals_count',
                    'lawyers_count',
                    'responses_count',
                    'assignment_threads_with_reply_count',
                    'accepted_proposals',
                    'accepted_proposals.user',
                ],
                sort: 'closed,-created'
            }
        });
    },

    create(data = {}) {
        return Vue.axios.post('/v1.0/assignments', data);
    },

    update(id, data = {}) {
        return Vue.axios.put(`v1.0/assignments/${id}/update`, data);
    },

    autosaveNew(data = {}) {
        return Vue.axios.post('/v1.0/assignments/autosave', data);
    },

    autosaveExisting(id, data = {}) {
        return Vue.axios.put(`/v1.0/assignments/${id}/autosave`, data);
    },

    getById(id, include = null) {
        if (include === null) {
            include = [
                'user',
                'threads',
                'threads_count',
                'threads.messages_count',
                'threads.last_response_by_lawyer',
                'threads.last_response',
                'assignment_threads_with_reply_count',
                'followers',
                'media'
            ];
        }

        return Vue.axios.get(`/v1.0/assignments/${id}`, {
            params: {
                include
            }
        });
    },

    setStatus(id, status, sendCloseNotification = true) {
        return Vue.axios.put(`/v1.0/assignments/${id}/status`, { status, sendCloseNotification });
    },

    delete(id) {
        return Vue.axios.delete(`/v1.0/assignments/${id}`);
    },

    postParticipants(assignmentId, newParticipants = []) {
        if (!assignmentId) {
            return false;
        }

        // Allow for IDs and objects to be mixed.
        // Eg:
        // const participantIds = [
        //      'userid123',
        //      { id: 'userid234', is_follower: true }
        // ]
        //
        // The following function sets the is_follower property to be
        // false by default, and make sure the posted object(s) is
        // always properly formatted.
        const participants = newParticipants.map((newParticipant) => {
            if (typeof newParticipant === 'string') {
                return {
                    user_id: newParticipant, // eslint-disable-line
                    is_follower: false // eslint-disable-line
                };
            }

            return newParticipant;
        });

        return Vue.axios.post(`/v1.0/assignments/${assignmentId}/participants`, {
            participants
        });
    },

    deleteParticipants(assignmentId, deletedParticipantIds = []) {
        if (!assignmentId) {
            return false;
        }

        const deletedParticipants = deletedParticipantIds.map((participantId) => {
            return {
                user_id: participantId // eslint-disable-line
            };
        });

        return Vue.axios.delete(`/v1.0/assignments/${assignmentId}/participants`, {
            data: {
                participants: deletedParticipants
            }
        });
    }
};
