'use strict';

import { find } from 'lodash-es';

export default {
    user: state => state.user,

    users(state, getters) {
        const loggedInUser = getters.loggedInUser;

        if (!state.users || !loggedInUser) {
            return [];
        }

        return state.users.filter((user) => {
            return user.id !== loggedInUser.id;
        });
    },

    filteredUsers(state, getters) {
        const loggedInUser = getters.loggedInUser;

        if (!state.filteredUsers || !loggedInUser) {
            return [];
        }

        return state.filteredUsers.filter((user) => {
            return user.id !== loggedInUser.id;
        });
    },

    userById: (state, getters) => (id) => {
        const foundUser = find(getters.users, { id });

        return foundUser !== -1 ? foundUser : false;
    },

    clients(state, getters) {
        return getters.users.filter(user => user.roles.includes('client'));
    },

    clientIds(state, getters) {
        return getters.clients.map(client => client.id);
    },

    lawyersAndSuppliers(state, getters) {
        return getters.users.filter((user) => {
            return user.roles.includes('lawyer') || user.roles.includes('supplier');
        });
    },

    lawyerAndSupplierIds(state, getters) {
        return getters.lawyersAndSuppliers.map(user => user.id);
    },

    filteredLawyersAndSuppliers(state, getters) {
        return getters.filteredUsers.filter((user) => {
            return user.roles.includes('lawyer') || user.roles.includes('supplier');
        });
    },

    /**
     * Selected users
     */
    selectedLawyerAndSupplierIds(state) {
        return state.selectedLawyerAndSupplierIds;
    },

    selectedLawyersAndSuppliers(state, getters) {
        return getters.lawyersAndSuppliers.filter(user => getters.selectedLawyerAndSupplierIds.includes(user.id));
    },

    /**
     * Pending users
     */
    pendingUsers(state) {
        return state.pendingUsers;
    },

    hasPendingUsers(state) {
        return state.pendingUsers && state.pendingUsers.length > 0;
    },

    /**
     * Logged in user
     */
    loggedInUser(state, getters, rootState, rootGetters) {
        return rootGetters['auth/user'];
    }
};
