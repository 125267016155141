<template>
    <div class="repeater">
        <div
            v-for="(item, index) in items"
            :key="index"
            class="repeater__item"
            :class="{ 'column-grid': columns }"
        >
            <slot
                name="items"
                :index="index"
            />

            <div class="repeater__buttons">
                <slot
                    name="buttons"
                    :index="index"
                />

                <v-button
                    v-if="items.length > 1 && item.required !== true"
                    class="repeater__button"
                    @click="removeItem(index)"
                >
                    <svg-icon icon="trash" />
                </v-button>

                <v-button
                    v-if="index === items.length - 1"
                    class="repeater__button btn--secondary"
                    @click="addItem"
                >
                    <svg-icon icon="plus" />
                </v-button>
            </div>

            <slot
                name="info"
                :index="index"
            />
        </div>

        <slot name="total" />
    </div>
</template>

<script>
import VButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        VButton
    },

    props: {
        items: {
            type: Array,
            default: () => { return []; }
        },
        columns: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        addItem() {
            const items = this.items;

            const emptyItem = {};

            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            for (const [key, value] of Object.entries(this.items[0])) {
                emptyItem[key] = undefined;
            }

            items.push(emptyItem);

            this.$emit('input', items);
        },

        removeItem(index) {
            const items = this.items;

            items.splice(index, 1);

            this.$emit('input', items);
        }
    }
};
</script>

<style lang="less">
.repeater__item {
    margin-bottom: 1rem;

    &.column-grid {
        align-items: flex-end;
    }

    & + & {
        margin-top: 1rem;
    }

    // Hide labels for items except the first
    &:not(:first-child) .form-field__label {
        display: none;
    }
}

.repeater__buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    flex: 1 1 auto;
}

.repeater__button {
    height: 2.6875rem;
    padding: 0;
    width: 2.6875rem;

    .icon {
        position: relative;
        top: 2px;
    }

    & + & {
        margin-left: .25rem;
    }
}

</style>
