<template>
    <div class="frame-view">
        <div class="frame-view__frame">
            <div
                v-if="!!$slots.header"
                class="frame-view__header"
            >
                <slot name="header" />
            </div>

            <div class="frame-view__body">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
'use strict';

export default {

};
</script>

<style lang="less">
@import './frame-view.less';
</style>
