<template>
    <dn-modal
        name="tender-rounds"
        class="tender-rounds-modal"
        position="centered"
        size="large"
    >
        <modal-content>
            <section class="text-center">
                <h2>{{ $t(`modal.tender-rounds.${status}.title`) }}</h2>
            </section>

            <section>
                <notice severity="notice">
                    <h4>{{ $t('modal.tender-rounds.notice.title') }}</h4>
                    <div>{{ $t('modal.tender-rounds.notice.body') }}</div>
                </notice>

                <p>{{ $t(`modal.tender-rounds.${status}.explanation`) }}</p>

                <form
                    data-vv-scope="tender-rounds"
                    class="form"
                    @submit.prevent="handleSubmit"
                >
                    <div class="tender-rounds-modal__date-row">
                        <form-field
                            class="form-field"
                            :error-messages="errors.collect('tender-rounds.end_date')"
                        >
                            <label
                                slot="label"
                                for="date"
                            >
                                {{ $t('modal.tender-rounds.label.end-date') }}
                            </label>

                            <datepicker
                                id="date"
                                ref="date"
                                v-model="endDate"
                                name="date"
                                :disabled-dates="{to: new Date(Date.now() - 86400000)}"
                                :placeholder="$t(`modal.tender-rounds.${status}.placeholder-date`)"
                                data-vv-as="date"
                            />

                            <svg-icon
                                slot="icon"
                                icon="calendar"
                            />
                        </form-field>
                        
                        <form-field
                            class="form-field"
                            :error-messages="errors.collect('tender-rounds.end_time')"
                        >
                            <label
                                slot="label"
                                for="time"
                            >
                                {{ $t('modal.tender-rounds.label.end-time') }}
                            </label>
                            <input
                                id="time"
                                v-model="endTime"
                                type="time"
                            >
                        </form-field>

                        <div
                            v-if="$options.filters.timeRemaining(endDateTime) !== null"
                            class="tender-rounds-modal__time-notice"
                        >
                            {{ $t('modal.tender-rounds.time-notice', { time: $options.filters.timeRemaining(endDateTime) }) }}
                        </div>
                        <div
                            v-else
                            class="tender-rounds-modal__time-notice"
                        >
                            {{ $t('modal.tender-rounds.time-invalid') }}
                        </div>
                    </div>

                    <form-field>
                        <toggle-switch
                            id="ignore_ends_at"
                            v-model="ignore_ends_at"
                        >
                            <template #label-after>
                                <label for="ignore_ends_at">
                                    {{ $t('modal.tender-rounds.label.ignore-ends-at') }}
                                </label>
                            </template>
                        </toggle-switch>
                    </form-field>

                    <!-- <form-field
                        class="form-field"
                        :error-messages="errors.collect('tender-rounds.message')"
                    >
                        <label slot="label" for="message">
                            {{ $t('modal.tender-rounds.label.message') }}
                        </label>

                        <textarea
                            v-model="message"
                            id="message"
                            :placeholder="$t(`modal.tender-rounds.${status}.placeholder-message`)"
                            type="text"
                        />
                    </form-field> -->
                </form>
            </section>

            <section class="text-right">
                <v-button
                    v-if="status === 'edit'"
                    class="btn btn--medium btn--inline"
                    :state="endRequestState"
                    @click="endTenderRound"
                >
                    {{ $t(`modal.tender-rounds.${status}.end`) }}
                </v-button>

                <v-button
                    class="btn btn--secondary btn--medium btn--inline"
                    :state="requestState"
                    :disabled="!canSubmit"
                    @click="handleSubmit"
                >
                    {{ $t(`modal.tender-rounds.${status}.submit`) }}
                </v-button>
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { UTCDate } from '@date-fns/utc';
import { format, set, formatISO, addWeeks } from 'date-fns';

import { mapGetters } from 'vuex';

import Datepicker from 'vuejs-datepicker';
import VButton from '~/patterns/atoms/button/button.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import Notice from '~/patterns/atoms/notice/notice.vue';

import createTenderRoundMutation from '~/graphql/mutations/createTenderRound.graphql';
import updateTenderRoundMutation from '~/graphql/mutations/updateTenderRound.graphql';
import endTenderRoundMutation from '~/graphql/mutations/endTenderRound.graphql';
import ToggleSwitch from '~/patterns/atoms/toggle-switch/toggle-switch.vue';
import SvgIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        DnModal,
        ModalContent,
        VButton,
        FormField,
        Datepicker,
        ToggleSwitch,
        SvgIcon,
        Notice
    },

    data() {
        return {
            requestState: null,
            endRequestState: null,
            endDate: null,
            endTime: null,
            message: null,
            ignore_ends_at: false
        };
    },

    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen'
        }),

        data() {
            return this.$store.getters['modal/data'];
        },

        open() {
            return this.isOpen('tender-rounds');
        },

        endDateTime() {
            if (!this.endDate || !this.endTime) {
                return null;
            }

            const hours = this.endTime.split(':')[0];
            const minutes = this.endTime.split(':')[1];

            const combinedDateTime = set(new Date(this.endDate), { hours, minutes });

            return new UTCDate(combinedDateTime);
        },

        activeTenderRound() {
            return this.data?.assignment?.activeTenderRound;
        },

        canSubmit() {
            if (this.$options.filters.timeRemaining(this.endDateTime) === null) {
                return false;
            }
            
            return true;
        },

        status() {
            if (this.data?.tenderRoundIsActive === true) {
                return 'edit';
            } else {
                return 'start';
            }
        }
    },

    watch: {
        requestState: {
            handler() {
                if(this.requestState === 'success') {
                    this.$store.dispatch('modal/closeModal');
                }
            }
        },

        endRequestState: {
            handler() {
                if(this.endRequestState === 'success') {
                    this.$store.dispatch('modal/closeModal');
                }
            }
        },

        open: {
            handler(newValue) {
                if (newValue && this.status === 'start') {
                    // Default end date is 1 week from now
                    const date = addWeeks(new Date(), 1);

                    this.endTime = format(date, 'HH:mm');
                    this.endDate = format(date, 'yyyy-MM-dd');
                }

                if (this.status === 'edit' && this.activeTenderRound) {
                    const date = new Date(this.activeTenderRound.ends_at);

                    this.endTime = format(date, 'HH:mm');
                    this.endDate = format(date, 'yyyy-MM-dd');

                    this.ignore_ends_at = this.activeTenderRound.ignore_ends_at;
                }

                // Clear request state on modal state change
                this.requestState = null;
                this.endRequestState = null;
            },
            immediate: true
        }
    },

    methods: {
        async createTenderRound() {
            if (this.data?.isTenderRoundActive === true) {
                return;
            }

            return this.$validator.validateAll('tender-rounds').then(async(valid) => {
                if (valid) {
                    this.requestState = 'loading';
                    try {
                        await this.$executeGqlQuery(createTenderRoundMutation, {
                            assignmentId: this.data.assignment.id,
                            endsAt: formatISO(this.endDateTime),
                            ignoreEndsAt: this.ignore_ends_at,
                            broadcastMessage: this.message
                        });
                        
                        this.requestState = 'success';
                        this.$eventBus.$emit('create-tender-round');
                    } catch (error) {
                        // Bugsnag.notify(error);
                        console.error(error);
                        this.requestState = 'fail';
                    }
                }
            });
        },

        async updateTenderRound() {
            if (!this.activeTenderRound) {
                this.requestState = 'fail';
                return;
            }

            return this.$validator.validateAll('tender-rounds').then(async(valid) => {
                if (valid) {
                    this.requestState = 'loading';
                    try {
                        await this.$executeGqlQuery(updateTenderRoundMutation, {
                            id: this.activeTenderRound.id,
                            endsAt: formatISO(this.endDateTime),
                            ignoreEndsAt: this.ignore_ends_at,
                            broadcastMessage: null
                        });

                        this.$eventBus.$emit('update-tender-round');
                        this.requestState = 'success';
                    } catch (error) {
                        // Bugsnag.notify(error);
                        console.error(error);
                        this.requestState = 'fail';
                    }
                }
            });
        },

        async endTenderRound() {
            this.endRequestState = 'loading';
            try {
                await this.$executeGqlQuery(endTenderRoundMutation, {
                    id: this.activeTenderRound.id,
                });

                this.$eventBus.$emit('update-tender-round');
                this.endRequestState = 'success';
            } catch (error) {
                // Bugsnag.notify(error);
                console.error(error);
                this.endRequestState = 'fail';
            }
        },

        handleSubmit() {
            if (!this.status) {
                return;
            }

            if (this.status === 'edit') {
                this.updateTenderRound();
            } else {
                this.createTenderRound();
            }   
        }
    }
};
</script>

<style lang="less">
.tender-rounds-modal {
    // Reset overflows for datepicker
    .modal__content-wrapper,
    .modal__body {
        overflow: visible;
    }
}
.tender-rounds-modal__date-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: 1.5rem;
    gap: 1.5rem;

    .form-field {
        width: 100%;
        max-width: 15rem;
        min-width: 10rem;
        margin-bottom: 0;
    }
}

.tender-rounds-modal__time-notice {
    display: flex;
    height: 2.8125rem;
    align-items: center;
    color: @color-gray-11;
}

.tender-rounds-modal .notice {
    background: hsl(var(--color-button-hue), var(--color-button-sat), 95%);
    color: var(--color-button, --color-action);

    font-weight: 400;
    line-height: 1.3125;
    padding: .75rem;
    margin-bottom: 1rem;

    h4 {
        font-weight: 600;
        font-size: 1rem;
        margin-bottom: .5rem;
    }
}
</style>
