<template>
    <dn-modal
        v-if="modalData"
        name="select-template"
        size="medium"
        position="centered"
    >
        <modal-content class="modal-content--dialog">
            <h1>{{ $t('cta.add-assignment') }}</h1>

            <template v-if="modalData.templates && modalData.templates.length">
                <span class="font-primary-light">{{ $t('modal.select-template.select-a-template') }}</span>

                <llq-button
                    v-for="template in modalData.templates"
                    :key="template.id"
                    class="btn--wide btn--spaced btn--two-icons"
                    @click.prevent="selectTemplate(template.id)"
                >
                    <svg-icon icon="copy" />
                    <span>{{ template.title }}</span>
                    <svg-icon
                        v-if="template.description"
                        v-tooltip="{ content: template.description }"
                        class="text--muted"
                        icon="info"
                    />
                </llq-button>

                <br><br>
                <span class="font-primary-light">{{ $t('or') }}</span>
                <br><br>
            </template>

            <llq-button
                class="btn--icon-left btn--wide btn--left-align"
                @click.prevent="selectTemplate(null)"
            >
                <svg-icon icon="plus" />
                <span>{{ $tc('modal.select-template.empty', modalData?.templates?.length || 0) }}</span>
            </llq-button>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';

import LlqButton from '~/patterns/atoms/button/button.vue';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';

export default {
    components: {
        LlqButton,
        DnModal,
        ModalContent
    },

    data() {
        return {
            templates: null,
            isLoading: false,
            requestState: null,
        };
    },

    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen',
            modalData: 'modal/data'
        }),

        modalOpen() {
            return this.isOpen('select-template');
        },
    },

    methods: {
        selectTemplate(templateId) {
            let query = null;
            if (templateId) {
                query = { templateId };
            }
            this.$store.dispatch('modal/closeModal');
            this.$router.push({ name: 'assignment.create', query });
        }
    }
};
</script>
