<template>
    <div class="page-loader">
        <div class="page-loader__throbber">
            <spinner />
        </div>
    </div>
</template>

<script>
'use strict';

import Spinner from '~/patterns/atoms/spinner/spinner.vue';

export default {
    components: {
        Spinner
    }
};
</script>

<style lang="less">
@import './page-loader.less';
</style>