<template>
    <div class="navigation">
        <!-- Top bar -->
        <div class="navigation__bar navigation__bar--default">
            <a
                class="navigation__logo"
                href="/"
            >
                <img
                    src="/img/logos/link.svg"
                    alt="LawyerlinQ"
                >
            </a>

            <div class="navigation__return">
                <return-link />
            </div>

            <div class="navigation__bar__right">
                <button
                    v-if="!isGuest()"
                    type="button"
                    class="navigation__item navigation__item--account"
                    @click="setActiveTab('account')"
                >
                    <avatar
                        :user="user"
                        :link-to-profile="false"
                        :is-unread="hasActivity"
                        class="avatar--darkened"
                    />

                    <svg-icon icon="chevron-down" />
                </button>

                <nav-dropdown :active-tab="activeTab" />
            </div>
        </div>
    </div>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import isVisible from '~/helpers/isVisible';

import returnLink from '~/patterns/atoms/return-link/return-link.vue';
import navDropdown from '~/patterns/molecules/nav-dropdown/nav-dropdown.vue';
import avatar from '~/patterns/atoms/avatar/avatar.vue';

export default {
    data() {
        return {
            activeTab: false
        };
    },
    mounted() {
        const navBarRight = document.querySelector('.navigation__bar__right'),
            navDropdownEl = document.querySelector('.nav-dropdown'),
            outsideClickListener = (e) => {
                if (!navDropdownEl.contains(e.target) && !navBarRight.contains(e.target)) {
                    if (isVisible(navDropdownEl)) {
                        this.activeTab = false;
                    }
                }
            };

        document.addEventListener('click', outsideClickListener);
    },

    computed: {
        ...mapGetters({
            hasUpdatedCommunities: 'activity/hasUpdatedCommunities'
        }),

        hasActivity() {
            return this.hasUpdatedCommunities && window.appConfig.context !== 'community-portal';
        },

        user() {
            return this.$store.getters['auth/user'];
        }
    },

    methods: {
        setActiveTab(name) {
            if (this.activeTab === name) {
                this.activeTab = false;
                return this.activeTab;
            }

            this.activeTab = name;
        }
    },

    watch: {
        $route() {
            this.setActiveTab(false);
        }
    },

    components: {
        navDropdown,
        avatar,
        returnLink
    }
};
</script>

<style lang="less">
@import './navigation.less';
</style>
