<template>
    <dn-modal
        name="timeline-edit"
        position="centered"
        size="medium"
    >
        <modal-content v-if="highlight">
            <section class="text-center">
                <h2 v-if="highlight.type === 'experience'">{{ $t('modal.timeline-edit.title-experience') }}</h2>
                <h2 v-if="highlight.type === 'study'">{{ $t('modal.timeline-edit.title-study') }}</h2>
            </section>

            <section>
                <form class="form">
                    <form-field class="column-grid">
                        <form-field
                            :error-messages="errors.collect('start')"
                            columns-sm-min="4/8"
                            columns-md-min="3/8"
                        >
                            <label
                                slot="label"
                                for="start"
                            >
                                {{ $t('modal.timeline-edit.start.label') }}
                            </label>

                            <input
                                id="start"
                                v-model="highlight.start"
                                v-validate="'required'"
                                type="number"
                                name="start"
                                :placeholder="$t('modal.timeline-edit.start.placeholder')"
                            >
                        </form-field>

                        <form-field
                            :error-messages="errors.collect('end')"
                            columns-sm-min="4/8"
                            columns-md-min="3/8"
                        >
                            <label
                                slot="label"
                                for="start"
                            >
                                {{ $t('modal.timeline-edit.end.label') }}
                            </label>

                            <input
                                id="end"
                                v-model="highlight.end"
                                type="number"
                                name="end"
                                :placeholder="$t('modal.timeline-edit.end.placeholder')"
                            >
                        </form-field>
                    </form-field>

                    <form-field
                        :error-messages="errors.collect('title')"
                    >
                        <label
                            slot="label"
                            for="title"
                        >{{ titleField.label }}</label>

                        <input
                            id="title"
                            v-model="highlight.title"
                            v-validate="'required'"
                            type="text"
                            name="title"
                            :placeholder="titleField.placeholder"
                        >
                    </form-field>

                    <form-field
                        class="form-field--last"
                        :error-messages="errors.collect('organisation')"
                    >
                        <label
                            slot="label"
                            for="organisation"
                        >{{ organisationField.label }}</label>

                        <input
                            id="organisation"
                            v-model="highlight.organisation"
                            v-validate="'required'"
                            type="text"
                            name="organisation"
                            :placeholder="organisationField.placeholder"
                        >
                    </form-field>
                </form>
            </section>

            <section class="text-right">
                <button
                    class="btn btn--link-error btn--medium"
                    style="margin-right: 1rem;"
                    @click="deleteHighlight"
                >
                    {{ $t('modal.timeline-edit.delete') }}
                </button>

                <button
                    class="btn btn--secondary btn--medium"
                    @click="saveHighlight"
                >
                    {{ $t('modal.timeline-edit.submit') }}
                </button>
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { clone } from 'lodash-es';

import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';

export default {

    components: {
        DnModal,
        ModalContent,
        FormField
    },
    data() {
        return {
            highlight: {
                start: undefined,
                end: undefined,
                title: undefined,
                organsation: undefined,
                type: undefined
            }
        };
    },

    computed: {
        data() {
            return this.$store.getters['modal/data'];
        },

        titleField() {
            if (!this.highlight) {
                return {};
            }

            if (this.highlight.type === 'experience') {
                return {
                    label: this.$i18n.t('modal.timeline-edit.role.label'),
                    placeholder: this.$i18n.t('modal.timeline-edit.role.placeholder')
                };
            }

            return {
                label: this.$i18n.t('modal.timeline-edit.study.label'),
                placeholder: this.$i18n.t('modal.timeline-edit.study.placeholder')
            };
        },

        organisationField() {
            if (!this.highlight) {
                return {};
            }

            if (this.highlight.type === 'experience') {
                return {
                    label: this.$i18n.t('modal.timeline-edit.company.label'),
                    placeholder: this.$i18n.t('modal.timeline-edit.company.placeholder')
                };
            }

            return {
                label: this.$i18n.t('modal.timeline-edit.institute.label'),
                placeholder: this.$i18n.t('modal.timeline-edit.institute.placeholder')
            };
        }
    },

    watch: {
        data() {
            this.highlight = clone(this.data);
        }
    },

    methods: {
        saveHighlight() {
            this.$validator.validateAll().then((noErrors) => {
                if (!noErrors) {
                    return;
                }

                // eslint-disable-next-line vue/custom-event-name-casing
                this.$eventBus.$emit('update-timelineItem', this.highlight);
                this.$store.dispatch('modal/closeModal');
            });
        },

        deleteHighlight() {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$eventBus.$emit('delete-timelineItem', this.highlight);
            this.$store.dispatch('modal/closeModal');
        }
    }
};
</script>
