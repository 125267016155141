<template>
    <div class="confirm-box">
        <div class="confirm-box__intro">
            <div
                v-if="showLogo"
                class="confirm-box__logo"
            >
                <img
                    src="/img/logos/llq-orange.svg"
                    alt="LawyerlinQ"
                >
            </div>
            <slot name="title">
                <h1 v-if="title">{{ title }}</h1>
            </slot>

            <div
                v-if="hasActions"
                class="confirm-box__actions"
            >
                <slot name="actions" />
            </div>
        </div>

        <div
            v-if="headerTitle && !$slots.header"
            class="confirm-box__header"
        >
            <h2>{{ headerTitle }}</h2>
        </div>

        <div
            v-if="!!$slots.header"
            class="confirm-box__header"
        >
            <slot name="header" />
        </div>

        <article class="confirm-box__body">
            <slot />
        </article>

        <div class="confirm-box__footer">
            <slot name="footer" />

            <template>
                <div class="confirm-box__check">
                    <checkbox
                        v-if="canAccept"
                        v-model="isChecked"
                    >
                        {{ acceptLabel }}
                    </checkbox>

                    <div
                        v-if="downloadUrl"
                        class="confirm-box__download"
                    >
                        <file-download
                            :attachment="{name: downloadButtonLabel}"
                            :href="downloadUrl"
                        />
                    </div>
                </div>

                <v-button
                    v-if="canAccept"
                    class="btn--secondary"
                    :state="requestState"
                    :disabled="!isChecked || disabled"
                    @click="clickAccept"
                >
                    {{ acceptButtonLabel }}
                </v-button>
            </template>
        </div>
    </div>
</template>

<script>
'use strict';

import VButton from '~/patterns/atoms/button/button.vue';
import Checkbox from '~/patterns/atoms/checkbox/checkbox.vue';
import FileDownload from '~/patterns/atoms/file-download/file-download.vue';

export default {
    components: {
        Checkbox,
        VButton,
        FileDownload
    },

    props: {
        canAccept: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null,
            required: false
        },
        headerTitle: {
            type: String,
            default: null,
            required: false
        },
        acceptLabel: {
            type: String,
            default: null,
            required: false
        },
        downloadUrl: {
            type: String,
            default: null,
            required: false
        },
        downloadButtonLabel: {
            type: String,
            default: null,
            required: false
        },
        acceptButtonLabel: {
            type: String,
            default: null,
            required: false
        },
        confirmMessage: {
            type: String,
            default: null,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        requestState: {
            type: String,
            default: null,
            required: false
        },
        showLogo: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            isChecked: false,
        };
    },

    computed: {
        hasActions() {
            return !!this.$slots.actions;
        }
    },

    methods: {
        clickAccept() {
            if (!this.canAccept) {
                return false;
            }

            let confirmed;

            if (this.confirmMessage !== null) {
                confirmed = confirm(this.confirmMessage);
            } else {
                confirmed = true;
            }

            if (confirmed) {
                this.$emit('confirmed');
            }
        }
    }
};
</script>

<style lang="less">
@import './confirm-box.less';
</style>
