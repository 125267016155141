<template>
    <section
        class="selectable-list"
        :data-selection-allowed="allowSelection ? 'true' : 'false'"
    >
        <section-header
            v-if="showHeader"
            class="selectable-list__header"
        >
            <template #prefix>
                <button
                    v-if="allowSelection"
                    type="button"
                    class="checkbox__custom"
                    :data-selected="selectedAll ? 'true' : 'false'"
                    :data-intermediate="selectedIds.length > 0 && !selectedAll"
                    @click="toggleAll"
                />
            </template>

            <template #title>
                <slot name="title" />
            </template>

            <template #actions>
                <slot name="header_actions" />
            </template>
        </section-header>

        <div class="selectable-list__body">
            <ul
                v-if="selectableItems && selectableItems.length"
                class="list list--clean"
            >
                <li
                    v-for="(item, index) in selectableItems"
                    :key="index"
                    class="selectable-list__item"
                >
                    <transition name="fade-absolute">
                        <div
                            v-show="allowSelection"
                            class="selectable-list__checkbox"
                        >
                            <button
                                type="button"
                                class="checkbox__custom"
                                :data-selected="isSelected(item) ? 'true' : 'false'"
                                :disabled="isDisabled(item)"
                                @click="toggleItem(item)"
                            />
                        </div>
                    </transition>

                    <slot
                        name="preview"
                        :item="item"
                        :index="index"
                    />

                    <div class="selectable-list__actions">
                        <slot
                            name="actions"
                            :item="item"
                        />
                    </div>
                </li>
            </ul>
        </div>

        <slot name="cta" />
    </section>
</template>

<script>
'use strict';

import Vue from 'vue';
import SectionHeader from '~/patterns/molecules/section-header/section-header.vue';

export default {
    components: {
        SectionHeader
    },

    props: {
        selectableItems: {
            type: Array,
            required: true
        },
        allowSelection: {
            type: Boolean,
            default: true
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        forceSelectedIds: {
            type: Array,
            default: () => []
        },
        preselectIds: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            selectedIds: []
        };
    },

    computed: {
        selectedAll() {
            return this.selectedIds.length === this.selectableItems.length;
        }
    },

    watch: {
        selectedIds(val) {
            this.$emit('input', val);
        }
    },

    created() {
        // Preselect IDs passed via optional prop
        if (this.preselectIds.length) {
            this.selectedIds = this.preselectIds;
        }
    },

    methods: {
        toggleItem(item) {
            this.$emit('toggle', item);

            const key = this.selectedIds.findIndex(val => val === item.id);
            if (key === -1) {
                this.selectedIds.push(item.id);
            } else {
                Vue.delete(this.selectedIds, key);
            }
        },

        toggleAll() {
            if (this.selectedAll) {
                // eslint-disable-next-line vue/custom-event-name-casing
                this.$emit('deselectAll');
                this.selectedIds = [];
            } else {
                // eslint-disable-next-line vue/custom-event-name-casing
                this.$emit('selectAll');
                this.selectedIds = this.selectableItems.map(({ id }) => id);
            }
        },

        isSelected(item) {
            return this.isForceSelected(item) || this.selectedIds.includes(item.id);
        },

        isDisabled(item) {
            return this.isForceSelected(item);
        },

        isForceSelected(item) {
            return this.forceSelectedIds.includes(item.id);
        }
    }
};
</script>

<style lang="less">

    .selectable-list {

    }

    .selectable-list__body {

    }

    .selectable-list__header {
        margin: 1.375rem 0;

        /* Prevents header resize when button disappears */
        .section-header__body {
            @media @q-lg-min {
                min-height: 4.75rem;
            }
        }
    }

    .selectable-list__item {
        content-visibility: auto;
        position: relative;
        padding: .4rem;
        transition: @transition-default;

        @media @q-sm-min {
            padding: .75rem 0;
        }
    }

    .selectable-list__actions {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    .selectable-list__checkbox {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    /*
     * Modifiers
     */
    .selectable-list[data-selection-allowed='true'] {
        .selectable-list__item {
            position: relative;
            padding-left: 2.5rem;
        }
    }

    .selectable-list--padded {
        .selectable-list__item {
            @media @q-sm-min {
                padding-right: 8rem;
            }
        }
    }

    .selectable-list--bordered {
        .selectable-list__item {
            padding-top: 0;

            padding-bottom: 1.5625rem;
            margin-bottom: 1.5625rem;

            border-bottom: 1px solid rgba(0, 0, 0, .05);
        }
    }
</style>
