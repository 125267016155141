import Vue from 'vue';

export default {
    getActivity() {
        return Vue.axios.get('/v1.0/activities');
    },

    putActivity(activiableId) {
        return Vue.axios.put(`/v1.0/activities/${activiableId}`);
    }
};
