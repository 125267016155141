const env = import.meta.env.MODE || 'development',
    config = {
        gtmId: 'GTM-MCR5QCT',
        bugsnagId: '004376ab1bf04fbf46b17884b400a371'
    };

export default function() {
    return import(`./config-${env}.js`)
        .then((module) => {
            return Object.assign(config, module.default);
        });
}
