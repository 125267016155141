<template>
    <div class="timeline-field">
        <timeline
            v-for="(highlight, index) in sortedTimeline"
            :key="index"
            :highlight="highlight"
            :editable="true"
            @click-edit="onClickEditHighlight"
            @change="onChangeHighlight"
        >
            <template #label>
                <div class="highlight__date">
                    {{ highlight.start }} <template v-if="highlight.end && (highlight.start !== highlight.end)">- {{ highlight.end }}</template>
                </div>
            </template>
        </timeline>

        <div class="timeline-field__actions">
            <button
                type="button"
                class="btn btn--link-secondary btn--small"
                @click="onClickAddHighlight('study')"
            >
                <svg-icon icon="plus" /> Opleiding toevoegen
            </button>

            <button
                type="button"
                class="btn btn--link-secondary btn--small"
                @click="onClickAddHighlight('experience')"
            >
                <svg-icon icon="plus" /> Werkervaring toevoegen
            </button>
        </div>
    </div>
</template>

<script>
'use strict';

import { clone, findIndex } from 'lodash-es';
import Timeline from '~/patterns/atoms/timeline/timeline.vue';
import guid from '~/helpers/guid';

export default {

    components: {
        Timeline
    },
    props: [
        'value'
    ],

    data() {
        return {
            highlights: []
        };
    },

    computed: {
        sortedTimeline() {
            const highlights = clone(this.highlights);

            return highlights.sort((a, b) => b.start - a.start);
        }
    },

    watch: {
        highlights() {
            this.$emit('input', this.highlights);
        }
    },

    created() {
        this.prepareTimeline();
        this.$eventBus.$on('delete-timelineItem', this.onDeleteTimeline);
        this.$eventBus.$on('add-timelineItem', this.onAddTimeline);
    },

    methods: {
        onClickAddHighlight(type) {
            this.$store.dispatch('modal/openModal', {
                name: 'timeline-add',
                data: {
                    type
                }
            });
        },

        onClickEditHighlight(highlight) {
            this.$store.dispatch('modal/openModal', {
                name: 'timeline-edit',
                data: highlight
            });
        },

        onAddTimeline(highlight) {
            const newHighlight = clone(highlight);
            newHighlight.id = guid();
            this.highlights.push(newHighlight);
        },

        onChangeHighlight(changedHighlight) {
            const matchingHighlightIndex = findIndex(this.highlights, { id: changedHighlight.id }),
                highlights = clone(this.highlights);

            if (matchingHighlightIndex === -1) {
                return false;
            }

            highlights[matchingHighlightIndex] = changedHighlight;

            this.highlights = highlights;
        },

        onDeleteTimeline(deletedHighlight) {
            const matchingHighlightIndex = findIndex(this.highlights, { id: deletedHighlight.id });

            if (matchingHighlightIndex === -1) {
                return false;
            }

            this.highlights.splice(matchingHighlightIndex, 1);
        },

        prepareTimeline() {
            if (!this.value) {
                return false;
            }
            const highlights = [];

            this.value.forEach((highlight) => {
                highlights.push({
                    id: guid(),
                    start: highlight.start || undefined,
                    end: highlight.end || undefined,
                    title: highlight.title || undefined,
                    organisation: highlight.organisation || undefined,
                    type: highlight.type || undefined
                });
            });

            this.highlights = highlights;
        }
    }
};
</script>

<style lang="less">
@import './timeline-field.less';
</style>
