
<div id="page">
    <toasts />

    <navigation />

    <page-header-main />

    <transition
        name="subnav"
        mode="out-in"
    >
        <sub-navigation-main
            v-if="subnav === 'main'"
            key="main"
        />
    </transition>

    <main class="layout">
        <transition name="page">
            <router-view
                v-if="(termsAccepted && userIsLoggedInLockedAndLoaded) || $route.meta.noAuthRequired"
                class="layout__view"
            />
        </transition>
    </main>

    <transition name="fade-absolute">
        <page-loader v-if="loading" />
    </transition>

    <accept-terms-modal
        v-if="userIsLoggedInLockedAndLoaded && !termsAccepted"
        :modal-open="!termsAccepted && !loading"
    />
    <user-profile-modal />

    <create-proposal-modal />

    <proposal-dialog-modal />
    <assignment-created-modal />
    <proposal-accepted-modal />
    <proposal-accept-dialog-modal v-if="isAdmin() || isClient() || isCommunityManager()" />
    <timeline-edit-modal />
    <timeline-add-modal />

    <assignment-response-modal />
    <assignment-add-follower-modal />
    <assignment-push-modal v-if="isAdmin() || isClient() || isCommunityManager()" />
    <conflict-check-modal />
    <select-participants-modal />
    <save-user-selection-modal />
    <proposal-preview-modal />
    <pushed-proposal-modal v-if="isAdmin() || isClient() || isCommunityManager()" />
    <proposals-share-modal v-if="isAdmin() || isClient() || isCommunityManager()" />
    <proposal-created-modal />

    <user-invite-modal v-if="isClient()" />
    <user-invited-modal v-if="isClient()" />
    <broadcast-modal v-if="isClient()" />
    <select-template-modal v-if="isAdmin() || isClient()" />
    <tender-rounds-modal v-if="isClient()" />
</div>
