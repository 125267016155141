
<div
    class="toast"
    :class="{
        'toast--warning': severity === 'warning',
        'toast--error': severity === 'error'
    }"
>
    <span
        class="toast__content"
        v-html="content"
    />
</div>
