<template>
    <div
        class="tariff-calculation-total"
        :class="`tariff-calculation-total--${type}`"
    >
        <div class="tariff-calculation-total__row tariff-calculation-total__row--fee">
            <div class="tariff-calculation-total__spacer">
                {{ $t('tariffCalculationTotal.message') }}
            </div>

            <template v-if="type === 'range'">
                <span class="tariff-calculation-total__cell tariff-calculation-total__cell--hide-small">
                    <template v-if="getTotal(workloads, 'lower_boundary') !== null">
                        {{ $n(getTotal(workloads, 'lower_boundary'), 'currency') }}
                    </template>
                </span>
                <span class="tariff-calculation-total__cell tariff-calculation-total__cell--hide-small">
                    <template v-if="getTotal(workloads, 'upper_boundary') !== null">
                        {{ $n(getTotal(workloads, 'upper_boundary'), 'currency') }}
                    </template>
                </span>
            </template>

            <template v-else>
                <span class="tariff-calculation-total__cell">
                    {{ $n(parsedTotal, 'currency') }}
                </span>
            </template>
        </div>
    </div>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import ProposalFees from '~/helpers/proposal-fees';

export default {
    props: {
        workloads: {
            type: Array,
            default: () => { return []; },
            required: true,
        },
        type: {
            type: String,
            default: 'total'
        }
    },
    computed: {
        ...mapGetters({
            loggedInUser: 'auth/user',
            community: 'community/community'
        }),

        total() {
            return this.getTotal(this.workloads, 'total_estimate');
        },

        parsedTotal() {
            return Number(this.total);
        },

        llqFeeDecimal() {
            return ProposalFees.getLawyerlinqFeePercentage(this.loggedInUser) / 100;
        },
    },

    methods: {
        getTotal(items, key) {
            let sum = null;

            items.forEach((item) => {
                if (item[key] !== undefined && item[key] !== '') {
                    sum += Number(item[key]);
                }
            });

            return sum - (sum * this.llqFeeDecimal);
        }
    }
};
</script>

<style lang="less">
@import './tariff-calculation-total.less';
</style>
