<template>
    <!-- TODO: Add modal here -->
    <span
        v-if="hasSupplier"
        class="supplier-info"
        :class="{
            'supplier-info--standalone': !inline
        }"
    >
        <template v-if="inline">
            {{ $t('supplierInfo.description', { supplier: user.supplier.title }) }}
        </template>

        <template v-else>
            <svg-icon
                class="supplier-info__icon"
                icon="colleague"
            />
            {{ user.supplier.title }}
        </template>
    </span>
</template>

<script>
export default {
    props: {
        user: {
            type: Object,
            default: null
        },
        inline: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasSupplier() {
            return this.user.supplier && Object.keys(this.user.supplier).length > 0;
        }
    }
};
</script>

<style lang="less">
@import './supplier-info.less';
</style>
