
<dl
    v-if="assignment"
    class="assignment-meta"
>
    <template v-if="assignment.starts_at">
        <dt>
            {{ $t('assignment.start-date') }}
        </dt>
        <dd>
            <time>
                {{ assignment.starts_at | dateFormat($t('date-format.date')) }}
            </time>
        </dd>
    </template>

    <template v-if="assignment.ends_at">
        <dt>
            {{ $t('assignment.end-date') }}
        </dt>
        <dd>
            <time>
                {{ assignment.ends_at | dateFormat($t('date-format.date')) }}
            </time>
        </dd>
    </template>

    <template v-if="assignment.duration">
        <dt>
            {{ $t('assignment.duration') }}
        </dt>
        <dd>
            {{ assignment.duration + ' ' + $t(`durationUnits.${assignment.duration_unit}`).toLowerCase() }}
        </dd>
    </template>

    <template v-if="assignment.tariff">
        <dt>
            {{ $t('assignment.tariff') }}
        </dt>
        <dd>
            {{ tariffTypeLabel }}

            <div v-if="assignment.travel_costs_markup">
                {{ $t('assignment.travel_costs_markup') }}
            </div>
        </dd>
    </template>

    <dt>
        {{ $t('assignment.location') }}
    </dt>
    <dd>
        <template v-if="assignment.location_type === 'remote'">
            {{ $t('assignment.location_type.remote') }}
        </template>

        <template v-else-if="assignment.location_type === 'onsite'">
            {{ $t('assignment.location_type.onsite') }}

            <div v-if="assignment.location_name">
                ({{ assignment.location_name }})
            </div>
        </template>

        <template v-else>
            {{ $t('assignment.location_type.none') }}
        </template>
    </dd>

    <template v-if="assignment.conditions">
        <dt>
            {{ $t('assignment.conditions') }}
        </dt>
        <dd>
            <div class="pre-wrap">{{ assignment.conditions }}</div>
        </dd>
    </template>

    <template v-if="assignment.proposal_form_config?.open_questions">
        <dt>
            {{ $t('assignment.open_questions.label') }}
        </dt>
        <dd>
            <div class="pre-wrap">{{ $t('assignment.open_questions.present') }}</div>
        </dd>
    </template>

    <dt>
        {{ $t('assignment.requested_information') }}
    </dt>
    <dd>
        <span>
            {{ requestedFields.join(', ') }}
        </span>

        <span v-if="assignment.ask_for_cv">
            , {{ $t('assignment.ask_for_cv') }}
        </span>
    </dd>

    <dt>
        {{ $t('assignment.respond_before') }}
    </dt>
    <dd>
        <time
            class="font-primary-regular"
            :datetime="assignment.respond_before"
        >
            {{ formattedDeadline }}
        </time>
    </dd>

    <template v-if="assignment.media && assignment.media.length">
        <dt>
            {{ $t('assignment.attachments') }}
        </dt>
        <dd>
            <template v-for="attachment in assignment.media">
                <file-download
                    :key="attachment.id"
                    :attachment="attachment"
                    :href="getAttachmentUrl(attachment.id)"
                />
            </template>
        </dd>
    </template>

    <template v-if="assignment.proposal_compare_option?.length && assignment.proposal_compare_option !== 'NONE'">
        <dd><strong>{{ $t('assignment.comparison-nudge') }}</strong></dd>
    </template>
</dl>
