import { EVENT_ASSIGNMENT_CREATED, EVENT_PROPOSAL_ACCEPTED } from '../../constants/events';

const events = {
    [EVENT_ASSIGNMENT_CREATED]: (gtm) => {
        gtm.trackEvent({
            event: 'assignment-created'
        });
    },
    [EVENT_PROPOSAL_ACCEPTED]: (gtm) => {
        gtm.trackEvent({
            event: 'proposal-accepted'
        });
    }
};

export default {
    install(Vue) {
        Object.keys(events).forEach((key) => {
            Vue.$eventBus.$on(key, () => events[key](Vue.gtm));
        });
    }
};
