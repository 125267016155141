
<section class="user-profile-header">
    <div class="user-profile-header__avatar">
        <avatar
            :link-to-profile="false"
            size="large"
            :user="user"
            class="avatar--large"
        />
    </div>
    <div class="user-profile-header__personalia">
        <h3 data-margin="none">{{ user.full_name }}</h3>
        <div v-if="user.lawyer_title">
            {{ user.lawyer_title }}

            <supplier-info
                v-if="hasSupplier"
                inline
                :user="user"
            />
        </div>
        <supplier-info
            v-else-if="hasSupplier"
            :user="user"
        />
        <div v-if="user.city"><icon icon="location-pin" /> {{ user.city }}</div>
    </div>
    <div class="user-profile-header__actions" />
    <div class="user-profile-header__languages">
        <!--eslint-disable-next-line vue/no-v-html-->
        <span
            v-if="translatedLanguages"
            v-html="translatedLanguages.join(', ')"
        />
    </div>
    <div class="user-profile-header__tags">
        <tag
            v-for="(field) in user.fields_of_law"
            :key="field"
            class="tag--inline"
        >
            {{ $t(`fieldsOfLaw.${field}`) }}
        </tag>
    </div>
</section>
