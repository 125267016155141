'use strict';

import { clone } from 'lodash-es';
import CommunityApi from '../../../api/community';
import Loader from '~/services/loader/loading-service';
import UserApi from '~/api/users';
import { getLoggedInUser, isClient } from '~/helpers/user';

export default {
    fetchUser(context, id) {
        return UserApi.getUser(id).then((response) => {
            if (response.data) {
                context.commit('setUser', response.data);
            }
            return response.data;
        });
    },

    fetchPendingUsers(context) {
        // Only users with the client role can manage pending users.
        // The back-end shouldn't add them to the endpoint. This is just
        // a double-check to make sure this can never happen.
        const loggedInUser = getLoggedInUser();

        if (!isClient(loggedInUser)) {
            return false;
        }

        Loader.get('pending-users').start();

        UserApi.pendingUsers().then((response) => {
            if (response.data && response.data.data) {
                context.commit('pendingUsers', response.data.data);
            }

            Loader.get('pending-users').success();
        }).catch((err) => {
            Loader.get('pending-users').fail(err);
        });
    },

    fetchUsers(context) {
        Loader.get('users').start();

        UserApi.users().then((response) => {
            if (response.data && response.data.data) {
                context.commit('users', response.data.data);
            }

            Loader.get('users').success();
        }).catch((err) => {
            Loader.get('users').fail(err);
        });
    },

    fetchFilteredUsers(context) {
        const params = {},
            query = {
                search: context.rootGetters['search/searchQuery'],
                assignmentTypes: context.rootGetters['search/assignmentTypes'],
                lawyerTypes: context.rootGetters['search/lawyerTypes'],
                fieldsOfLaw: context.rootGetters['search/fieldsOfLaw'],
                businessLanguages: context.rootGetters['search/businessLanguages'],
                supplier: context.rootGetters['search/suppliers']
            };

        // ?search=term
        if (query.search) {
            params.search = query.search;
        }

        // ?assignment_types=a,b,c
        if (query.assignmentTypes) {
            params['filter[assignment_types]'] = query.assignmentTypes;
        }

        // ?lawyer_types=a,b,c
        if (query.lawyerTypes) {
            params['filter[lawyer_types]'] = query.lawyerTypes;
        }

        // ?fields_of_law=a,b,c
        if (query.fieldsOfLaw) {
            params['filter[fields_of_law]'] = query.fieldsOfLaw;
        }

        // ?business_languages=a,b,c
        if (query.businessLanguages) {
            params['filter[business_languages]'] = query.businessLanguages;
        }

        if (query.supplier) {
            params['filter[supplier]'] = query.supplier;
        }

        Loader.get('users').start();

        UserApi.users(params).then((response) => {
            if (response.data && response.data.data) {
                context.commit('filteredUsers', response.data.data);
            }

            Loader.get('users').success();
        }).catch((err) => {
            Loader.get('users').fail(err);
        });
    },

    selectLawyerOrSupplier(context, user) {
        if (!user || !user.id) {
            return false;
        }

        const selectedLawyerAndSupplierIds = clone(context.state.selectedLawyerAndSupplierIds),
            selectedLawyerAndSupplierIndex = selectedLawyerAndSupplierIds.indexOf(user.id);

        // Remove the lawyer from the array if it was already selected.
        if (selectedLawyerAndSupplierIndex !== -1) {
            selectedLawyerAndSupplierIds.splice(selectedLawyerAndSupplierIndex, 1);
        } else {
            selectedLawyerAndSupplierIds.push(user.id);
        }

        context.commit('selectedLawyerAndSupplierIds', selectedLawyerAndSupplierIds);
    },

    selectLawyersAndSuppliers({ commit }, ids) {
        commit('selectedLawyerAndSupplierIds', ids);
    },

    selectAllLawyersAndSuppliers(context) {
        context.commit('selectedLawyerAndSupplierIds', context.getters.lawyerAndSupplierIds);
    },

    deselectAllLawyersAndSuppliers(context) {
        context.commit('selectedLawyerAndSupplierIds', []);
    },

    acceptTerms(context) {
        CommunityApi.acceptTerms().then((response) => {
            context.commit('terms_accepted_at', response.data.terms_accepted_at);
        });
    }
};
