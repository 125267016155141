export default {
    namespaced: true,

    state: {
        activeModal: false,
        data: undefined
    },

    actions: {
        openModal(context, payload) {
            const name = typeof payload === 'string' ? payload : payload.name;

            context.commit('activeModal', name);

            if (payload.data !== undefined) {
                context.commit('data', payload.data);
            } else {
                context.commit('data', undefined);
            }
        },

        closeModal(context) {
            context.commit('activeModal', false);
            context.commit('data', undefined);
        }
    },

    getters: {
        isOpen: state => (modalName) => {
            return state.activeModal === modalName;
        },

        data(state) {
            return state.data;
        }
    },

    mutations: {
        activeModal(state, modalName) {
            state.activeModal = modalName;
        },

        data(state, data) {
            state.data = data;
        }
    }
};
