
<div
    class="notice"
    :class="{
        'notice--info': severity === 'info',
        'notice--success': severity === 'success',
        'notice--warning': severity === 'warning',
        'notice--danger': severity === 'danger'
    }"
>
    <slot />
</div>
