<template>
    <div
        class="activity-indicator"
        :data-active="isActive"
    />
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="less">
@import './activity-indicator.less';
</style>