
<nav
    v-if="filteredLinks && filteredLinks.length > 0"
    class="sub-navigation"
>
    <div
        v-if="!!$slots.header"
        class="sub-navigation__header"
    >
        <slot name="header" />
    </div>
    <div class="sub-navigation__container">
        <div class="sub-navigation__body">
            <ol :data-link-count="filteredLinks.length">
                <li
                    v-for="(link, index) in filteredLinks"
                    :key="index"
                >
                    <router-link
                        :to="{
                            name: link.routeName,
                            params: link.params || {}
                        }"
                        exact
                    >
                        {{ link.prettyName }}

                        <activity-indicator :is-active="link.isUnread" />
                    </router-link>
                </li>
            </ol>

            <div
                v-if="isActivePage"
                class="sub-navigation__underline"
                :data-active-link="activeRouteIndex + 1"
            />
        </div>
        <div
            v-if="$slots.subnavigationAside"
            class="sub-navigation__aside"
        >
            <slot name="subnavigationAside" />
        </div>
    </div>
</nav>
