<template>
    <div>
        <li
            v-if="proposal.tariff_type"
            class="meta-list__item"
        >
            <h3>{{ $t('proposalView.tariff_type') }}</h3>
            <time>{{ $t(`tariffTypes.${proposal.tariff_type}`) }}</time>
        </li>

        <!-- Hourly tariffs -->
        <li
            v-if="proposal.tariff_type === 'hourly' && proposal.tariff_hourly_billable"
            class="meta-list__item"
        >
            <h3>{{ $t('proposalView.tariff_hourly_billable') }}</h3>
            <p>{{ $n(centsToEuros(proposal.tariff_hourly_billable), 'currency') }}</p>
        </li>

        <li
            v-if="proposal.tariff_type === 'hourly' && proposal.tariff_hourly_interim"
            class="meta-list__item"
        >
            <h3>{{ $t('proposalView.tariff_hourly_interim') }}</h3>
            <p>{{ $n(centsToEuros(proposal.tariff_hourly_interim), 'currency') }}</p>
        </li>

        <li
            v-if="proposal.tariff_type === 'hourly' && proposal.tariff_hourly_litigation"
            class="meta-list__item"
        >
            <h3>{{ $t('proposalView.tariff_hourly_litigation') }}</h3>
            <p>{{ $n(centsToEuros(proposal.tariff_hourly_litigation), 'currency') }}</p>
        </li>

        <!-- Total tariff -->
        <li
            v-if="proposal.tariff_type === 'total' && proposal.tariff_total"
            class="meta-list__item"
        >
            <h3>{{ $t('proposalView.tariff_total') }}</h3>
            <p>{{ $n(centsToEuros(proposal.tariff_total), 'currency') }}</p>
        </li>
    </div>
</template>

<script>
export default {
    props: {
        proposal: {
            type: Object,
            required: true,
            default: null
        }
    }
};
</script>
