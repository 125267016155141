'use strict';

export default {
    user(state, user) {
        if (user) {
            state.user = Object.assign({}, state.user, user);
        } else {
            state.user = undefined;
        }
    },

    logOut(state) {
        state.loggedOut = true;
    },

    hasAccessToData(state, hasAccessToData) {
        state.hasAccessToData = hasAccessToData;
    },

    forceNewTerms(state, value) {
        state.forceNewTerms = value;
    }
};
