import Store from '~/store/store';

export default {
    /**
     * Returns the percentage which LawyerlinQ will get, based on the provided user.
     * - If the user was added by the client, the fee is lower
     * - If the user was added by LawyerlinQ, the fee will be higher.
     *
     * @param  {User} [lawyer] The lawyer to get the fee percentage of.
     * @return {Number} Percentage points
     */
    getLawyerlinqFeePercentage(lawyer) {
        const community = Store.getters['community/community'],
            source = lawyer && lawyer.source ? lawyer.source : null;

        return Number(source === 'client' ? community.tariff_source_client : community.tariff_source_llq);
    },

    /**
     * Calculate the total fee that LawyerlinQ will receive (in eurocents).
     *
     * @param  {String/Number} [tariff] Total tariff input by the lawyer.
     * @param  {User} [lawyer] The lawyer to get the fee percentage of
     * @param  {Boolean} [convertCentsToEuros] The lawyer inputs the tariff in euro's. This should be stored in the database as eurocents, so by default the tariff is converted to cents.
     * @return {Number} Total amount LawyerlinQ will receive as fee.
     */
    calculateLawyerlinqFee(tariff, lawyer, convertCentsToEuros = true) {
        return (this.convertTariff(tariff, convertCentsToEuros) / 100) * this.getLawyerlinqFeePercentage(lawyer);
    },

    /**
     * Calculate the lawyer's total profit, taking the LLQ fee into account.
     * Example:
     * 1. Lawyer inputs 10000 as the total tariff. This what the client will pay.
     * 2. User was added by LawyerlinQ, so a 12% fee is subtracted.
     * 3. The total lawyer profit is equal to: 10000 - (10000*0.12) = 8800
     *
     * @param  {String/Number} [tariff] Total tariff input by the lawyer.
     * @param  {User} [lawyer] The lawyer to get the fee percentage of
     * @param  {Boolean} [convertCentsToEuros] The lawyer inputs the tariff in euro's. This should be stored in the database as eurocents, so by default the tariff is converted to cents.
     * @return {Number} Total amount the lawyer will receive.
     */
    calculateTotalLawyerProfit(tariff, lawyer, convertCentsToEuros = true) {
        const convertedTariff = this.convertTariff(tariff, convertCentsToEuros);

        return convertedTariff - this.calculateLawyerlinqFee(convertedTariff, lawyer, false);
    },

    /**
     * Convert the provided tariff to an integer to work with.
     * - It's possible to convert the provided tariff (in euro's) to eurocents.
     *
     *
     * @param  {String/Integer} [tariff] The total tariff of the proposal, as set by the lawyer. This is the total price the client will pay if he accepts the proposal.
     * @param  {Boolean} [convertCentsToEuros] The lawyer inputs the tariff in euro's. This should be stored in the database as eurocents, so by default the tariff is converted to cents.
     * @return {Number} Converted tariff, as a number.
     */
    convertTariff(tariff, convertCentsToEuros = true) {
        return convertCentsToEuros ? Number(tariff) / 100 : Number(tariff);
    }
};
