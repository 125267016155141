import Vue from 'vue';

export default {
    me() {
        const include = [
            'roles',
            'permissions',
            'communities'
        ];

        if (window.appConfig.context === 'community') {
            include.push('supplier');
        }
        return Vue.axios.get('/v1.0/users/me', {
            params: {
                include
            }
        });
    },

    users(params = {}) {
        const defaultParams = {
            include: [
                'roles',
                'supplier'
            ],
            'filter[roles]': 'client,lawyer,supplier',
            limit: 1000
        };

        return Vue.axios.get('/v1.0/users', {
            params: Object.assign(params, defaultParams)
        });
    },

    getUser(id) {
        const defaultParams = {
            include: [
                'supplier'
            ]
        };

        return Vue.axios.get(`/v1.0/users/${id}`, {
            params: Object.assign(defaultParams)
        });
    },

    getNotes(id) {
        // eslint-disable-next-line camelcase
        return Vue.axios.get(`/v1.0/users/${id}/notes`, { params: { per_page: 100 } });
    },

    addNote(userId, note) {
        return Vue.axios.post(`/v1.0/users/${userId}/notes`, note);
    },

    lawyers(params = {}) {
        const defaultParams = {
            'filter[roles]': 'lawyer,supplier'
        };

        return Vue.axios.get('/v1.0/users', {
            params: Object.assign(params, defaultParams)
        });
    },

    pendingUsers() {
        return Vue.axios.get('/v1.0/users/pending', {
            params: {
                'filter[roles]': 'lawyer,supplier',
                limit: 100
            }
        });
    },

    acceptPendingUser(userId) {
        if (!userId) {
            return false;
        }

        return Vue.axios.post(`/v1.0/users/pending/${userId}`);
    },

    declinePendingUser(userId) {
        if (!userId) {
            return false;
        }

        return Vue.axios.delete(`/v1.0/users/pending/${userId}`);
    },

    updateProfile(userId, scope, data) {
        if (!userId || !scope || !data) {
            return false;
        }

        const endpoints = {
            profile: `/v1.0/users/${userId}/profile`,
            'contact-info': `/v1.0/users/${userId}/contact-info`,
            bio: `/v1.0/users/${userId}/bio`,
            discipline: `/v1.0/users/${userId}/discipline`,
            highlights: `/v1.0/users/${userId}/highlights`,
            timeline: `/v1.0/users/${userId}/timeline`
        };

        return Vue.axios.put(endpoints[scope], data, {
            params: {
                include: [
                    'roles',
                    'permissions'
                ]
            }
        });
    },

    inviteUser(data) {
        if (!data) {
            return false;
        }

        return Vue.axios.post('/v1.0/users/invite', data);
    },

    inviteGuest(expiration, email, assignmentId) {
        if (!expiration) {
            return false;
        }

        return Vue.axios.get('v1.0/users/guest/invite', {
            params: {
                expiration,
                email,
                assignmentId
            }
        });
    }
};
