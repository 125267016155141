
<transition
    name="modal"
    mode="out-in"
>
    <div
        v-if="modalOpen"
        :key="name"
        class="modal"
        :class="{
            'modal--unclosable': !allowClose
        }"
        :data-position="position"
        :data-size="size"
        :data-aside="!!$slots.aside"
        tabindex="0"
        @keydown.esc="closeModal"
    >
        <div class="modal__wrapper">
            <header class="modal__header">
                <div
                    v-if="allowClose"
                    class="modal__close"
                >
                    <button
                        class="btn btn--clean"
                        @click="closeModal"
                    >
                        <svg-icon icon="close" />
                    </button>
                </div>
            </header>
            <div class="modal__content-wrapper">
                <div class="modal__body">
                    <slot
                        v-if="modalOpen"
                        :modal-data="modalData"
                    />
                </div>
                <div
                    v-show="$slots.aside"
                    class="modal__aside"
                >
                    <slot
                        v-if="modalOpen"
                        name="aside"
                        :modal-data="modalData"
                    />
                </div>

                <footer
                    v-if="$slots.footer"
                    class="modal__footer"
                >
                    <slot name="footer" />
                </footer>
            </div>
        </div>

        <div
            class="modal__backdrop"
            @click="closeModal"
        />
    </div>
</transition>
