
<div class="checkbox">
    <label :for="id">
        <input
            :id="id"
            type="checkbox"
            :name="name"
            :value="value"
            :class="className"
            :required="required"
            :disabled="disabled"
            :checked="state"
            @change="onChange"
        >

        <span class="checkbox__custom" />

        <span
            v-if="!!$slots.default"
            class="checkbox__label"
        ><slot /></span>
    </label>
</div>
