<template>
    <div class="modal-content">
        <slot />
    </div>
</template>

<script>
'use strict';

export default {

};
</script>

<style lang="less">
@import './modal-content.less';
</style>
