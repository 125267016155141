'use strict';

import Vue from 'vue';

const status = {
    LOADING: 'loading',
    SUCCESS: 'success',
    FAIL: 'failed'
};

class Loader {
    constructor(name) {
        this.name = name;
        this.loaderVm = new Vue({
            data() {
                return {
                    status: undefined
                };
            },

            computed: {
                getStatus() {
                    return '';
                }
            }
        });
    }

    loading() {
        return this.loaderVm.status === status.LOADING;
    }

    failed() {
        return this.loaderVm.status === status.FAIL;
    }

    successful() {
        return this.loaderVm.status === status.SUCCESS;
    }

    start() {
        this.loaderVm.status = status.LOADING;
    }

    success() {
        this.loaderVm.status = status.SUCCESS;
    }

    fail() {
        this.loaderVm.status = status.FAIL;
    }
}

class LoadingService {
    constructor() {
        this.loaders = {};

        this.loadingServiceVm = new Vue({
            data() {
                return {
                    loaders: {}
                };
            }
        });
    }

    get(loaderName) {
        if (!this.loadingServiceVm.$data.loaders[loaderName]) {
            this.loadingServiceVm.$data.loaders[loaderName] = new Loader(loaderName);
        }

        return this.loadingServiceVm.$data.loaders[loaderName];
    }
}

export default new LoadingService();
