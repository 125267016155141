<template>
    <dn-modal
        name="pushed-proposal-modal"
        position="centered"
        size="medium"
    >
        <modal-content>
            <section class="text-center">
                <h2 v-if="action === 'accept'">{{ $t('modal.pushed-proposal-accept.modal-title', { name: user.full_name }) }}</h2>
                <h2 v-else-if="action === 'reject'">{{ $t('modal.pushed-proposal-reject.modal-title', { name: user.full_name }) }}</h2>
            </section>

            <section>
                <form class="form">
                    <form-field class="form-field--last">
                        <label
                            slot="label"
                            for="note"
                        >
                            <template v-if="action === 'accept'">
                                {{ $t('modal.pushed-proposal-accept.note-label', { name: user.full_name }) }}
                            </template>
                            <template v-else-if="action === 'reject'">
                                {{ $t('modal.pushed-proposal-reject.note-label', { name: user.full_name }) }}
                            </template>
                            <span class="form-field__label-notice">({{ $t('general.optional') }})</span>
                        </label>

                        <textarea
                            id="start"
                            v-model="note"
                            name="note"
                            :placeholder="$t('modal.pushed-proposal-reject.note-placeholder')"
                            resize="vertical"
                        />
                    </form-field>
                </form>
            </section>

            <section
                v-if="modalData && modalData.response"
                class="text-right"
            >
                <llq-button
                    class="btn btn--secondary"
                    :state="requestState"
                    @click.prevent="handleSubmit"
                >
                    <template v-if="action === 'accept'">
                        {{ $t('modal.pushed-proposal-accept.submit') }}
                    </template>
                    <template v-else-if="action === 'reject'">
                        {{ $t('modal.pushed-proposal-reject.submit') }}
                    </template>
                </llq-button>
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters, mapActions } from 'vuex';

import Bugsnag from '@bugsnag/js';
import LlqButton from '~/patterns/atoms/button/button.vue';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';
import updateResponse from '~/graphql/mutations/updateResponse.graphql';

export default {
    components: {
        LlqButton,
        DnModal,
        ModalContent,
        FormField
    },

    data() {
        return {
            note: null,
            requestState: null
        };
    },

    computed: {
        ...mapGetters({
            modalData: 'modal/data'
        }),

        user() {
            return this.modalData?.response?.user;
        },

        action() {
            return this.modalData?.action;
        }
    },

    watch: {
        modalData: {
            deep: true,
            handler() {
                this.note = null;
                this.requestState = null;
            }
        }
    },

    methods: {
        ...mapActions({ closeModal: 'modal/closeModal' }),

        async handleSubmit() {
            this.requestState = 'loading';

            let status;
            if (this.action === 'accept') {
                status = 'accepted';
            } else if (this.action === 'reject') {
                status = 'rejected';
            }

            try {
                await this.$executeGqlQuery(updateResponse, { id: this.modalData.response.id, status, note: this.note });
                this.requestState = 'success';
                this.$eventBus.$emit('response-updated');
                this.note = null;
                this.closeModal();
            } catch (error) {
                Bugsnag.notify(error);
                console.error(error);
                this.requestState = 'fail';
            }
        }
    },
};
</script>
