'use strict';

export default {
    threads(state, threads = []) {
        if (!threads) {
            return false;
        }

        state.threads = threads;
    }
};
