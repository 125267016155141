<template>
    <section class="section">
        <slot />
    </section>
</template>

<script>
'use strict';

export default {

};
</script>

<style lang="less">
@import './section.less';
</style>
