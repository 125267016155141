<template>
    <nav class="table-of-contents">
        <ul>
            <li
                v-for="(link, index) in links"
                :key="index"
                class="table-of-contents__item"
                :data-active="activeLinks.includes(link)"
            >
                <button
                    type="button"
                    @click="scrollToAnchor(link)"
                >
                    {{ link.label }}
                </button>
            </li>
        </ul>
    </nav>
</template>

<script>
'use strict';

import elementInViewport from '../../../helpers/elementInViewport';
import { scrollIntoView } from '../../../helpers/scroll';

export default {
    props: {
        links: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            activeLinks: []
        };
    },

    created() {
        this.handleScroll();
        // Ignore rule due to not using SSR for this project
        // eslint-disable-next-line
        window.addEventListener('scroll', this.handleScroll);
    },

    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            this.activeLinks = [];

            if (this.links) {
                for (const link of this.links) {
                    const elem = document.querySelector(`${link.target}`);

                    if (elem && elementInViewport(elem)) {
                        this.activeLinks.push(link);
                    }
                }
            }
        },

        scrollToAnchor(link) {
            if (!link) {
                return false;
            }

            scrollIntoView(document.querySelector(`${link.target}`));
        }
    }
};
</script>

<style lang="less">
@import './../../atoms/button/button.less';
@import './table-of-contents.less';
</style>
