<template>
    <div
        v-if="highlight"
        class="highlight"
        :data-editable="editable"
    >
        <slot name="label">
            <div
                v-if="sortable"
                class="highlight__sort"
            >
                <icon icon="draggable" />
            </div>
        </slot>

        <slot name="title">
            <div class="highlight__title">
                {{ highlight.title }} <template v-if="highlight.organisation">({{ highlight.organisation }})</template>
            </div>
        </slot>

        <button
            v-if="editable"
            type="button"
            class="highlight__action btn btn--clean"
            @click="clickEditHighlight"
        >
            <svg-icon icon="edit" />
        </button>
    </div>
</template>

<script>
'use strict';

import Icon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        Icon
    },

    props: {
        highlight: {
            type: Object
        },

        index: {
            type: Number
        },

        editable: {
            type: Boolean,
            default: false
        },

        sortable: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        end() {
            return this.highlight.end || this.$i18n.t('highlight.no-end');
        }
    },

    created() {
        this.$eventBus.$on('update-timelineItem', this.onUpdateHighlight);
    },

    methods: {
        clickEditHighlight() {
            this.$emit('click-edit', this.highlight, this.index);
        },

        onUpdateHighlight(updatedHighlight) {
            if (updatedHighlight.id !== this.highlight.id) {
                return false;
            }

            this.$emit('change', updatedHighlight);
        }
    }
};
</script>
