
<dn-modal
    name="conflict-check"
    position="centered"
    size="medium"
    class="conflict-check-modal"
    :allow-close="false"
>
    <modal-content>
        <template #default>
            <h1 class="conflict-check-modal__title">
                {{ $t('conflict-response.title') }}
            </h1>

            <p
                class="conflict-check-modal__intro"
            >
                {{ $t('conflict-response.content') }}
            </p>

            <p
                v-if="assignment.conflict_check"
                class="conflict-check-modal__content"
            >
                {{ assignment.conflict_check }}
            </p>

            <div class="conflict-check-modal__radio">
                <llq-radio
                    v-model="hasConflict"
                    class="radio--flex"
                    name="conflict-of-interest"
                    :value="true"
                >
                    {{ $t('conflict-response.conflict-of-interest') }}
                </llq-radio>

                <llq-radio
                    v-model="hasConflict"
                    class="radio--flex"
                    name="no-conflict"
                    :value="false"
                >
                    {{ $t('conflict-response.no-conflict') }}
                </llq-radio>
            </div>

            <llq-button
                class="btn--secondary"
                :disabled="typeof hasConflict === 'undefined' && requestState !== 'loading'"
                :state="requestState"
                @click="handleSubmit"
            >
                {{ $t('conflict-response.confirm') }}
            </llq-button>
        </template>
    </modal-content>
</dn-modal>
