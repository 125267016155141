<template>
    <dn-modal
        name="user-invite"
        position="centered"
        size="medium"
    >
        <modal-content>
            <section class="text-center">
                <h2>{{ $t('modal.user-invite.title') }}</h2>
            </section>

            <section>
                <form class="form">
                    <div class="column-grid form-field">
                        <form-field
                            :error-messages="errors.collect('role')"
                            columns-sm-min="4/8"
                        >
                            <label
                                slot="label"
                                for="role"
                            >
                                {{ $t('modal.user-invite.role.label') }}
                            </label>

                            <multiselect
                                v-model="formData.role"
                                :options="roleOptions"
                                :placeholder="$t('modal.user-invite.role.placeholder')"
                                :searchable="false"
                                :show-labels="false"
                                :multiple="false"
                                :custom-label="roleLabel"
                                :allow-empty="false"
                            />
                        </form-field>
                    </div>

                    <form-field
                        :error-messages="errors.collect('name')"
                    >
                        <label
                            slot="label"
                            for="name"
                        >
                            {{ $t('modal.user-invite.name.label') }}
                        </label>

                        <input
                            id="name"
                            v-model="formData.name"
                            v-validate="'required'"
                            type="text"
                            name="name"
                            :placeholder="$t('modal.user-invite.name.placeholder')"
                        >
                    </form-field>

                    <form-field
                        :error-messages="errors.collect('email')"
                    >
                        <label
                            slot="label"
                            for="email"
                        >
                            {{ $t('modal.user-invite.email.label') }}
                        </label>

                        <input
                            id="email"
                            v-model="formData.email"
                            v-validate="'required|email'"
                            type="email"
                            name="email"
                            :placeholder="$t('modal.user-invite.email.placeholder')"
                        >
                    </form-field>
                </form>
            </section>

            <section class="text-right">
                <v-button
                    class="btn btn--secondary btn--medium"
                    :state="requestState"
                    @click="inviteUser"
                >
                    {{ $t('modal.user-invite.submit') }}
                </v-button>
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import UserApi from '~/api/users';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';
import VButton from '~/patterns/atoms/button/button.vue';

export default {
    data() {
        return {
            requestState: undefined,
            formData: {
                name: null,
                email: null,
                role: 'lawyer'
            }
        };
    },

    computed: {
        ...mapGetters({
            roleOptions: 'enums/getRoles'
        })
    },

    methods: {
        inviteUser() {
            this.$validator.validateAll().then((noErrors) => {
                if (!noErrors) {
                    return;
                }

                this.requestState = 'loading';

                UserApi.inviteUser({
                    name: this.formData.name,
                    email: this.formData.email,
                    role: this.formData.role
                }).then(() => {
                    this.$router.push({ name: 'dashboard' });
                    this.$store.dispatch('modal/openModal', 'user-invited');
                    this.reset();
                    this.requestState = 'success';
                }).catch(() => {
                    this.requestState = 'fail';
                }).then(() => {
                    setTimeout(() => {
                        this.requestState = undefined;
                    }, 2000);
                });
            });
        },

        roleLabel(option) {
            return this.$i18n.t(`roles.${option}`);
        },

        reset() {
            this.formData.name = null;
            this.formData.email = null;
        }
    },

    components: {
        DnModal,
        ModalContent,
        FormField,
        Multiselect,
        VButton
    }
};
</script>
