
<transition name="nav-dropdown">
    <div
        v-show="!!activeTab"
        class="nav-dropdown"
        :data-active-content="currentTab"
        :style="{ height: activeTabHeight ? activeTabHeight + 'px' : null }"
    >
        <transition
            name="nav-tab"
            mode="out-in"
        >
            <div
                v-if="activeTab === 'notifications'"
                ref="notifications"
                key="notifications"
                class="nav-dropdown__tab"
                data-tab-name="notifications"
            >
                <notification-list />
            </div>

            <div
                v-if="activeTab === 'account'"
                ref="account"
                key="account"
                class="nav-dropdown__tab"
                data-tab-name="account"
            >
                <ul class="nav-dropdown__list">
                    <li>
                        <router-link :to="{ name: 'profile.edit' }">
                            {{ $t('navDropdown.links.profile') }}
                        </router-link>
                    </li>
                    <li>
                        <a :href="communityPortalUrl">
                            {{ $t('navDropdown.links.community-overview') }}

                            <activity-indicator :is-active="hasActivity" />
                        </a>
                    </li>

                    <li v-if="isLawyer() || isSupplier()">
                        <a
                            :href="timeTrackingUrl"
                            target="_blank"
                            rel="noopener"
                        >
                            {{ $t('navDropdown.links.time-tracking') }}
                        </a>
                    </li>

                    <li>
                        <router-link :to="{ name: 'logout' }">{{ $t('navDropdown.links.logout') }}</router-link>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</transition>
