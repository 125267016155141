
<!-- eslint-disable vue/no-v-html -->

<dn-modal
    name="select-participants"
    class="select-participants"
    size="large"
    data-aside="reverse"
>
    <template #aside>
        <community-filters
            class="select-participants__filters"
            @has-filters="updateFilters"
        />
    </template>

    <template #default>
        <load-status loader-name="users">
            <expert-list
                v-if="lawyersAndSuppliers.length"
                class="select-participants__list"
                :users="hasFilters ? filteredLawyersAndSuppliers : lawyersAndSuppliers"
                :force-selected-user-ids="forcedLawyerAndSupplierIds"
                :use-store="true"
                :allow-selection="true"
                :smaller-preview="true"
                :link-experts-to-new-tab="true"
                @selectNone="emitCurrentSelection"
                @selectAll="emitCurrentSelection"
                @selectLawyer="emitCurrentSelection"
            />

            <no-lawyers
                v-if="!lawyersAndSuppliers.length"
                class="select-participants__banner"
            />
        </load-status>
    </template>

    <template
        v-if="lawyersAndSuppliers.length"
        #footer
    >
        <cta-bar
            v-if="assignmentId"
            class="cta-bar--static"
        >
            <template v-slot:body>
                <div
                    v-html="$tc(
                        'modal.assignment-participants.add.cta-description',
                        addedSelectedLawyerAndSupplierIds.length,
                        { count: addedSelectedLawyerAndSupplierIds.length }
                    )"
                />
            </template>

            <template v-slot:cta>
                <v-button
                    class="btn--secondary"
                    :disabled="selectedLawyerCount <= 0"
                    :state="requestState"
                    @click="nextAdd"
                >
                    {{ $t('modal.assignment-participants.add.cta') }}
                </v-button>
            </template>
        </cta-bar>

        <cta-bar
            v-else
            class="cta-bar--static"
        >
            <template v-slot:body>
                <div
                    v-html="$tc(
                        'modal.assignment-participants.create.cta-description',
                        selectedLawyerCount,
                        { count: selectedLawyerCount }
                    )"
                />
            </template>

            <template v-slot:cta>
                <checkbox
                    id="save_favorite"
                    v-model="saveSelection"
                    :disabled="addedSelectedLawyerAndSupplierIds.length <= 0"
                    class="checkbox--inline"
                    name="save_favorite"
                >
                    {{ $t('modal.assignment-participants.create.favorite') }}
                </checkbox>
                <button
                    type="button"
                    class="btn btn--secondary"
                    :disabled="selectedLawyerCount <= 0"
                    @click="nextNew"
                >
                    {{ $t('modal.assignment-participants.create.cta') }}
                </button>
            </template>
        </cta-bar>
    </template>
</dn-modal>
