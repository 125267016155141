'use strict';

import { clone } from 'lodash-es';

export default {
    setNotesForUser(state, { userId, notes }) {
        state.notes[userId] = notes;
    },

    appendNotesForUser(state, note) {
        state.notes[note.user_id].unshift(note);
    },

    removeNoteForUser(state, { userId, id }) {
        // Ugly workarounds for reactivity, make pretty at your own peril.
        const newNotes = clone(state.notes);
        newNotes[userId] = newNotes[userId].filter(item => item.id !== id);
        state.notes = newNotes;
    }
};
