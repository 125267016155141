<template>
    <div
        class="panel"
        :data-collapsible="collapsible"
        :data-collapsed="collapsed"
    >
        <div
            v-if="title || !!$slots.title"
            class="panel__header"
            @click="collapsible ? collapsed = (collapsed !== true) : false"
        >
            <svg-icon
                v-if="collapsible"
                icon="chevron-up"
                class="panel__collapse"
            />

            <slot name="title">
                <h2 class="panel__title">{{ title }}</h2>
            </slot>
        </div>

        <transition name="expand">
            <div
                v-if="!!$slots.default"
                v-show="!collapsed"
                ref="body"
                class="panel__body"
                :style="{ height: `${bodyHeight}px` }"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<script>
'use strict';

import Vue from 'vue';

export default {
    props: {
        title: {
            type: String,
            default: null,
            required: false
        },
        collapsible: {
            type: Boolean,
            default: false,
            required: false
        }
    },

    data() {
        return {
            collapsed: false,
            initialHeight: 0
        };
    },

    computed: {
        bodyHeight() {
            if (!this.collapsible) {
                return 'auto';
            }
            if (this.$refs.body && this.collapsed) {
                return 0;
            }
            return this.initialHeight;
        }
    },

    mounted() {
        if (this.$refs.body) {
            // Store the height of the body before collapse, so we can animate it in CSS.
            this.initialHeight = this.$refs.body.scrollHeight;
            // Next tick, set the collapsed state
            Vue.nextTick(() => {
                this.collapsed = this.collapsible;
            });
        }
    }
};
</script>

<style lang="less">
@import './panel.less';
</style>
