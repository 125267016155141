'use strict';

import { find } from 'lodash-es';

export default {
    threads(state) {
        if (!state.threads) {
            return [];
        }

        return state.threads;
    },

    threadById: (state, getters) => (id) => {
        const foundThread = find(getters.threads, { id });

        return foundThread !== -1 ? foundThread : false;
    }
};
