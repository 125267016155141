
<div class="banner">
    <div
        v-if="icon"
        class="banner__image"
    >
        <svg-icon :icon="icon" />
    </div>

    <div class="banner__content">
        <h2
            v-if="title"
            class="banner__title"
        >
            {{ title }}
        </h2>
        <h3
            v-if="subtitle"
            class="banner__subtitle"
        >
            {{ subtitle }}
        </h3>
        <slot name="content" />
    </div>

    <div class="banner__actions">
        <slot />
    </div>
</div>
