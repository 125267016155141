'use strict';

import UserApi from '~/api/users';
import NoteApi from '~/api/notes';

export default {
    fetchNotesForUser(context, userId) {
        return UserApi.getNotes(userId).then((response) => {
            if (response.data && response.data.data) {
                context.commit('setNotesForUser', { userId, notes: response.data.data });
            } else {
                context.commit('setNotesForUser', { userId, notes: [] });
            }
        });
    },

    addNote(context, { userId, note }) {
        return UserApi.addNote(userId, note).then((response) => {
            if (response.data) {
                context.commit('appendNotesForUser', response.data);
            }
        });
    },

    deleteNote(context, { userId, id }) {
        return NoteApi.deleteNote(id).then(() => {
            context.commit('removeNoteForUser', { userId, id });
        });
    }
};
