<template>
    <div class="confirmed-message">
        <svg-icon
            icon="check"
            class="icon--success"
        /><span class="confirmed-message__text"><slot /></span>
    </div>
</template>

<script>
'use strict';

export default {

};
</script>

<style lang="less">
@import './confirmed-message.less';
</style>
