'use strict';

import { clone, findIndex } from 'lodash-es';
import ThreadApi from '~/api/threads';

export default {
    /**
     * API mutations
     */
    fetchById(context, id) {
        ThreadApi.getById(id).then((response) => {
            context.dispatch('addThread', response.data);
        }).catch((err) => {
            console.error('threads/fetchById failed:', err);
        });
    },

    /**
     * Complex store mutations
     */
    addThread(context, thread) {
        if (!thread) {
            return false;
        }

        const threads = clone(context.state.threads),
            threadIndex = findIndex(threads, { id: thread.id });

        if (threadIndex !== -1) {
            threads[threadIndex] = thread;
        } else {
            threads.push(thread);
        }

        context.commit('threads', threads);
    },

    addMessageToThread(context, payload) {
        const message = payload.message,
            threadId = payload.threadId;

        if (!message || !threadId) {
            return false;
        }

        const thread = context.getters.threadById(threadId);

        if (!thread) {
            return;
        }

        const clonedThread = clone(thread),
            messages = clonedThread.messages ? clone(clonedThread.messages) : [];

        messages.push(message);
        clonedThread.messages = messages;

        context.dispatch('addThread', clonedThread);
    }
};
