'use strict';

import Vue from 'vue';
import UserApi from '~/api/users';
import CommunityApi from '~/api/community';

export default {
    fetchUser(context) {
        return UserApi.me().then((response) => {
            if (!response || !response.data) {
                return false;
            }

            context.commit('hasAccessToData', true);
            context.commit('user', response.data);
        }).catch(({ response }) => {
            if (response) {
                switch (response.status) {
                case 404:
                case 403:
                    Vue.$auth.accessDenied(response);
                    break;
                case 401:
                    Vue.$auth.setAuthenticated(false);
                    break;
                }
            }
        });
    },

    acceptTerms(context) {
        return CommunityApi.acceptTerms().then((response) => {
            context.commit('user', response.data);
            context.commit('forceNewTerms', false);
        });
    },

    /**
     * Force new terms to be accepted (eg. when getting a 403 from the API)
     * @param context
     */
    forceNewTerms(context) {
        context.commit('forceNewTerms', true);
    }
};
