import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { parseISO, format, formatDistance, formatDistanceStrict, differenceInMinutes, isToday, isYesterday, compareAsc } from 'date-fns';

import dateNl from 'date-fns/locale/nl';
import dateEn from 'date-fns/locale/en-US';
import nl from './locales/nl.json';
import en from './locales/en.json';

Vue.use(VueI18n);

const messages = {
        nl,
        en
    },

    locale = window.appConfig.language || 'en',

    i18n = new VueI18n({
        locale,
        messages,

        numberFormats: {
            nl: {
                currency: {
                    style: 'currency',
                    currency: 'EUR'
                }
            },
            en: {
                currency: {
                    style: 'currency',
                    currency: 'EUR'
                }
            }
        }
    }),

    dateLocales = {
        en: dateEn,
        nl: dateNl
    };

function convertToDateObject(date) {
    if (date instanceof Date) {
        return date;
    }
    return parseISO(date);
}

Vue.filter('dateFormat', (date, formatting) => {
    const dateObj = convertToDateObject(date);

    return format(dateObj, formatting, {
        locale: dateLocales[i18n.locale]
    });
});

Vue.filter('time', (date) => {
    const dateObj = convertToDateObject(date);

    return format(dateObj, i18n.t('date-format.time'), {
        locale: dateLocales[i18n.locale]
    });
});

Vue.filter('humanDate', (date) => {
    const dateObj = convertToDateObject(date);

    if (isToday(dateObj)) {
        return format(dateObj, i18n.t('date-format.time'), {
            locale: dateLocales[i18n.locale]
        });
    }

    if (isYesterday(dateObj)) {
        return i18n.t('date-format.yesterday');
    }

    return format(dateObj, i18n.t('date-format.date-short'), {
        locale: dateLocales[i18n.locale]
    });
});

Vue.filter('humanDateTime', (date, options) => {
    const dateObj = convertToDateObject(date);

    if (isToday(dateObj)) {
        return format(dateObj, i18n.t('date-format.today-time'), {
            locale: dateLocales[i18n.locale]
        });
    }

    if (isYesterday(dateObj)) {
        return format(dateObj, i18n.t('date-format.yesterday-time'), {
            locale: dateLocales[i18n.locale]
        });
    }

    return format(dateObj, i18n.t('date-format.datetime'), {
        locale: dateLocales[i18n.locale]
    });
});

Vue.filter('friendlyHumanDate', (date, capitalize = true) => {
    const dateObj = convertToDateObject(date);
    let str;

    if (differenceInMinutes(new Date(), dateObj) < 10) {
        str = i18n.t('date-format.just-now');
    } else {
        str = formatDistance(
            dateObj,
            new Date(),
            {
                addSuffix: true,
                locale: dateLocales[i18n.locale]
            }
        );
    }

    // Capitalize first letter
    if (capitalize) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
});

Vue.filter('timeRemaining', (date) => {
    if (date === null) {
        return;
    }

    const dateObj = convertToDateObject(date);

    // if time is in the past, return null
    if (compareAsc(dateObj, new Date()) === -1){
        return null;
    }

    let str;

    str = formatDistanceStrict(
        dateObj,
        new Date(),
        { locale: dateLocales[i18n.locale] }
    );

    return str;
});

export default i18n;
