'use strict';

import { clone, findIndex } from 'lodash-es';

export default {
    assignments(state, assignments = []) {
        if (!assignments) {
            return false;
        }

        state.assignments = assignments;
    },

    assignment(state, assignment) {
        if (!assignment) {
            return false;
        }

        const assignments = clone(state.assignments),
            assignmentIndex = findIndex(assignments, { id: assignment.id });

        if (assignmentIndex !== -1) {
            // Merge in new values, keep any ones not included.
            assignments[assignmentIndex] = { ...assignments[assignmentIndex], ...assignment };
        } else {
            assignments.push(assignment);
        }

        state.assignments = assignments;
    },

    deleteAssignment(state, assignmentId) {
        const assignments = clone(state.assignments),
            assignmentIndex = findIndex(assignments, { id: assignmentId });

        if (assignmentIndex === -1 || !assignmentId) {
            return false;
        }

        assignments.splice(assignmentIndex, 1);

        state.assignments = assignments;
    }
};
