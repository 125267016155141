<template>
    <div class="default-view">
        <div class="default-view__body">
            <slot />
        </div>

        <div class="default-view__footer">
            <page-footer />
        </div>
    </div>
</template>

<script>
'use strict';

import PageFooter from '~/patterns/organisms/footer/footer.vue';

export default {
    components: {
        PageFooter
    }
};
</script>

<style lang="less">
@import './default-view.less';
</style>
