<template>
    <selectable-list
        :selectable-items="users"
        :allow-selection="allowSelection"
        :show-header="showHeader"
        :force-selected-ids="forceSelectedUserIds"
        :preselect-ids="preselectIds"
        @selectAll="selectAllLawyersAndSuppliers"
        @deselectAll="deselectAllLawyersAndSuppliers"
        @input="passThroughInput"
        @toggle="maybeToggleInStore"
    >
        <template #title>
            {{ $tc('selectableList.expert-list.title', users.length, { count: users.length }) }}
        </template>

        <template #header_actions>
            <slot name="header_actions" />
        </template>

        <template #preview="{ item }">
            <expert-preview
                :expert="item"
                :class="{ 'expert-preview--select': !!smallerPreview }"
                :link-to-new-tab="linkExpertsToNewTab"
            />
        </template>

        <template #cta>
            <slot name="cta" />
        </template>
    </selectable-list>
</template>

<script>
'use strict';

import SelectableList from '../selectable-list.vue';
import ExpertPreview from '~/patterns/molecules/expert-preview/expert-preview.vue';

export default {
    components: {
        SelectableList,
        ExpertPreview
    },

    props: {
        users: {
            type: Array,
            required: true
        },

        forceSelectedUserIds: {
            type: Array,
            default() {
                return [];
            }
        },

        allowSelection: {
            type: Boolean,
            default: false
        },

        useStore: {
            type: Boolean,
            default: false
        },

        showHeader: {
            type: Boolean,
            default: true
        },

        smallerPreview: {
            type: Boolean,
            default: false
        },

        linkExpertsToNewTab: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        preselectIds() {
            return this.$store.getters['users/selectedLawyerAndSupplierIds'];
        }
    },

    methods: {
        deselectAllLawyersAndSuppliers() {
            if (this.useStore) {
                this.$store.dispatch('users/deselectAllLawyersAndSuppliers');
                this.$emit('selectNone');
            }
        },

        selectAllLawyersAndSuppliers() {
            if (this.useStore) {
                this.$store.dispatch('users/selectAllLawyersAndSuppliers');
                this.$emit('selectAll');
            }
        },

        passThroughInput(data) {
            this.$emit('input', data);
        },

        maybeToggleInStore(item) {
            if (this.useStore) {
                this.$store.dispatch('users/selectLawyerOrSupplier', item);
                this.$emit('selectLawyer', item);
            }
        }
    }
};
</script>
