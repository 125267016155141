'use strict';

export default {
    community(state, community) {
        state.sidebar.community = community;
    },

    terms(state, terms) {
        state.terms = terms;
    }
};
