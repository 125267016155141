const eitherIsRequired = 'You have to provide either an attachment, a message, or both';

export default {
    custom: {
        message: {
            required: eitherIsRequired
        },
        attachment: {
            required: eitherIsRequired
        }
    }
};
