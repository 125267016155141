<template>
    <transition
        name="modal"
        mode="out-in"
    >
        <div
            v-if="modalOpen"
            :key="name"
            class="modal"
            :class="{
                'modal--unclosable': !allowClose
            }"
            :data-position="position"
            :data-size="size"
            :data-aside="!!$slots.aside"
            tabindex="0"
            @keydown.esc="closeModal"
        >
            <div class="modal__wrapper">
                <header class="modal__header">
                    <div
                        v-if="allowClose"
                        class="modal__close"
                    >
                        <button
                            class="btn btn--clean"
                            @click="closeModal"
                        >
                            <svg-icon icon="close" />
                        </button>
                    </div>
                </header>
                <div class="modal__content-wrapper">
                    <div class="modal__body">
                        <slot
                            v-if="modalOpen"
                            :modal-data="modalData"
                        />
                    </div>
                    <div
                        v-show="$slots.aside"
                        class="modal__aside"
                    >
                        <slot
                            v-if="modalOpen"
                            name="aside"
                            :modal-data="modalData"
                        />
                    </div>

                    <footer
                        v-if="$slots.footer"
                        class="modal__footer"
                    >
                        <slot name="footer" />
                    </footer>
                </div>
            </div>

            <div
                class="modal__backdrop"
                @click="closeModal"
            />
        </div>
    </transition>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';

export default {
    props: {
        name: {
            type: String,
            required: true
        },
        position: {
            type: String,
            default: 'centered'
        },
        size: {
            type: String,
            default: 'small'
        },
        forceOpen: {
            type: Boolean,
            default: false
        },
        allowClose: {
            type: Boolean,
            default: true
        },
        closePrompt: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen',
            modalData: 'modal/data'
        }),

        modalOpen() {
            return this.isOpen(this.name) || this.forceOpen;
        }
    },

    watch: {
        modalOpen: {
            handler(newValue) {
                if (newValue === true) {
                    this.$gtm.trackEvent({
                        category: 'modal',
                        action: 'open',
                        label: this.name,
                        noninteraction: false
                    });

                    this.$nextTick(() => {
                        this.$el.focus();
                    });
                }
            },
            immediate: true
        }
    },

    methods: {
        closeModal() {
            const confirmed = this.closePrompt ? confirm(this.closePrompt) : true;

            if (this.allowClose && confirmed) {
                this.$store.dispatch('modal/closeModal');
            }
        },

        keyEventListener(event) {
            // Event.key === Esc is IE/Edge specific!
            if (event.key === 'Escape' || event.key === 'Esc') {
                this.closeModal();
            }
        }
    }
};
</script>

<style lang="less">
@import './modal.less';
</style>
