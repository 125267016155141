<template>
    <div class="user-profile">
        <loading-indicator v-if="loading" />

        <template v-if="!loading">
            <llq-section class="user-profile__section">
                <user-profile-header :user="user" />
            </llq-section>

            <llq-section
                v-if="hasContactFieldsSet"
                class="user-profile__section"
            >
                <h3>{{ $t('userProfile.contact.heading') }}</h3>
                <labelled-list :items="contactItems">
                    <template #value="{ item }">
                        <a
                            v-if="item.type === 'url'"
                            :href="item.value"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {{ item.value }}
                        </a>
                        <a
                            v-else-if="item.type === 'mailto'"
                            :href="`mailto:${item.value}`"
                        >
                            {{ item.value }}
                        </a>
                        <a
                            v-else-if="item.type === 'tel'"
                            :href="`tel:${item.value}`"
                        >
                            {{ item.value }}
                        </a>
                        <span v-else>{{ item.value }}</span>
                    </template>
                </labelled-list>
            </llq-section>

            <llq-section
                v-if="discipline && discipline.length"
                class="user-profile__section"
            >
                <h3>{{ $t('profileView.title.discipline') }}</h3>
                <labelled-list :items="discipline" />
            </llq-section>

            <llq-section
                v-if="user.bio"
                class="user-profile__section"
            >
                <h3>{{ $t('userProfile.bio.heading') }}</h3>
                <p>{{ user.bio }}</p>
            </llq-section>

            <llq-section
                v-if="user.highlights && user.highlights.length"
                class="user-profile__section"
            >
                <h3>{{ $t('userProfile.highlights.heading') }}</h3>
                <user-highlight
                    v-for="(highlight, index) in user.highlights"
                    :key="index"
                    :highlight="highlight"
                    :user-id="user.id"
                    :index="index"
                />
            </llq-section>

            <llq-section
                v-if="user.timeline"
                class="user-profile__section"
            >
                <h3>{{ $t('userProfile.timeline.heading') }}</h3>
                <labelled-list :items="user.timeline">
                    <template #label="{ item }">
                        {{ item.start }}<template v-if="item.end"> - {{ item.end }}</template>
                    </template>
                    <template #value="{ item }">{{ item.title }}, {{ item.organisation }}</template>
                </labelled-list>
            </llq-section>
        </template>
    </div>
</template>

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import LoadingIndicator from '~/patterns/molecules/loading-indicator/loading-indicator.vue';
import Section from '~/patterns/organisms/section/section.vue';
import UserHighlight from '~/patterns/atoms/user-highlight/user-highlight.vue';
import UserProfileHeader from '~/patterns/molecules/user-profile-header/user-profile-header.vue';
import LabelledList from '~/patterns/atoms/labelled-list/labelled-list.vue';

export default {
    components: { LoadingIndicator, llqSection: Section, UserProfileHeader, LabelledList, UserHighlight },

    props: ['id'],

    data() {
        return {
            loading: false
        };
    },

    computed: {
        ...mapGetters({ user: 'users/user' }),

        hasContactFieldsSet() {
            return this.user.linkedin_url || this.user.contact_email ||
                    this.user.phone_number || this.user.website_url;
        },

        contactItems() {
            const contactItems = [];

            if (this.user.linkedin_url) {
                contactItems.push({
                    type: 'url',
                    label: this.$t('userProfile.contact.linkedin_url'),
                    value: this.user.linkedin_url
                });
            }

            if (this.user.contact_email) {
                contactItems.push({
                    type: 'mailto',
                    label: this.$t('userProfile.contact.contact_email'),
                    value: this.user.contact_email
                });
            }

            if (this.user.phone_number) {
                contactItems.push({
                    type: 'tel',
                    label: this.$t('userProfile.contact.phone_number'),
                    value: this.user.phone_number
                });
            }

            if (this.user.website_url) {
                contactItems.push({
                    type: 'url',
                    label: this.$t('userProfile.contact.website_url'),
                    value: this.user.website_url
                });
            }

            return contactItems;
        },

        discipline() {
            const discipline = [];

            if (this.user.lawyer_types && this.user.lawyer_types.length) {
                discipline.push({
                    label: this.$t('profileView.lawyer_types.label'),
                    value: this.user.lawyer_types.map(value => this.$t(`lawyerTypes.${value}`)).join(', ')
                });
            }
            if (this.user.assignment_types && this.user.assignment_types.length) {
                discipline.push({
                    label: this.$t('profileView.assignment_types.label'),
                    value: this.user.assignment_types.map(value => this.$t(`assignmentTypes.${value}`)).join(', ')
                });
            }
            if (this.user.specialisations && this.user.specialisations.length) {
                discipline.push({
                    label: this.$t('profileView.specialisations.label'),
                    value: this.user.specialisations.map(value => value.name).join(', ')
                });
            }
            if (this.user.domain_expertises && this.user.domain_expertises.length) {
                discipline.push({
                    label: this.$t('profileView.domain_expertises.label'),
                    value: this.user.domain_expertises.map(value => value.name).join(', ')
                });
            }

            return discipline;
        }
    },

    watch: {
        id() {
            this.loadUser();
        }
    },

    created() {
        this.loadUser().then((user) => {
            this.$gtm.trackEvent({
                category: 'user-profile',
                action: 'open',
                label: user ? user.full_name : 'Unknown',
                noninteraction: false
            });
        });
    },

    methods: {
        ...mapActions({ fetchUser: 'users/fetchUser', addToast: 'toast/add' }),

        loadUser() {
            this.loading = true;

            return this.fetchUser(this.id).then((result) => {
                this.loading = false;
                return result;
            }).catch(() => {
                this.loading = false;
                this.addToast('Something went wrong when trying to fetch the profile.');
                return null;
            });
        }
    }
};
</script>

<style lang="less">
@import './user-profile.less';
</style>
