
<dn-modal
    v-if="mode"
    name="proposal-dialog"
    size="medium"
    position="centered"
>
    <modal-content>
        <section>
            <h1>
                {{ $t(`modal.${mode}-proposal-dialog.title`) }}
            </h1>
            <p>
                {{ $t(`modal.${mode}-proposal-dialog.description`) }}
            </p>

            <p>
                {{ $t(`modal.${mode}-proposal-dialog.confirmation`) }}
            </p>

            <p class="text-right">
                <v-button
                    class="btn--inline"
                    @click="closeModal"
                >
                    {{ $t(`modal.${mode}-proposal-dialog.cancel`) }}
                </v-button>

                <v-button
                    class="btn--secondary btn--inline"
                    @click="submit"
                >
                    {{ $t(`modal.${mode}-proposal-dialog.continue`) }}
                </v-button>
            </p>
        </section>
    </modal-content>
</dn-modal>
