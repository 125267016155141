
<div
    class="form-field"
    :class="{'form-field--invalid': hasErrors}"
    :icon-position="!!$slots.icon ? iconPosition : 'none'"
>
    <div
        v-if="!!$slots.label"
        class="form-field__label"
    >
        <slot name="label" />
        <abbr
            v-if="required"
            class="form-field__required"
            title="required"
        >*</abbr>
    </div>

    <div
        v-if="!!$slots.description"
        class="form-field__description"
    >
        <slot name="description" />
    </div>

    <div class="form-field__input-wrapper">
        <slot />

        <div
            v-if="!!$slots.icon"
            class="form-field__input-action"
        >
            <div>
                <slot name="icon" />
            </div>
        </div>
    </div>

    <div
        v-if="!!$slots.caption"
        class="form-field__caption"
    >
        <slot name="caption" />
    </div>

    <div
        v-if="errorMessages && errorMessages.length > 0"
        class="form-field__errors"
    >
        <ul class="list--clean">
            <li
                v-for="(error, index) in errorMessages"
                :key="index"
            >
                {{ error }}
            </li>
        </ul>
    </div>
</div>
