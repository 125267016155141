
<dn-modal
    name="pushed-proposal-modal"
    position="centered"
    size="medium"
>
    <modal-content>
        <section class="text-center">
            <h2 v-if="action === 'accept'">{{ $t('modal.pushed-proposal-accept.modal-title', { name: user.full_name }) }}</h2>
            <h2 v-else-if="action === 'reject'">{{ $t('modal.pushed-proposal-reject.modal-title', { name: user.full_name }) }}</h2>
        </section>

        <section>
            <form class="form">
                <form-field class="form-field--last">
                    <label
                        slot="label"
                        for="note"
                    >
                        <template v-if="action === 'accept'">
                            {{ $t('modal.pushed-proposal-accept.note-label', { name: user.full_name }) }}
                        </template>
                        <template v-else-if="action === 'reject'">
                            {{ $t('modal.pushed-proposal-reject.note-label', { name: user.full_name }) }}
                        </template>
                        <span class="form-field__label-notice">({{ $t('general.optional') }})</span>
                    </label>

                    <textarea
                        id="start"
                        v-model="note"
                        name="note"
                        :placeholder="$t('modal.pushed-proposal-reject.note-placeholder')"
                        resize="vertical"
                    />
                </form-field>
            </form>
        </section>

        <section
            v-if="modalData && modalData.response"
            class="text-right"
        >
            <llq-button
                class="btn btn--secondary"
                :state="requestState"
                @click.prevent="handleSubmit"
            >
                <template v-if="action === 'accept'">
                    {{ $t('modal.pushed-proposal-accept.submit') }}
                </template>
                <template v-else-if="action === 'reject'">
                    {{ $t('modal.pushed-proposal-reject.submit') }}
                </template>
            </llq-button>
        </section>
    </modal-content>
</dn-modal>
