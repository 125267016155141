
<div class="default-view">
    <div class="default-view__body">
        <slot />
    </div>

    <div class="default-view__footer">
        <page-footer />
    </div>
</div>
