import Vue from 'vue';

export default {
    getById(id) {
        return Vue.axios.get(`/v1.0/threads/${id}`, {
            params: {
                include: [
                    'messages',
                    'messages.assignment',
                    'messages.proposal',
                    'messages.media',
                    'messages.user'
                ]
            }
        });
    }
};
