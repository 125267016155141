import Vue from 'vue';

export default {
    create(message) {
        return Vue.axios.post('/v1.0/messages', message);
    },

    getMedia({ messageId, threadId, mediaId }) {
        return Vue.axios.get(`/v1.0/messages/${messageId}/${threadId}/${mediaId}/download`);
    }
};
