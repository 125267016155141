<template>
    <dn-modal
        name="user-invited"
        position="centered"
        size="small"
    >
        <modal-content class="modal-content--centered">
            <h1>{{ $t('modal.user-invited.title') }}</h1>

            <p>{{ $t('modal.user-invited.description') }}</p>

            <p>
                <button
                    class="btn btn--secondary"
                    @click="$store.dispatch('modal/closeModal');"
                >
                    {{ $t('modal.user-invited.cta') }}
                </button>
            </p>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';

export default {
    components: {
        DnModal,
        ModalContent
    }
};
</script>
