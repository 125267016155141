<template>
    <transition
        name="fade"
        mode="out-in"
    >
        <div
            v-if="loader.successful()"
            key="content"
        >
            <slot />
        </div>

        <loading-indicator
            v-if="loader.loading()"
            key="loading"
        />
        <div
            v-if="loader.failed()"
            key="failed"
            class="load-status__error"
        >
            <h4 class="text-center">{{ $t('loadStatus.failed') }}</h4>
        </div>
    </transition>
</template>

<script>
'use strict';

import LoadingIndicator from '~/patterns/molecules/loading-indicator/loading-indicator.vue';

export default {

    components: {
        LoadingIndicator
    },
    props: {
        loaderName: {
            required: true,
            type: String
        }
    },

    computed: {
        loader() {
            return this.$loader.get(this.loaderName);
        }
    }
};
</script>

<style lang="less">
@import './load-status.less';
</style>
