<template>
    <div id="page">
        <toasts />

        <navigation />

        <page-header-main />

        <transition
            name="subnav"
            mode="out-in"
        >
            <sub-navigation-main
                v-if="subnav === 'main'"
                key="main"
            />
        </transition>

        <main class="layout">
            <transition name="page">
                <router-view
                    v-if="(termsAccepted && userIsLoggedInLockedAndLoaded) || $route.meta.noAuthRequired"
                    class="layout__view"
                />
            </transition>
        </main>

        <transition name="fade-absolute">
            <page-loader v-if="loading" />
        </transition>

        <accept-terms-modal
            v-if="userIsLoggedInLockedAndLoaded && !termsAccepted"
            :modal-open="!termsAccepted && !loading"
        />
        <user-profile-modal />

        <create-proposal-modal />

        <proposal-dialog-modal />
        <assignment-created-modal />
        <proposal-accepted-modal />
        <proposal-accept-dialog-modal v-if="isAdmin() || isClient() || isCommunityManager()" />
        <timeline-edit-modal />
        <timeline-add-modal />

        <assignment-response-modal />
        <assignment-add-follower-modal />
        <assignment-push-modal v-if="isAdmin() || isClient() || isCommunityManager()" />
        <conflict-check-modal />
        <select-participants-modal />
        <save-user-selection-modal />
        <proposal-preview-modal />
        <pushed-proposal-modal v-if="isAdmin() || isClient() || isCommunityManager()" />
        <proposals-share-modal v-if="isAdmin() || isClient() || isCommunityManager()" />
        <proposal-created-modal />

        <user-invite-modal v-if="isClient()" />
        <user-invited-modal v-if="isClient()" />
        <broadcast-modal v-if="isClient()" />
        <select-template-modal v-if="isAdmin() || isClient()" />
        <tender-rounds-modal v-if="isClient()" />
    </div>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';

import toasts from '~/patterns/molecules/toasts/toasts.vue';
import Navigation from '~/patterns/organisms/navigation/navigation.vue';
import PageHeaderMain from '~/patterns/molecules/page-header/presets/main.vue';
import PageLoader from '~/patterns/molecules/page-loader/page-loader.vue';

import SubNavigationMain from '~/patterns/molecules/sub-navigation/presets/main.vue';

import AssignmentCreatedModal from '~/patterns/organisms/modal/presets/assignment-created-modal.vue';
import AssignmentAddFollowerModal from '~/patterns/organisms/modal/presets/assignment-add-follower-modal.vue';
import AssignmentPushModal from '~/patterns/organisms/modal/presets/assignment-push-modal.vue';
import AssignmentResponseModal from '~/patterns/organisms/modal/presets/assignment-response-modal.vue';
import ConflictCheckModal from '~/patterns/organisms/modal/presets/conflict-check-modal.vue';
import BroadcastModal from '~/patterns/organisms/modal/presets/broadcast-modal.vue';
import ProposalAcceptedModal from '~/patterns/organisms/modal/presets/proposal-created-modal.vue';
import ProposalCreatedModal from '~/patterns/organisms/modal/presets/proposal-accepted-modal.vue';
import ProposalAcceptDialogModal from '~/patterns/organisms/modal/presets/proposal-accept-dialog-modal.vue';
import TimelineEditModal from '~/patterns/organisms/modal/presets/timeline-edit-modal.vue';
import TimelineAddModal from '~/patterns/organisms/modal/presets/timeline-add-modal.vue';
import AcceptTermsModal from '~/patterns/organisms/modal/presets/accept-terms-modal.vue';
import CreateProposalModal from '~/patterns/organisms/modal/presets/create-proposal-modal.vue';
import ProposalDialogModal from '~/patterns/organisms/modal/presets/proposal-dialog-modal.vue';
import UserProfileModal from '~/patterns/organisms/modal/presets/user-profile-modal.vue';
import UserInviteModal from '~/patterns/organisms/modal/presets/user-invite-modal.vue';
import UserInvitedModal from '~/patterns/organisms/modal/presets/user-invited-modal.vue';
import SelectParticipantsModal from '~/patterns/organisms/modal/presets/select-participants.vue';
import SaveUserSelectionModal from '~/patterns/organisms/modal/presets/save-user-selection-modal.vue';
import PushedProposalModal from '~/patterns/organisms/modal/presets/pushed-proposal-modal.vue';
import SelectTemplateModal from '~/patterns/organisms/modal/presets/select-template-modal.vue';
import ProposalsShareModal from '~/patterns/organisms/modal/presets/proposals-share-modal.vue';
import TenderRoundsModal from '~/patterns/organisms/modal/presets/tender-rounds-modal.vue';
import ProposalPreviewModal from './patterns/organisms/modal/presets/proposal-preview-modal.vue';

export default {
    components: {
        toasts,
        Navigation,
        PageHeaderMain,
        SubNavigationMain,
        PageLoader,
        AssignmentCreatedModal,
        AssignmentResponseModal,
        AssignmentAddFollowerModal,
        ConflictCheckModal,
        ProposalAcceptedModal,
        ProposalCreatedModal,
        ProposalAcceptDialogModal,
        AcceptTermsModal,
        TimelineEditModal,
        TimelineAddModal,
        CreateProposalModal,
        ProposalDialogModal,
        UserProfileModal,
        UserInviteModal,
        UserInvitedModal,
        BroadcastModal,
        AssignmentPushModal,
        SelectParticipantsModal,
        SaveUserSelectionModal,
        PushedProposalModal,
        SelectTemplateModal,
        ProposalsShareModal,
        ProposalPreviewModal,
        TenderRoundsModal
    },

    computed: {
        ...mapGetters({
            termsAccepted: 'auth/termsAccepted',
            sidebarOpen: 'ui/sidebarOpen',
            hasAccessToData: 'auth/hasAccessToData',
            user: 'auth/user'
        }),

        isAuthenticated() {
            return this.$auth.isAuthenticated;
        },

        // Returns true if we know the user is logged in via auth0, we've found a user accound,
        // and we know they have access to this community.
        userIsLoggedInLockedAndLoaded() {
            return !this.$auth.loading && this.hasUserData && this.hasAccessToData && this.isAuthenticated;
        },

        hasUserData() {
            return !!this.user;
        },

        topRoute() {
            return this.$route.matched.length ? this.$route.matched[0] : false;
        },

        subnav() {
            if (this.topRoute && this.topRoute.meta) {
                return this.topRoute.meta.subnav;
            }

            return false;
        },

        loading() {
            // If the requested route doesn't need authentication
            // the loader doesn't need to be present.
            if (this.topRoute.meta && this.topRoute.meta.noAuthRequired) {
                return false;
            }

            if (this.hasUserData || this.hasAccessToData === false) {
                return false;
            }

            return !this.isAuthenticated || !this.hasUserData;
        }
    },

    watch: {
        isAuthenticated(isAuthenticated) {
            // LoggedOut will be true if the user manually logs out.
            // This prevents a weird double pop-up screen.
            if (this.$store.getters['auth/loggedOut']) {
                return false;
            }

            if (!isAuthenticated) {
                // Timeout because auth0 needs a moment to clear it's user cache.
                setTimeout(() => {
                    this.$router.push({
                        name: 'login',
                        params: {
                            requestedRoute: this.$route
                        }
                    });
                }, 500);
            }
        },

        hasAccessToData(hasAccessToData) {
            if (!hasAccessToData) {
                this.$router.push({
                    name: '403'
                });
            }
        },

        userIsLoggedInLockedAndLoaded(lockedAndLoaded) {
            if (lockedAndLoaded) {
                this.fetchGlobalData();
                this.pollActivity();
            }
        }
    },

    mounted() {
        this.pollActivity();
    },

    methods: {
        fetchGlobalData() {
            this.$store.dispatch('users/fetchPendingUsers');
            this.$store.dispatch('users/fetchUsers');
        },

        pollActivity() {
            if (!this.userIsLoggedInLockedAndLoaded || this.pollActivityInterval) {
                return false;
            }

            this.$store.dispatch('activity/fetchActivity');

            this.pollActivityInterval = setInterval(() => {
                this.$store.dispatch('activity/fetchActivity');
            }, 60000);
        }
    },
};
</script>

<style lang="less" src="../styles/app.less" />
