<template>
    <dn-modal
        name="proposal-accept-dialog"
        size="medium"
    >
        <modal-content>
            <section>
                <h1>{{ $t('modal.proposal-accept-dialog.title') }}</h1>

                <p>{{ $t('modal.proposal-accept-dialog.description') }}</p>

                <p class="text-right">
                    <v-button
                        class="btn btn--inline"
                        @click="accept(false)"
                    >
                        {{ $t('modal.proposal-accept-dialog.cancel') }}
                    </v-button>

                    <v-button
                        class="btn btn--secondary btn--inline"
                        @click="accept(true)"
                    >
                        {{ $t('modal.proposal-accept-dialog.accept') }}
                    </v-button>
                </p>
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import VButton from '~/patterns/atoms/button/button.vue';
import { mapGetters } from 'vuex';

export default {
    components: {
        DnModal,
        ModalContent,
        VButton
    },

    computed: {
        ...mapGetters({
            modalData: 'modal/data'
        })
    },

    methods: {
        accept(value) {
            this.$eventBus.$emit('proposal-accept-dialog-accept', { value, proposalId: this.modalData.proposalId });
            this.$store.dispatch('modal/closeModal');
        }
    }
};
</script>
