'use strict';

export default {
    items: {
        // @TODO get these values from the backend
        'assignment-types': [
            'interim',
            'advice',
            'litigation'
        ],

        'lawyer-types': [
            'lawyer', // Attorney (new)
            'attorney', // (Ex-)Attorney - legacy
            'legal_counsel',
            'candidate_civil_law_notary',
            'tax_lawyer',
            'goverment_lawyer',
            'paralegal',
            'staff'
        ],

        'fields-of-law': [
            'employment',
            'real-estate',
            'contracts',
            'banking-and-finance',
            'corporate-m-and-a',
            'litigation',
            'regulatory',
            'ip-ict',
            'public-law',
            'tax',
            'insolvency-restructuring',
            'competition-public-procurement',
            'consumer-protection',
            'criminal-law'
        ],

        'haircut-keywords': [
            '€',
            '\\$',
            '/u',
            '/wk',
            'pdg',
            'Eur.',
            'P.u.',
            'Uur',
            'Tarief',
            'Uurtarief',
            'Prijs',
            'Bedrag',
            'BTW',
            'Indicatie',
            'Hourly',
            'Rate',
            'Hour',
            'Wage',
            'Price',
            'Costs',
            'Fee',
            'Charge',
            'Euro',
            'Dollars',
            'VAT',
            'Tarieven'
        ],

        'business-languages': [
            'nl', 'en', 'de', 'es', 'fr', 'it',
            'ar', 'zh', 'cs', 'da', 'fi', 'hu', 'ja', 'ro', 'pl', 'pt', 'ru', 'sk', 'sv', 'tr', 'uk'
        ],

        roles: [
            'lawyer',
            'client',
            'supplier'
        ]
    },
    successState: null
};
