
<dn-modal
    name="accept-terms"
    size="cover"
    :force-open="modalOpen"
    :allow-close="false"
>
    <modal-content>
        <confirm-box
            :title="$t('modal.accept-terms.title', { community: community.name })"
            :header-title="$t('modal.accept-terms.header-title')"
            :accept-label="$t('modal.accept-terms.accept-label')"
            :accept-button-label="$t('modal.accept-terms.button-label')"
            :confirm-message="$t('modal.accept-terms.confirm-message')"
            :disabled="!!loadingTerms.loading()"
            @confirmed="acceptTerms"
        >
            <transition
                name="fade"
                mode="out-in"
            >
                <div
                    v-if="loadingTerms.successful()"
                    key="content"
                    v-html="terms.content"
                />

                <loading-indicator
                    v-if="loadingTerms.loading()"
                    key="loader"
                />
            </transition>
        </confirm-box>
    </modal-content>
</dn-modal>
