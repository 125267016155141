<template>
    <div
        v-if="community.logo_url"
        class="community-logo"
    >
        <img
            :src="community.logo_url"
            :alt="community.name"
        >
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            community: 'community/community'
        })
    }
};
</script>

<style lang="less">
@import './community-logo.less';
</style>
