<template>
    <div class="highlight-field">
        <draggable
            v-model="highlights"
            group="highlights"
            handle=".highlight__sort"
            @start="drag=true"
            @end="drag=false"
        >
            <highlight
                v-for="(highlight, index) in highlights"
                :key="index"
                :highlight="highlight"
                :index="index"
                :editable="true"
                :sortable="true"
                @click-edit="onClickEditHighlight"
                @change="onChangeHighlight"
            />
        </draggable>

        <div class="highlight-field__actions">
            <button
                type="button"
                class="btn btn--link-secondary btn--small"
                @click="onClickAddHighlight"
            >
                <svg-icon icon="plus" /> Highlight toevoegen
            </button>
        </div>
    </div>
</template>

<script>
'use strict';

import { clone, findIndex } from 'lodash-es';
import { mapGetters } from 'vuex';
import guid from '~/helpers/guid';
import Highlight from '~/patterns/atoms/highlight/highlight.vue';

export default {
    components: {
        Highlight,
        Draggable: () => import('vuedraggable')
    },

    props: [
        'value'
    ],

    data() {
        return {
            highlights: []
        };
    },

    computed: {
        ...mapGetters({ user: 'auth/user' })
    },

    watch: {
        highlights() {
            this.$emit('input', this.highlights);
        }
    },

    created() {
        this.prepareHighlights();
        this.$eventBus.$on('delete-highlight', this.onDeleteHighlight);
        this.$eventBus.$on('add-highlight', this.onAddHighlight);
    },

    methods: {
        onClickAddHighlight() {
            this.$store.dispatch('modal/openModal', {
                name: 'highlight-form',
                data: null
            });
        },

        onClickEditHighlight(highlight, key) {
            this.$store.dispatch('modal/openModal', {
                name: 'highlight-form',
                data: Object.assign({}, { index: key, userId: this.user.id }, highlight)
            });
        },

        onAddHighlight(highlight) {
            const newHighlight = clone(highlight);
            newHighlight.id = guid();
            this.highlights.push(newHighlight);
        },

        onChangeHighlight(changedHighlight) {
            const matchingHighlightIndex = findIndex(this.highlights, { id: changedHighlight.id }),
                highlights = clone(this.highlights);

            if (matchingHighlightIndex === -1) {
                return false;
            }

            highlights[matchingHighlightIndex] = changedHighlight;

            this.highlights = highlights;
        },

        onDeleteHighlight(deletedHighlight) {
            const matchingHighlightIndex = findIndex(this.highlights, { id: deletedHighlight.id });

            if (matchingHighlightIndex === -1) {
                return false;
            }

            this.highlights.splice(matchingHighlightIndex, 1);
        },

        prepareHighlights() {
            if (!this.value) {
                return false;
            }
            const highlights = [];

            this.value.forEach((highlight) => {
                highlights.push({
                    id: guid(),
                    title: highlight.title,
                    description: highlight.description,
                    image: highlight.image,
                    order: highlight.order
                });
            });

            this.highlights = highlights;
        }
    },
};
</script>

<style lang="less">
@import './highlight-field.less';
</style>
