<template>
    <dn-modal
        v-if="userId"
        name="user-profile"
        position="centered"
        size="large"
    >
        <user-profile :id="userId" />

        <template
            v-if="isClient() || isAdmin()"
            #aside
        >
            <user-notes :user-id="userId" />
        </template>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import UserProfile from '~/patterns/organisms/user-profile/user-profile.vue';
import UserNotes from '~/patterns/organisms/user-notes/user-notes.vue';

export default {
    components: {
        DnModal,
        UserProfile,
        UserNotes
    },

    computed: {
        ...mapGetters({ data: 'modal/data', authUser: 'auth/user' }),

        userId() {
            if (this.data && this.data.userId) {
                return this.data.userId;
            }

            return null;
        }
    }
};
</script>
