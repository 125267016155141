'use strict';

import { find } from 'lodash-es';

export default {
    proposals(state) {
        if (!state.proposals) {
            return [];
        }

        return state.proposals;
    },

    proposalById: (state, getters) => (id) => {
        const foundProposal = find(getters.proposals, { id });

        return foundProposal !== -1 ? foundProposal : false;
    }
};
