
<div
    class="panel"
    :data-collapsible="collapsible"
    :data-collapsed="collapsed"
>
    <div
        v-if="title || !!$slots.title"
        class="panel__header"
        @click="collapsible ? collapsed = (collapsed !== true) : false"
    >
        <svg-icon
            v-if="collapsible"
            icon="chevron-up"
            class="panel__collapse"
        />

        <slot name="title">
            <h2 class="panel__title">{{ title }}</h2>
        </slot>
    </div>

    <transition name="expand">
        <div
            v-if="!!$slots.default"
            v-show="!collapsed"
            ref="body"
            class="panel__body"
            :style="{ height: `${bodyHeight}px` }"
        >
            <slot />
        </div>
    </transition>
</div>
