'use strict';

import { isEmpty } from 'lodash-es';
import { UPDATED_COMMUNITIES, NEW_ASSIGNMENTS, UPDATED_ASSIGNMENTS, UPDATED_THREADS } from '~/constants/activities';

export default {
    /**
     * Communities
     */
    updatedCommunities(state) {
        return state[UPDATED_COMMUNITIES] || [];
    },

    updatedCommunitiesWithoutCurrent(state, getters, rootState, rootGetters) {
        const currentCommunity = rootGetters['community/community'];

        return getters.updatedCommunities.filter(communityId => communityId !== currentCommunity.id);
    },

    hasUpdatedCommunities(state, getters) {
        return getters.updatedCommunitiesWithoutCurrent.length > 0;
    },

    /**
     * Assignments
     */
    newAssignments(state) {
        return state[NEW_ASSIGNMENTS] || [];
    },

    hasNewAssignments(state, getters) {
        return getters.newAssignments && getters.newAssignments.length > 0;
    },

    isAssignmentNew: (state, getters) => (assignmentId) => {
        return getters.newAssignments.includes(assignmentId);
    },

    updatedAssignments(state) {
        return !isEmpty(state[UPDATED_ASSIGNMENTS]) ? state[UPDATED_ASSIGNMENTS] : {};
    },

    hasAssignmentUpdatedThreads: (state, getters) => (assignmentId) => {
        const updatedAssignment = getters.updatedAssignments[assignmentId];

        if (!updatedAssignment) {
            return false;
        }

        return updatedAssignment.thread_activity && updatedAssignment.thread_activity.length > 0;
    },

    hasAssignmentNewProposals: (state, getters) => (assignmentId) => {
        const updatedAssignment = getters.updatedAssignments[assignmentId];

        if (!updatedAssignment) {
            return false;
        }

        return updatedAssignment.proposal_activity && updatedAssignment.proposal_activity.length > 0;
    },

    /**
     * Threads
     */
    updatedThreads(state) {
        return Array.isArray(state[UPDATED_THREADS]) ? state[UPDATED_THREADS] : [];
    },

    isThreadUpdated: (state, getters) => (threadId) => {
        return getters.updatedThreads.includes(threadId);
    },

    /**
     * Bundled UI states
     */
    isDashboardTabUpdated(state, getters) {
        return getters.hasNewAssignments;
    },

    isCommunityTabUpdated(state, getters, rootState, rootGetters) {
        return rootGetters['users/hasPendingUsers'];
    }
};
