<template>
    <dn-modal
        name="accept-terms"
        size="cover"
        :force-open="modalOpen"
        :allow-close="false"
    >
        <modal-content>
            <confirm-box
                :title="$t('modal.accept-terms.title', { community: community.name })"
                :header-title="$t('modal.accept-terms.header-title')"
                :accept-label="$t('modal.accept-terms.accept-label')"
                :accept-button-label="$t('modal.accept-terms.button-label')"
                :confirm-message="$t('modal.accept-terms.confirm-message')"
                :disabled="!!loadingTerms.loading()"
                @confirmed="acceptTerms"
            >
                <transition
                    name="fade"
                    mode="out-in"
                >
                    <div
                        v-if="loadingTerms.successful()"
                        key="content"
                        v-html="terms.content"
                    />

                    <loading-indicator
                        v-if="loadingTerms.loading()"
                        key="loader"
                    />
                </transition>
            </confirm-box>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters, mapActions } from 'vuex';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import ConfirmBox from '~/patterns/molecules/confirm-box/confirm-box.vue';
import LoadingIndicator from '~/patterns/molecules/loading-indicator/loading-indicator.vue';

export default {
    props: {
        modalOpen: {
            type: Boolean,
            default: false
        }
    },

    created() {
        return this.fetchTerms();
    },

    computed: {
        ...mapGetters({ terms: 'community/terms', community: 'community/community' }),

        loadingTerms() {
            return this.$loader.get('terms');
        }
    },

    methods: {
        ...mapActions({ fetchTerms: 'community/fetchTerms', acceptTerms: 'auth/acceptTerms' })
    },

    components: {
        DnModal,
        ModalContent,
        ConfirmBox,
        LoadingIndicator
    }
};
</script>
