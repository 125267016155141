<template>
    <header
        class="page-header"
        :class="{
            'page-header--fixed-height': collapsible
        }"
        :data-collapsed="isCollapsed ? 'true' : 'false'"
    >
        <div class="page-header__container">
            <div class="page-header__body">
                <div
                    v-if="label"
                    class="page-header__label"
                >
                    {{ label }}
                </div>

                <div class="page-header__main">
                    <div
                        v-if="!!$slots.preTitle"
                        class="page-header__pre-title"
                    >
                        <slot name="preTitle" />
                    </div>

                    <h1
                        v-if="!!$slots.title"
                        class="page-header__title"
                    >
                        <slot name="title" />
                    </h1>

                    <div
                        v-if="!!$slots.actions"
                        class="page-header__actions"
                    >
                        <slot name="actions" />
                    </div>
                </div>

                <div
                    v-if="!!$slots.meta"
                    class="page-header__meta"
                >
                    <slot name="meta" />

                    <div
                        v-if="!!$slots.cta"
                        class="page-header__cta"
                    >
                        <slot name="cta" />
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    props: {
        isCollapsed: {
            type: Boolean,
            default: false
        },

        collapsible: {
            type: Boolean,
            default: false
        },

        label: {
            type: String,
            default: null,
            required: false
        }
    }
};
</script>

<style lang="less">
@import './page-header.less';
</style>
