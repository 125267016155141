<template>
    <a
        class="file-download"
        :href="downloadUrl"
        :title="`Download ${attachment.name}`"
    >
        <svg-icon
            v-if="!hideIcon"
            icon="attachments"
        />

        <span class="file-download__label">
            <slot>{{ attachment.name }}</slot>
        </span>
    </a>
</template>

<script>
export default {
    props: {
        hideIcon: {
            type: Boolean,
            default: false,
            required: false
        },
        attachment: {
            type: Object,
            required: true
        },
        href: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            token: ''
        };
    },

    computed: {
        downloadUrl() {
            return `${this.href}?token=${this.token}`;
        }
    },

    async created() {
        this.token = await this.$auth.getTokenSilently();
    }
};
</script>

<style lang="less">
@import './file-download.less';
</style>
