
<div
    class="accordion"
    :data-accordion-open="isOpen ? 'true' : 'false'"
>
    <header
        class="accordion__header"
        @click="toggleAccordion"
    >
        <h3>{{ title }}</h3>

        <a class="accordion__toggle">
            <svg-icon icon="chevron-down" />
        </a>
    </header>

    <div
        v-if="isOpen"
        class="accordion__body"
    >
        <slot />
    </div>
</div>
