'use strict';

import { parseISO } from 'date-fns';
import { isClient, isLawyer, isSupplier } from '~/helpers/user';

export default {
    user(state) {
        return state.user;
    },

    hasAccessToData(state) {
        // This will be set to 'false' when the user doesn't have access
        if (state.hasAccessToData === undefined) {
            return true;
        }

        return state.hasAccessToData;
    },

    loggedOut(state) {
        return state.loggedOut;
    },

    termsAccepted: (state, getters, { community }) => {
        // Allow access if the user is a Client or if there are no terms to accept
        if (!community.community.terms_updated_at ||
            (state.user !== undefined && !isLawyer())
        ) {
            return true;
        }

        // Deny Access when the user has not accepted terms or is being forced to.
        if (state.user === undefined || state.forceNewTerms || !state.user.terms_accepted_at) {
            return false;
        }

        // In all other cases, compare the dates to determine if acceptance is needed.
        return parseISO(community.community.terms_updated_at) < parseISO(state.user.terms_accepted_at);
    }
};
