<template>
    <page-header
        :collapsible="true"
        :is-collapsed="headerCollapsed"
    >
        <template #preTitle>
            <community-logo />
        </template>

        <template #title>
            {{ community.name }}
        </template>
    </page-header>
</template>

<script>
import { mapGetters } from 'vuex';
import PageHeader from '~/patterns/molecules/page-header/page-header.vue';
import CommunityLogo from '~/patterns/atoms/community-logo/community-logo.vue';

export default {
    computed: {
        ...mapGetters({
            headerCollapsed: 'ui/headerCollapsed',
            community: 'community/community'
        })
    },

    components: {
        PageHeader,
        CommunityLogo
    }
};
</script>
