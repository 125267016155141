
<dn-modal
    name="timeline-edit"
    position="centered"
    size="medium"
>
    <modal-content v-if="highlight">
        <section class="text-center">
            <h2 v-if="highlight.type === 'experience'">{{ $t('modal.timeline-edit.title-experience') }}</h2>
            <h2 v-if="highlight.type === 'study'">{{ $t('modal.timeline-edit.title-study') }}</h2>
        </section>

        <section>
            <form class="form">
                <form-field class="column-grid">
                    <form-field
                        :error-messages="errors.collect('start')"
                        columns-sm-min="4/8"
                        columns-md-min="3/8"
                    >
                        <label
                            slot="label"
                            for="start"
                        >
                            {{ $t('modal.timeline-edit.start.label') }}
                        </label>

                        <input
                            id="start"
                            v-model="highlight.start"
                            v-validate="'required'"
                            type="number"
                            name="start"
                            :placeholder="$t('modal.timeline-edit.start.placeholder')"
                        >
                    </form-field>

                    <form-field
                        :error-messages="errors.collect('end')"
                        columns-sm-min="4/8"
                        columns-md-min="3/8"
                    >
                        <label
                            slot="label"
                            for="start"
                        >
                            {{ $t('modal.timeline-edit.end.label') }}
                        </label>

                        <input
                            id="end"
                            v-model="highlight.end"
                            type="number"
                            name="end"
                            :placeholder="$t('modal.timeline-edit.end.placeholder')"
                        >
                    </form-field>
                </form-field>

                <form-field
                    :error-messages="errors.collect('title')"
                >
                    <label
                        slot="label"
                        for="title"
                    >{{ titleField.label }}</label>

                    <input
                        id="title"
                        v-model="highlight.title"
                        v-validate="'required'"
                        type="text"
                        name="title"
                        :placeholder="titleField.placeholder"
                    >
                </form-field>

                <form-field
                    class="form-field--last"
                    :error-messages="errors.collect('organisation')"
                >
                    <label
                        slot="label"
                        for="organisation"
                    >{{ organisationField.label }}</label>

                    <input
                        id="organisation"
                        v-model="highlight.organisation"
                        v-validate="'required'"
                        type="text"
                        name="organisation"
                        :placeholder="organisationField.placeholder"
                    >
                </form-field>
            </form>
        </section>

        <section class="text-right">
            <button
                class="btn btn--link-error btn--medium"
                style="margin-right: 1rem;"
                @click="deleteHighlight"
            >
                {{ $t('modal.timeline-edit.delete') }}
            </button>

            <button
                class="btn btn--secondary btn--medium"
                @click="saveHighlight"
            >
                {{ $t('modal.timeline-edit.submit') }}
            </button>
        </section>
    </modal-content>
</dn-modal>
