'use strict';

export default {
    searchQuery(state, getters, rootState) {
        return rootState.route.query.search;
    },

    assignmentTypes(state, getters, rootState) {
        return rootState.route.query.assignment_types;
    },

    lawyerTypes(state, getters, rootState) {
        return rootState.route.query.lawyer_types;
    },

    fieldsOfLaw(state, getters, rootState) {
        return rootState.route.query.fields_of_law;
    },

    businessLanguages(state, getters, rootState) {
        return rootState.route.query.business_languages;
    },

    suppliers(state, getters, rootState) {
        return rootState.route.query.suppliers;
    }
};
