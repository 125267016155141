<template>
    <div class="community-filters">
        <section v-if="hide.indexOf('title') === -1">
            <h3>{{ $t('communityFilters.title') }}</h3>
        </section>

        <section>
            <accordion :title="$t('communityFilters.filters.assignment-type.title')">
                <checkbox
                    v-for="(assignmentType, index) in assignmentTypeOptions"
                    :key="index"
                    v-model="filterModel.assignment_types"
                    class="checkbox--small"
                    :value="assignmentType"
                >
                    {{ $t(`assignmentTypes.${assignmentType}`) }}
                </checkbox>
            </accordion>

            <accordion :title="$t('communityFilters.filters.lawyer-type.title')">
                <multiselect
                    v-model="filterModel.lawyer_types"
                    class="multiselect--light"
                    :options="lawyerTypeOptions"
                    :placeholder="$t('communityFilters.filters.lawyer-type.placeholder')"
                    :searchable="false"
                    :show-labels="false"
                    :multiple="true"
                    :custom-label="lawyerTypeLabel"
                />
            </accordion>

            <accordion :title="$t('communityFilters.filters.field-of-law.title')">
                <multiselect
                    v-model="filterModel.fields_of_law"
                    class="multiselect--light"
                    :options="fieldOfLawOptions"
                    :placeholder="$t('communityFilters.filters.field-of-law.placeholder')"
                    :searchable="false"
                    :show-labels="false"
                    :multiple="true"
                    :custom-label="fieldOfLawLabel"
                />
            </accordion>

            <accordion
                v-if="supplierOptions.length > 0"
                :title="$t('communityFilters.filters.supplier.title')"
            >
                <checkbox
                    v-for="(supplier, index) in supplierOptions"
                    :key="index"
                    v-model="filterModel.suppliers"
                    class="checkbox--small"
                    :value="supplier.id"
                >
                    {{ supplier.title }}
                </checkbox>
            </accordion>

            <accordion :title="$t('communityFilters.filters.business-language.title')">
                <multiselect
                    v-model="filterModel.business_languages"
                    class="multiselect--light"
                    :options="businessLanguageOptions"
                    :placeholder="$t('communityFilters.filters.business-language.placeholder')"
                    :searchable="false"
                    :show-labels="false"
                    :multiple="true"
                    :custom-label="businessLanguageLabel"
                />
            </accordion>
        </section>

        <section>
            <search-bar />
        </section>
    </div>
</template>

<script>
'use strict';
import Vue from 'vue';
import { forOwn } from 'lodash-es';
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import Suppliers from '~/graphql/queries/supplier/Suppliers.graphql';

import Accordion from '~/patterns/organisms/accordion/accordion.vue';
import SearchBar from '~/patterns/atoms/search-bar/search-bar.vue';
import Checkbox from '~/patterns/atoms/checkbox/checkbox.vue';

export default {
    components: {
        SearchBar,
        Checkbox,
        Multiselect,
        Accordion
    },

    props: {
        hide: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    emits: ['has-filters', 'change-query'],

    data() {
        return {
            filterModel: {
                assignment_types: [],
                suppliers: [],
                lawyer_types: [],
                fields_of_law: [],
                business_languages: []
            },
            supplierOptions: []
        };
    },

    computed: {
        ...mapGetters({
            // Enums
            assignmentTypeOptions: 'enums/getAssignmentTypes',
            lawyerTypeOptions: 'enums/getLawyerTypes',
            fieldOfLawOptions: 'enums/getFieldsOfLaw',
            businessLanguageOptions: 'enums/getBusinessLanguageOptions',
            assignmentTypes: 'search/assignmentTypes',
            lawyerTypes: 'search/lawyerTypes',
            fieldsOfLaw: 'search/fieldsOfLaw',
            businessLanguages: 'search/businessLanguages',
            suppliers: 'search/suppliers'
        }),

        hasFilters() {
            if (this.$route.query?.search !== undefined || this.$route.query?.search !== '') {
                return true;
            }

            const amountOfFilters = Object.keys(this.filterModel).length;
            if (amountOfFilters === 0) {
                return false;
            }

            const amountOfFilledFilters = Object.values(this.filterModel).filter(value => value && value.length).length;
            if (amountOfFilledFilters === 0) {
                return false;
            }

            return true;
        },

        filterPresets() {
            return {
                assignment_types: this.assignmentTypes ? this.assignmentTypes.split(',') : [],
                lawyer_types: this.lawyerTypes ? this.lawyerTypes.split(',') : [],
                fields_of_law: this.fieldsOfLaw ? this.fieldsOfLaw.split(',') : [],
                business_languages: this.businessLanguages ? this.businessLanguages.split(',') : [],
                suppliers: this.suppliers ? this.suppliers.split(',') : []
            };
        }
    },

    watch: {
        '$route.query': {
            handler() {
                // Update results on the next tick, when the store has been updated with the route query params
                Vue.nextTick(() => {
                    this.resetFilterModel();
                    this.updateResults();
                });
            },
            deep: true
        },

        hasFilters: {
            handler(value) {
                this.$emit('has-filters', value);
            },
            immediate: true
        },

        filterModel: {
            handler() {
                // Set a timeout as debounce for user interaction
                clearTimeout(this.timer);
                this.timer = setTimeout(this.updateQueryFilters, 250);
            },
            deep: true
        }
    },

    created() {
        this.resetFilterModel();
        this.getCommunitySuppliers();

        if (this.hasFilters) {
            this.updateResults();
        }
    },

    methods: {
        updateResults() {
            this.$store.dispatch('users/fetchFilteredUsers');
            this.$emit('change-query');
        },

        async updateQueryFilters() {
            const query = structuredClone(this.$route.query);

            forOwn(this.filterModel, (value, key) => {
                query[key] = value.join(',');
            });

            await this.$router.replace({ query });
        },

        async getCommunitySuppliers() {
            const response = await this.$executeGqlQuery(Suppliers);

            this.supplierOptions = response.data.suppliers;
        },

        resetFilterModel() {
            this.filterModel = structuredClone(this.filterPresets);
        },

        lawyerTypeLabel(option) {
            return this.$i18n.t(`lawyerTypes.${option}`);
        },

        fieldOfLawLabel(option) {
            return this.$i18n.t(`fieldsOfLaw.${option}`);
        },

        businessLanguageLabel(option) {
            return this.$i18n.t(`languages.${option}`);
        }
    }
};
</script>

<style lang="less">
@import './community-filters.less';
</style>
