<template>
    <button
        class="btn"
        :type="type"
        :data-state="buttonState ? buttonState : 'default'"
        :data-show-label="!buttonState || !showState ? 'true' : 'false'"
        :disabled="isDisabled"
        @click="onClick"
    >
        <span class="btn__label"><slot /></span>

        <transition name="fade-absolute">
            <span
                v-if="buttonState === 'loading'"
                key="loading"
                class="btn__state"
            >
                <spinner class="spinner--button" />
            </span>

            <span
                v-else-if="buttonState === 'success' && showState"
                key="success"
                class="btn__state"
            >{{ successLabel }}</span>

            <span
                v-else-if="buttonState === 'fail' && showState"
                key="fail"
                class="btn__state"
            >{{ failLabel }}</span>
        </transition>
    </button>
</template>

<script>
'use strict';

import Spinner from '~/patterns/atoms/spinner/spinner.vue';

export default {

    components: {
        Spinner
    },
    props: {
        state: {
            default: false
        },

        type: {
            default: 'button'
        },

        element: {
            default: 'button',
            type: String
        },

        disabled: {
            type: Boolean,
            default: false
        },

        successLabel: {
            default() {
                return this.$i18n.t('button.success');
            }
        },

        failLabel: {
            default() {
                return this.$i18n.t('button.fail');
            }
        }
    },

    data() {
        return {
            buttonState: this.state,
            showState: true
        };
    },

    computed: {
        isDisabled() {
            return this.state === 'loading' || this.disabled;
        }
    },

    watch: {
        state(newState) {
            this.buttonState = newState;
            if (newState) {
                this.showState = true;
                window.setTimeout(this.clearState, 2000);
            } else {
                this.showState = false;
            }
        }
    },

    beforeDestroy() {
        window.clearTimeout(this.clearState);
    },

    methods: {
        onClick(e) {
            this.$emit('click', e);
        },
        clearState() {
            this.buttonState = false;
        }
    }
};
</script>

<style lang="less">
@import './button.less';
</style>
