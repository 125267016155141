
<div class="navigation">
    <!-- Top bar -->
    <div class="navigation__bar navigation__bar--default">
        <a
            class="navigation__logo"
            href="/"
        >
            <img
                src="/img/logos/link.svg"
                alt="LawyerlinQ"
            >
        </a>

        <div class="navigation__return">
            <return-link />
        </div>

        <div class="navigation__bar__right">
            <button
                v-if="!isGuest()"
                type="button"
                class="navigation__item navigation__item--account"
                @click="setActiveTab('account')"
            >
                <avatar
                    :user="user"
                    :link-to-profile="false"
                    :is-unread="hasActivity"
                    class="avatar--darkened"
                />

                <svg-icon icon="chevron-down" />
            </button>

            <nav-dropdown :active-tab="activeTab" />
        </div>
    </div>
</div>
