'use strict';

import { find, clone } from 'lodash-es';
import { STATUS_CLOSED } from '../../../constants/assignment-status';

export default {
    recentAssignments(state, getters) {
        const assignments = clone(getters.assignments);

        return assignments.filter((assignment) => {
            return assignment.status !== STATUS_CLOSED;
        });
    },

    assignments(state) {
        if (!state.assignments) {
            return [];
        }

        return state.assignments;
    },

    assignmentById: (state, getters) => (id) => {
        const foundAssignment = find(getters.assignments, { id });

        return foundAssignment !== -1 ? foundAssignment : false;
    }
};
