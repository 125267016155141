
<section
    class="selectable-list"
    :data-selection-allowed="allowSelection ? 'true' : 'false'"
>
    <section-header
        v-if="showHeader"
        class="selectable-list__header"
    >
        <template #prefix>
            <button
                v-if="allowSelection"
                type="button"
                class="checkbox__custom"
                :data-selected="selectedAll ? 'true' : 'false'"
                :data-intermediate="selectedIds.length > 0 && !selectedAll"
                @click="toggleAll"
            />
        </template>

        <template #title>
            <slot name="title" />
        </template>

        <template #actions>
            <slot name="header_actions" />
        </template>
    </section-header>

    <div class="selectable-list__body">
        <ul
            v-if="selectableItems && selectableItems.length"
            class="list list--clean"
        >
            <li
                v-for="(item, index) in selectableItems"
                :key="index"
                class="selectable-list__item"
            >
                <transition name="fade-absolute">
                    <div
                        v-show="allowSelection"
                        class="selectable-list__checkbox"
                    >
                        <button
                            type="button"
                            class="checkbox__custom"
                            :data-selected="isSelected(item) ? 'true' : 'false'"
                            :disabled="isDisabled(item)"
                            @click="toggleItem(item)"
                        />
                    </div>
                </transition>

                <slot
                    name="preview"
                    :item="item"
                    :index="index"
                />

                <div class="selectable-list__actions">
                    <slot
                        name="actions"
                        :item="item"
                    />
                </div>
            </li>
        </ul>
    </div>

    <slot name="cta" />
</section>
