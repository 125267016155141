
<dn-modal
    name="save-user-selection-modal"
    position="centered"
    size="medium"
>
    <modal-content>
        <section>
            <h2>{{ $t('modal.save-user-selection.modal-title') }}</h2>
        </section>

        <section>
            <p>{{ $t('modal.save-user-selection.modal-text') }}</p>
            <form
                class="form"
                data-vv-scope="save-user"
                @submit.prevent="handleSubmit"
            >
                <form-field
                    class="form-field--last"
                    :error-messages="errors.collect('save-user.content')"
                >
                    <label
                        slot="label"
                        for="title"
                    >
                        {{ $t('modal.save-user-selection.title-label') }}
                    </label>

                    <input
                        v-model="title"
                        v-validate="'required'"
                        name="title"
                        type="text"
                        :placeholder="$t('modal.save-user-selection.title-placeholder')"
                        resize="vertical"
                    >
                </form-field>
            </form>
        </section>

        <section>
            <llq-button
                class="btn btn--secondary"
                :state="requestState"
                @click.prevent="handleSubmit"
            >
                {{ $t('modal.save-user-selection.submit') }}
            </llq-button>
        </section>
    </modal-content>
</dn-modal>
