import Vue from 'vue';

export default {
    getById(id) {
        return Vue.axios.get(`/v1.0/proposals/${id}`, {
            params: {
                include: [
                    'assignment',
                    'message',
                    'message.threads',
                    'media',
                    'comments',
                    'resume'
                ]
            }
        });
    },

    acceptProposal(id) {
        return Vue.axios.put(`/v1.0/proposals/${id}/accept`);
    },

    closeProposal(id) {
        return Vue.axios.put(`/v1.0/proposals/${id}/close`);
    },

    getPdf(id) {
        return `${window.appConfig.api.url}/v1.0/proposals/${id}/pdf/download`;
    },

    getMedia(proposalId, mediaId) {
        return `${window.appConfig.api.url}/v1.0/proposals/${proposalId}/${mediaId}/download`;
    }
};
