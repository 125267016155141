
<div
    v-if="!notesNotAllowed"
    class="user-notes"
>
    <h2 class="user-notes__title">
        {{ $t('userNotes.title', {
            communityName: community.name
        }) }}
    </h2>

    <form class="user-notes__form">
        <textarea
            v-model="userNote.note"
            class="user-notes__input input input--textarea input--light input--shadowed"
            :placeholder="$t('userNotes.textarea.placeholder')"
            rows="3"
            :disabled="submitting"
            required
        />

        <v-button
            ref="button"
            :class="`btn--medium ${buttonClass} user-notes__button`"
            :state="buttonState"
            @click.prevent="submitNote"
        >
            {{ $t('userNotes.submit') }}
        </v-button>
    </form>

    <loading-indicator v-if="loading || submitting" />

    <template v-if="!loading">
        <div
            v-if="notes && notes.length"
            class="user-notes__notes"
        >
            <message
                v-for="note in notes"
                :key="note.id"
                class="message--note"
                :message="note"
                author-property="author"
                avatar-position="below"
            >
                <template #default>
                    <p>{{ note.note }}</p>
                </template>

                <template #actions="{ message }">
                    <icon
                        v-if="message.author.id === authUser.id"
                        class="user-notes__delete"
                        icon="trash"
                        @click.native="handleDeleteNote(message)"
                    />
                </template>

                <template #meta="{ message }">
                    <span>{{ message.author.full_name }} - <time>{{ message.created_at | dateFormat($t('date-format.date')) }}</time></span>
                </template>
            </message>
        </div>
    </template>
</div>
