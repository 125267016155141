<template>
    <dn-modal
        v-if="mode"
        name="proposal-dialog"
        size="medium"
        position="centered"
    >
        <modal-content>
            <section>
                <h1>
                    {{ $t(`modal.${mode}-proposal-dialog.title`) }}
                </h1>
                <p>
                    {{ $t(`modal.${mode}-proposal-dialog.description`) }}
                </p>

                <p>
                    {{ $t(`modal.${mode}-proposal-dialog.confirmation`) }}
                </p>

                <p class="text-right">
                    <v-button
                        class="btn--inline"
                        @click="closeModal"
                    >
                        {{ $t(`modal.${mode}-proposal-dialog.cancel`) }}
                    </v-button>

                    <v-button
                        class="btn--secondary btn--inline"
                        @click="submit"
                    >
                        {{ $t(`modal.${mode}-proposal-dialog.continue`) }}
                    </v-button>
                </p>
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import VButton from '~/patterns/atoms/button/button.vue';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';

export default {
    components: {
        VButton,
        DnModal,
        ModalContent
    },
    computed: {
        mode() {
            return this.$store.getters['modal/data']?.mode;
        },
        prefillData() {
            return this.$store.getters['modal/data']?.prefillData;

        }
    },
    methods: {
        closeModal() {
            this.$store.dispatch('modal/closeModal');
        },
        submit() {
            if (this.mode === 'create') {
                this.$eventBus.$emit('create-proposal');
            } else if (this.mode === 'duplicate') {
                this.$eventBus.$emit('duplicate-proposal', this.prefillData);
            }
        }
    }
};
</script>
