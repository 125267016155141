
<dn-modal
    name="highlight-form"
    position="centered"
    size="medium"
>
    <modal-content>
        <section class="text-center">
            <h2 v-if="action === 'add'">{{ $t('modal.highlight-edit.title-add') }}</h2>
            <h2 v-else>{{ $t('modal.highlight-edit.title-edit') }}</h2>
        </section>

        <section>
            <form class="form">
                <form-field :error-messages="errors.collect('title')">
                    <label
                        slot="label"
                        for="title"
                    >
                        {{ $t('modal.highlight-edit.title.label') }}
                    </label>

                    <input
                        id="title"
                        v-model="highlight.title"
                        v-validate="'required'"
                        type="text"
                        name="title"
                        :placeholder="$t('modal.highlight-edit.title.placeholder')"
                    >
                </form-field>

                <form-field :error-messages="errors.collect('description')">
                    <label
                        slot="label"
                        for="description"
                    >
                        {{ $t('modal.highlight-edit.description.label') }}
                    </label>

                    <textarea
                        id="description"
                        v-model="highlight.description"
                        v-validate="'required'"
                        rows="10"
                        name="description"
                        :placeholder="$t('modal.highlight-edit.description.placeholder')"
                    />
                </form-field>

                <form-field>
                    <label
                        slot="label"
                        for="image"
                    >
                        {{ $t('modal.highlight-edit.image.label') }}
                    </label>
                    <file-upload
                        v-model="highlight.image"
                        name="image"
                        :thumbnail="highlight.image ? getImage() : ''"
                        @file-uploaded="uploading = false"
                        @file-uploading="uploading = true"
                    >
                        <div
                            v-if="!highlight.image || !highlight.image.length"
                            class="file-upload__placeholder"
                        >
                            <icon icon="camera" />
                        </div>

                        <div class="file-upload__label">
                            {{ $t('modal.highlight-edit.image-upload') }}
                        </div>
                        <div class="file-upload__sublabel">
                            min. 200x200px
                        </div>
                    </file-upload>
                </form-field>
            </form>
        </section>

        <section class="text-right">
            <button
                v-if="action === 'edit'"
                class="btn btn--link-error btn--medium"
                style="margin-right: 1rem;"
                @click="deleteHighlight"
            >
                {{ $t('modal.highlight-edit.delete') }}
            </button>

            <button
                class="btn btn--secondary btn--medium"
                :disabled="uploading"
                @click="saveHighlight"
            >
                {{ $t('modal.highlight-edit.submit') }}
            </button>
        </section>
    </modal-content>
</dn-modal>
