<template>
    <article
        v-if="previewFields && previewFields.length"
        class="proposal-preview"
    >
        <h5
            v-if="showHeading"
            class="proposal-preview__heading"
        >
            {{ $t('proposalPreview.heading') }}
        </h5>
        <proposal-meta
            class="proposal-preview__meta"
            :proposal="stubProposal"
        >
            <template #starts_at>
                {{ $t('proposalPreview.date', { date: stubDate }) }}
            </template>

            <template #availability>
                {{ $t('proposalPreview.availability', {
                    availability: $tc(
                        `availabilityWithAmount.${stubProposal.availability_per_time_unit}`,
                        stubProposal.availability_amount
                    )
                }) }}
            </template>
        </proposal-meta>
    </article>
</template>

<script>
import ProposalMeta from '~/patterns/molecules/proposal-meta/proposal-meta.vue';

export default {
    components: {
        ProposalMeta
    },

    props: {
        previewFields: {
            type: Array,
            required: false,
            default: () => []
        },
        customDescriptions: {
            type: Array,
            required: false,
            default: () => []
        },
        openQuestions: {
            type: Array,
            required: false,
            default: () => []
        },
        workloadFields: {
            type: Array,
            required: false,
            default: () => []
        },
        showHeading: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    computed: {
        stubDate() {
            if (!this.stubProposal?.starts_a) {
                return '';
            }
            return this.$options.filters.dateFormat(this.stubProposal.starts_at, this.$t('date-format.date'));
        },
        stubProposal() {
            const stub = {
                id: '',
                description: {}
            };

            this.previewFields.forEach((fieldName) => {
                switch (fieldName) {
                case 'APPROACH':
                    stub.content = this.$t('proposalPreview.approach');
                    stub.description.content = this.findDescription(fieldName);
                    break;
                case 'REMARKS':
                    stub.remarks = this.$t('proposalPreview.remarks');
                    stub.description.remarks = this.findDescription(fieldName);
                    break;
                case 'START_DATE':
                    stub.starts_at = new Date();
                    stub.description.starts_at = this.findDescription(fieldName);
                    break;
                case 'TARIFF_ANY':
                    stub.tariffs = [
                        {
                            type: this.$t('proposalPreview.tariff_type1'),
                            amount: 200,
                        },
                        {
                            type: this.$t('proposalPreview.tariff_type2'),
                            amount: 125,
                        }
                    ];
                    stub.tariff_note = this.$t('proposalPreview.optional_note_tariff');
                    stub.description.tariff = this.findDescription(fieldName);
                    break;
                case 'AVAILABILITY_ANY':
                    stub.availability_amount = 28;
                    stub.availability_per_time_unit = 'hoursPerWeek';
                    stub.availability_note = this.$t('proposalPreview.optional_note_availability');
                    stub.description.availability = this.findDescription(fieldName);
                    break;

                case 'RESUME':
                case 'MEDIA':
                    stub[fieldName === 'RESUME' ? 'cv' : 'media'] = [
                        {
                            id: 1,
                            name: 'download.pdf',
                        }
                    ];
                    stub.description[fieldName === 'RESUME' ? 'cv' : 'media'] = this.findDescription(fieldName);
                    break;
                case 'WORKLOAD_ANY':
                    stub.workloads = [{}];
                    if (this.workloadFields.length > 0) {
                        this.workloadFields.forEach((field, index) => {
                            stub.workloads[index] = {
                                description: field.label,
                                lower_boundary: 100,
                                upper_boundary: 200,
                                total_estimate: null,
                                type: 'range'
                            };
                        });
                    } else {
                        stub.workloads[0].description = this.$t('proposalPreview.workload_description3');
                        stub.workloads[0].lower_boundary = 100;
                        stub.workloads[0].upper_boundary = 200;
                        stub.workloads[0].total_estimate = null;
                        stub.workloads[0].type = 'range';
                    }
                    break;
                case 'OPEN_QUESTIONS':
                    stub.open_questions_answers = this.openQuestions.map((question) => ({
                        label: question.label,
                        description: question.description,
                        value: this.$t('proposalPreview.open_questions_answers')
                    }));
                    break;
                case 'FIXED_FEE':
                    // eslint-disable-next-line camelcase
                    stub.fixed_fee_amount = 89000;
                    // eslint-disable-next-line camelcase
                    stub.fixed_fee_note =  this.$t('proposalPreview.optional_note_fixed-fee');
                    stub.description.fixed_fee = this.findDescription(fieldName);
                    break;
                default:
                    stub[fieldName] = '...';
                    stub.description[fieldName] = this.findDescription(fieldName);
                    break;
                }
            });

            return stub;
        }
    },

    methods: {
        findDescription(fieldName) {
            const match = this.customDescriptions.find((description) => description.field === fieldName);

            return match ? match.description : this.$t(`assignment.form.panel.elements.default-description.${fieldName.toLowerCase()}`);
        }
    }
};
</script>

<style lang="less">
.proposal-preview {
    .proposal-preview__heading {
        .font-size-text;
        margin-bottom: .5625rem;
    }

    .proposal-preview__meta {
        background: @color-gray-10;
        padding: 1.5rem;
        opacity: .7;
    }

    &,
    .proposal-preview__meta.proposal-meta .meta-list__item,
    .proposal-preview__meta.proposal-meta .meta-list__item > * {
        .font-size-small;
    }
}
</style>
