<template>
    <default-view>
        <article class="article">
            <h1>{{ $t('errorsView.403.title') }}</h1>

            <p class="lead">{{ $t('errorsView.403.description') }}</p>
        </article>
    </default-view>
</template>

<script>
'use strict';

export default {
    components: {
    }
};
</script>
