'use strict';

import AssignmentApi from '~/api/assignments';
import Loader from '~/services/loader/loading-service';

export default {
    fetchAll(context) {
        Loader.get('assignments').start();

        AssignmentApi.all().then((response) => {
            if (response.data && response.data.data) {
                context.commit('assignments', response.data.data);
            }

            Loader.get('assignments').success();
        }).catch((err) => {
            // TODO: Connect to toast by passing the error
            Loader.get('assignments').fail(err);
        });
    },

    fetchById(context, id) {
        Loader.get(`assignment-${id}`).start();

        AssignmentApi.getById(id).then((response) => {
            context.commit('assignment', response.data);

            Loader.get(`assignment-${id}`).success();
        }).catch((err) => {
            Loader.get(`assignment-${id}`).fail(err);
        });
    },

    setStatus(context, { id, status, sendNotification = true }) {
        if (!id) {
            return false;
        }

        Loader.get(`assignment-${id}.status`).start();

        return new Promise((resolve, reject) => {
            AssignmentApi.setStatus(id, status, sendNotification).then((response) => {
                Loader.get(`assignment-${id}.status`).success();
                resolve(response);
            }).catch((err) => {
                Loader.get(`assignment-${id}.status`).fail(err);
                reject(err);
            });
        });
    },

    delete(context, id) {
        if (!id) {
            return false;
        }

        Loader.get(`assignment-${id}.delete`).start();

        return new Promise((resolve, reject) => {
            AssignmentApi.delete(id).then((response) => {
                Loader.get(`assignment-${id}.delete`).success();
                resolve(response);
            }).catch((err) => {
                Loader.get(`assignment-${id}.delete`).fail(err);
                reject(err);
            });
        });
    }
};
