<template>
    <dn-modal
        name="assignment-add-follower"
        position="centered"
        size="medium"
    >
        <modal-content v-if="assignment">
            <section class="text-center">
                <h2>{{ $t('modal.assignment-add-follower.modal-title') }}</h2>
            </section>

            <section>
                <load-status loader-name="users">
                    <form class="form">
                        <form-field>
                            <p>{{ $t('modal.assignment-add-follower.form-intro') }}</p>
                        </form-field>

                        <form-field class="column-grid">
                            <form-field
                                :error-messages="errors.collect('user_id')"
                                columns-sm-min="4/8"
                            >
                                <label
                                    slot="label"
                                    for="user_id"
                                >
                                    {{ $t('modal.assignment-add-follower.user_id.label') }}
                                </label>

                                <multiselect
                                    v-model="formData.userId"
                                    :options="selectableClientIds"
                                    :placeholder="$t('modal.assignment-add-follower.user_id.placeholder')"
                                    :searchable="false"
                                    :show-labels="false"
                                    :multiple="false"
                                    :custom-label="clientLabel"
                                    :allow-empty="false"
                                />
                            </form-field>
                        </form-field>
                    </form>
                </load-status>
            </section>

            <section class="text-right">
                <v-button
                    class="btn btn--secondary btn--medium"
                    :state="requestState"
                    :disabled="!formData.userId"
                    @click="addFollower"
                >
                    {{ $t('modal.assignment-add-follower.submit') }}
                </v-button>
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';
import { isMe, getLoggedInUser } from '~/helpers/user';
import AssignmentApi from '~/api/assignments';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import LoadStatus from '~/patterns/organisms/load-status/load-status.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';
import VButton from '~/patterns/atoms/button/button.vue';

export default {
    data() {
        return {
            requestState: undefined,

            formData: {
                userId: undefined
            }
        };
    },

    computed: {
        ...mapGetters({
            clientIds: 'users/clientIds'
        }),

        modalData() {
            return this.$store.getters['modal/data'];
        },

        assignment() {
            if (!this.modalData || !this.modalData.assignment) {
                return false;
            }

            return this.modalData.assignment;
        },

        currentFollowers() {
            if (!this.assignment || !this.assignment.followers) {
                return [];
            }

            return this.assignment.followers;
        },

        selectableClientIds() {
            const pool = this.clientIds.filter((userId) => {
                const isLoggedInUser = isMe(userId);
                const alreadyFollowing = this.isUserFollowing(userId);

                return !isLoggedInUser && !alreadyFollowing;
            });

            if (this.isCommunityManager()) {
                pool.push(getLoggedInUser().id);
            }

            return pool;
        }
    },

    methods: {
        addFollower() {
            this.$validator.validateAll().then((noErrors) => {
                if (!noErrors) {
                    return;
                }

                this.requestState = 'loading';

                AssignmentApi.postParticipants(this.assignment.id, [
                    {
                        user_id: this.formData.userId,  // eslint-disable-line
                        is_follower: true  // eslint-disable-line
                    }
                ]).then(() => {
                    this.requestState = 'success';

                    this.formData.userId = null;
                    this.refreshAssignment();
                    this.$store.dispatch('modal/closeModal');
                }).catch(() => {
                    this.requestState = 'fail';

                    this.$store.dispatch('toast/add', {
                        content: this.$i18n.t('modal.assignment-add-follower.request-fail'),
                        severity: 'error'
                    });
                }).finally(() => {
                    setTimeout(() => {
                        this.requestState = undefined;
                    }, 2000);
                });
            });
        },

        clientLabel(userId) {
            if (!userId) {
                return;
            }

            if (getLoggedInUser().id === userId && getLoggedInUser().full_name !== undefined) {
                return getLoggedInUser().full_name;
            }

            const user = this.$store.getters['users/userById'](userId);

            if (!user || !user.full_name) {
                return;
            }

            return user.full_name;
        },

        isUserFollowing(userId) {
            if (!userId) {
                return false;
            }

            return this.currentFollowers.findIndex(user => user.id === userId) !== -1;
        },

        refreshAssignment() {
            this.$store.dispatch('assignments/fetchById', this.assignment.id);
        }
    },

    components: {
        DnModal,
        ModalContent,
        FormField,
        Multiselect,
        LoadStatus,
        VButton
    }
};
</script>
