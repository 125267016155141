<template>
    <span
        class="modal-trigger"
        @click.prevent="openModal"
    ><slot /></span>
</template>

<script>
'use strict';

export default {
    props: {
        modalName: {
            type: String,
            required: true
        },

        modalData: {
            type: Object,
            required: false
        }
    },

    methods: {
        openModal() {
            this.$store.dispatch('modal/openModal', {
                name: this.modalName,
                data: this.modalData
            });
        }
    }
};
</script>

<style lang="less">
@import './modal-trigger.less';
</style>
