const eitherIsRequired = 'Je moet een bericht, een bijlage, of beide invoeren';

export default {
    custom: {
        message: {
            required: eitherIsRequired
        },
        attachment: {
            required: eitherIsRequired
        }
    }
};
