
<div class="highlight-field">
    <draggable
        v-model="highlights"
        group="highlights"
        handle=".highlight__sort"
        @start="drag=true"
        @end="drag=false"
    >
        <highlight
            v-for="(highlight, index) in highlights"
            :key="index"
            :highlight="highlight"
            :index="index"
            :editable="true"
            :sortable="true"
            @click-edit="onClickEditHighlight"
            @change="onChangeHighlight"
        />
    </draggable>

    <div class="highlight-field__actions">
        <button
            type="button"
            class="btn btn--link-secondary btn--small"
            @click="onClickAddHighlight"
        >
            <svg-icon icon="plus" /> Highlight toevoegen
        </button>
    </div>
</div>
