
<div
    v-if="community.logo_url"
    class="community-logo"
>
    <img
        :src="community.logo_url"
        :alt="community.name"
    >
</div>
