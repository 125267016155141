<template>
    <dn-modal
        name="broadcastModal"
        size="medium"
        position="centered"
    >
        <modal-content
            v-if="modalData"
            class="modal-content--form"
        >
            <h1>{{ $t('modal.broadcast.title') }}</h1>

            <form
                class="form"
                data-vv-scope="broadcast"
                @submit.prevent="sendBroadcast"
            >
                <form-field>
                    <template>
                        <label class="label label--inline">
                            {{ $t('modal.broadcast.to.label') }}:
                        </label>

                        <selected-users :users="modalData.users" />
                    </template>
                </form-field>

                <form-field
                    class="form-field--spacing-sm"
                    :error-messages="errors.collect('broadcast.message')"
                >
                    <label
                        slot="label"
                        for="broadcastMessage"
                    >
                        {{ $t('modal.broadcast.message.label') }}:
                    </label>

                    <textarea
                        id="broadcastMessage"
                        key="broadcastMessage"
                        v-model="formData.message"
                        v-validate.disable="{required: !attachment}"
                        name="message"
                        :placeholder="$t('modal.broadcast.message.placeholder')"
                        rows="6"
                        @input="revalidateFields"
                    />
                </form-field>

                <form-field :error-messages="errors.collect('broadcast.attachment')">
                    <file-attach
                        v-model="attachment"
                        v-validate.disable="{required: !formData.message.length}"
                        name="attachment"
                        @input="revalidateFields"
                    />
                </form-field>

                <llq-button
                    type="submit"
                    class="btn--secondary btn--wide btn--icon-right"
                    :disabled="formData.message.length === 0 && !attachment"
                    :state="requestState"
                >
                    {{ $t('modal.broadcast.submit') }}
                    <svg-icon
                        class="icon--small hidden-xxs hidden-xs"
                        middle
                        flip
                        icon="arrow"
                    />
                </llq-button>
            </form>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import Vue from 'vue';
import { mapGetters } from 'vuex';

import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';
import Button from '~/patterns/atoms/button/button.vue';
import SelectedUsers from '~/patterns/molecules/selected-users/selected-users.vue';
import MessageApi from '~/api/messages';
import FileAttach from '~/patterns/atoms/file-attach/file-attach.vue';
import validatorDictionary from '~/patterns/organisms/modal/locales/validator/dictionary';

export default {
    components: {
        SelectedUsers,
        DnModal,
        ModalContent,
        FormField,
        FileAttach,
        LlqButton: Button
    },

    data() {
        return {
            formData: {
                message: ''
            },

            attachment: null,
            proposal: null,
            requestState: null
        };
    },

    computed: {
        ...mapGetters({
            modalData: 'modal/data'
        })
    },

    watch: {

    },

    methods: {
        // Manual implementation of vee-validate due to custom validate with/without rules that don't work in
        // vanilla
        revalidateFields() {
            // Custom message dict for this component only
            this.$validator.localize(validatorDictionary);
            // Ensure data-in-template isn't a "tick behind".
            Vue.nextTick(() => {
                this.$validator.validateAll('broadcast');
            });
        },

        sendBroadcast() {
            this.$validator.validateAll('broadcast').then((result) => {
                if (result !== false) {
                    const transformedMessage = this.transformMessage({
                        content: this.formData.message,
                        attachment: this.attachment
                    });
                    this.requestState = 'loading';

                    return MessageApi.create(transformedMessage).then((response) => {
                        // Refresh assignment
                        this.$store.dispatch('assignments/fetchById', this.modalData.assignmentId);

                        // Populate threads
                        this.modalData.threadIds.forEach((threadId) => {
                            this.$store.dispatch('threads/addMessageToThread', {
                                message: response.data,
                                threadId
                            });
                        });

                        // Set state
                        this.requestState = 'success';
                        setTimeout(() => {
                            this.$store.dispatch('modal/closeModal');
                            this.formData.message = '';
                            this.attachment = null;
                        }, 1000);
                    }).catch((error) => {
                        console.error(error);
                        this.requestState = 'fail';
                    }).then(() => {
                        setTimeout(() => {
                            this.requestState = undefined;
                        }, 1000);
                    });
                }
            });
        },

        transformMessage({ content, attachment = null }) {
            const transformedMessage = {
                threads: this.modalData.threadIds,
                type: 'broadcast',
                content,
                media: []
            };

            if (attachment) {
                transformedMessage.media.push(attachment);
            }

            return transformedMessage;
        }
    }
};
</script>
