<template>
    <div
        v-if="users"
        class="selected-users"
        :data-light="!!light"
    >
        <h4
            v-if="showTitle"
            class="selected-users__title"
        >
            {{ $t('selectedUsers.title') }}
        </h4>

        <div
            v-for="(user, index) in slicedUsers"
            :key="user.id"
            class="selected-users__item"
            :style="{
                'zIndex': index
            }"
        >
            <avatar
                :user="user"
                :class="{ 'avatar--light': !!light }"
            />
        </div>

        <div
            v-if="users.length > maxUsers"
            class="selected-users__item"
            :style="{
                'zIndex': slicedUsers.length
            }"
        >
            <avatar
                class="avatar--overflow"
                :link-to-profile="false"
            >
                +{{ users.length - maxUsers }}
            </avatar>
        </div>

        <div
            v-if="allowSelect"
            class="selected-users__item"
            :style="{
                'zIndex': slicedUsers.length
            }"
        >
            <transition
                name="fade"
                mode="out-in"
            >
                <button
                    v-if="slicedUsers.length > 0"
                    v-tooltip="{ content: tooltipLabel }"
                    class="btn btn--secondary btn--circle"
                    :title="tooltipLabel"
                    @click="$emit('click-select-user')"
                >
                    <svg-icon icon="plus" />
                </button>

                <button
                    v-else
                    class="btn btn--medium"
                    :title="tooltipLabel"
                    @click="$emit('click-select-user')"
                >
                    {{ tooltipLabel }}
                </button>
            </transition>
        </div>
    </div>
</template>

<script>
'use strict';

import Avatar from '~/patterns/atoms/avatar/avatar.vue';

export default {
    components: {
        Avatar
    },

    props: {
        users: {
            required: true,
            type: Array,
            default() {
                return [];
            }
        },

        maxUsers: {
            type: Number,
            default: 6
        },

        showTitle: {
            type: Boolean,
            default: false
        },

        allowSelect: {
            type: Boolean,
            default: false
        },

        light: {
            type: Boolean,
            default: false,
            required: false
        },

        selectTooltipLabel: {
            type: String,
            required: false,
            default: null
        }
    },

    computed: {
        slicedUsers() {
            return this.users.slice(0, this.maxUsers);
        },

        tooltipLabel() {
            if (!this.allowSelect) {
                return false;
            }

            return this.selectTooltipLabel;
        }
    }
};
</script>

<style lang="less">
@import './selected-users.less';
</style>
