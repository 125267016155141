<template>
    <div class="radio">
        <label :for="id">
            <input
                :id="id"
                type="radio"
                :name="name"
                :value="value"
                :class="className"
                :required="required"
                :disabled="disabled"
                :checked="state"
                @change="onChange"
            >

            <span class="radio__custom" />

            <span
                v-if="!!$slots.default"
                class="radio__label"
            ><slot /></span>
        </label>
    </div>
</template>

<script>
export default {
    // See http://vee-validate.logaretm.com/v2/concepts/components.html
    $_veeValidate: {
        name() {
            return this.name;
        },
        value() {
            return this.modelValue;
        }
    },

    model: {
        prop: 'modelValue',
        event: 'input'
    },

    props: {
        id: {
            type: String,
            default() {
                return 'radio-id-' + this._uid;
            }
        },

        name: {
            type: String,
            default: null
        },

        value: {
            type: [Boolean, String, Number],
            default: null,
            required: false
        },

        modelValue: {
            type: [Boolean, String, Number, Array],
            default: undefined,
            required: false
        },

        className: {
            type: String,
            default: null
        },

        checked: {
            type: Boolean,
            default: false
        },

        required: {
            type: Boolean,
            default: null
        },

        disabled: {
            type: Boolean,
            default: false
        },
    },

    computed: {
        state() {
            if (this.modelValue === undefined) {
                return this.checked;
            }

            return this.modelValue === this.value;
        }
    },

    watch: {
        checked(newValue) {
            if (newValue !== this.state) {
                this.toggle();
            }
        }
    },

    mounted() {
        if (this.checked && !this.state) {
            this.toggle();
        }
    },

    methods: {
        onChange() {
            this.toggle();
        },

        toggle() {
            this.$emit('input', this.state ? '' : this.value);
        }
    }
};
</script>

<style lang="less">
@import './radio.less';
</style>

