
<div class="user-highlight">
    <h5 class="user-highlight__title">{{ highlight.title }}</h5>
    <p
        v-if="highlight.description"
        class="user-highlight__description"
    >
        {{ highlight.description }}
    </p>
    <img
        v-if="imageUrl"
        class="user-highlight__image"
        :src="imageUrl"
        :alt="highlight.title"
    >
</div>
