<template>
    <dn-modal
        name="conflict-check"
        position="centered"
        size="medium"
        class="conflict-check-modal"
        :allow-close="false"
    >
        <modal-content>
            <template #default>
                <h1 class="conflict-check-modal__title">
                    {{ $t('conflict-response.title') }}
                </h1>

                <p
                    class="conflict-check-modal__intro"
                >
                    {{ $t('conflict-response.content') }}
                </p>

                <p
                    v-if="assignment.conflict_check"
                    class="conflict-check-modal__content"
                >
                    {{ assignment.conflict_check }}
                </p>

                <div class="conflict-check-modal__radio">
                    <llq-radio
                        v-model="hasConflict"
                        class="radio--flex"
                        name="conflict-of-interest"
                        :value="true"
                    >
                        {{ $t('conflict-response.conflict-of-interest') }}
                    </llq-radio>

                    <llq-radio
                        v-model="hasConflict"
                        class="radio--flex"
                        name="no-conflict"
                        :value="false"
                    >
                        {{ $t('conflict-response.no-conflict') }}
                    </llq-radio>
                </div>

                <llq-button
                    class="btn--secondary"
                    :disabled="typeof hasConflict === 'undefined' && requestState !== 'loading'"
                    :state="requestState"
                    @click="handleSubmit"
                >
                    {{ $t('conflict-response.confirm') }}
                </llq-button>
            </template>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';

import ModalContent from '../../modal-content/modal-content.vue';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import Radio from '~/patterns/atoms/radio/radio.vue';
import Button from '~/patterns/atoms/button/button.vue';
import createConflictCheck from '~/graphql/mutations/createConflictCheck.graphql';
import Bugsnag from '@bugsnag/js';

export default {
    components: {
        ModalContent,
        DnModal,
        'llq-radio': Radio,
        'llq-button': Button

    },

    data() {
        return {
            conflictCheck: null,
            requestState: null,
            hasConflict: undefined
        };
    },

    computed: {
        ...mapGetters({
            isOpen: 'modal/isOpen',
            modalData: 'modal/data'
        }),

        modalOpen() {
            return this.isOpen('conflict-response');
        },

        assignment() {
            return this.modalData?.assignment;
        },

        responseCount() {
            if (this.assignment.isPushed && this.assignment.responses_count) {
                return this.assignment.responses_count;
            } else {
                return this.assignment.assignment_threads_with_reply_count || 0;
            }
        },

        assignmentRoute() {
            return {
                name: 'assignment.detail',
                params: { id: this.assignment?.id }
            };
        }
    },

    methods: {
        async handleSubmit() {
            let confirmed = false;
            if (this.hasConflict) {
                confirmed = window.confirm(this.$t('conflict-response.confirmation-message'));
                if (!confirmed) {
                    // Abort, allow user to make a different choice
                    return;
                }
            }

            // Run mutation with choice
            this.requestState = 'loading';
            try {
                const { data } = await this.$executeGqlQuery(createConflictCheck, { assignmentId: this.assignment.id, isAccepted: !this.hasConflict });
                this.conflictCheck = data.createConflictCheck;
            } catch (error) {
                this.requestState = 'fail';
                Bugsnag.notify(error);
                console.log(error);
                return;
            }

            if (this.conflictCheck?.accepted_at) {
                this.viewAssignment();
            } else {
                this.$eventBus.$emit('conflict-indicated', this.hasConflict);
                this.$store.dispatch('modal/closeModal');
                this.$store.dispatch('toast/add', this.$t('conflict-response.message'));

                if (this.$route.name !== 'dashboard') {
                    return this.$router.push({
                        name: 'dashboard'
                    });
                }
            }
        },

        viewAssignment() {
            // Just immediately visit the assignment, the response modal will show there if needed
            if (this.$route.name !== 'assignment.detail') {
                this.$router.push(this.assignmentRoute);
            }
            // If we're already there, dispatch the event to refresh instead
            this.$eventBus.$emit('conflict-indicated', this.hasConflict);
            this.$store.dispatch('modal/closeModal');
        },
    }
};
</script>

<style lang="less">
.conflict-check-modal {
    .modal__footer,
    .modal-content {
        padding: 2rem 1.875rem;
    }
}

.conflict-check-modal__intro,
.conflict-check-modal__content {
    margin-bottom: 1.5rem;
}


p.conflict-check-modal__intro {
    .font-primary-regular();
}

.conflict-check-modal__content {
    .font-primary-light();
    white-space: pre-wrap;
}

.conflict-check-modal__radio {
    margin: 1.5rem 0;
}
</style>
