'use strict';

// Dashboard
const DashboardView = () => import('~/patterns/pages/dashboard/dashboard.vue');
const CommunityView = () => import('~/patterns/pages/community/community.vue');
const AssignmentsView = () => import('~/patterns/pages/assignment-overview/overview.vue');

// Assignment
const AssignmentCreateView = () => import('~/patterns/pages/assignment-create/create.vue');
const AssignmentDetailView = () => import('~/patterns/pages/assignment-detail/assignment-detail.vue');
const AssignmentRespondView = () => import('~/patterns/pages/assignment-respond/respond.vue');

const AssignmentDetailRepliesView = () => import('~/patterns/pages/assignment-detail/replies.vue');
const AssignmentDetailCandidatesView = () => import('~/patterns/pages/assignment-detail/candidates.vue');
const AssignmentDetailProposalsView = () => import('~/patterns/pages/assignment-detail/proposals.vue');

const AssignmentThreadView = () => import('~/patterns/pages/thread/thread.vue');

// Proposals
const ProposalDetailView = () => import('~/patterns/pages/proposal/detail.vue');

// Users
const UserDetailView = () => import('~/patterns/pages/user/user.vue');

export default [
    /* Dashboard */
    {
        name: 'dashboard',
        path: '/',
        component: DashboardView,
        meta: {
            header: {
                visible: true
            },
            subnav: 'main'
        }
    },

    /* Community / experts */
    {
        name: 'community',
        path: '/community',
        component: CommunityView,
        meta: {
            header: {
                visible: true
            },
            subnav: 'main'
        }
    },

    /* Assignment */
    {
        name: 'assignments',
        path: '/assignments',
        component: AssignmentsView,
        meta: {
            header: {
                visible: true
            },
            subnav: 'main'
        }
    },

    {
        name: 'assignment.create',
        path: '/assignment/create',
        component: AssignmentCreateView,
    },

    {
        name: 'assignment.update',
        path: '/assignment/update',
        component: AssignmentCreateView
    },

    {
        name: 'assignment.detail',
        path: '/assignment/:id',
        component: AssignmentDetailView,
        meta: {
            subnav: 'assignment'
        },
        children: [
            {
                path: '',
                redirect: 'replies' // default child path
            },
            {
                name: 'assignment.detail.replies',
                path: 'replies',
                component: AssignmentDetailRepliesView
            },
            {
                name: 'assignment.detail.candidates',
                path: 'candidates',
                component: AssignmentDetailCandidatesView
            },
            {
                name: 'assignment.detail.proposals',
                path: 'proposals',
                component: AssignmentDetailProposalsView
            }
        ]
    },

    {
        name: 'assignment.thread',
        path: '/assignment/:assignmentId/thread/:threadId/:detail?',
        component: AssignmentThreadView
    },

    {
        name: 'assignment.respond',
        path: '/assignment/:id/respond',
        component: AssignmentRespondView
    },

    /* Proposals */
    {
        name: 'proposal.detail',
        path: '/proposal/:id',
        component: ProposalDetailView
    },

    /* Users */
    {
        path: '/users/:id',
        component: UserDetailView
    }
];
