<template>
    <dn-modal
        name="save-user-selection-modal"
        position="centered"
        size="medium"
    >
        <modal-content>
            <section>
                <h2>{{ $t('modal.save-user-selection.modal-title') }}</h2>
            </section>

            <section>
                <p>{{ $t('modal.save-user-selection.modal-text') }}</p>
                <form
                    class="form"
                    data-vv-scope="save-user"
                    @submit.prevent="handleSubmit"
                >
                    <form-field
                        class="form-field--last"
                        :error-messages="errors.collect('save-user.content')"
                    >
                        <label
                            slot="label"
                            for="title"
                        >
                            {{ $t('modal.save-user-selection.title-label') }}
                        </label>

                        <input
                            v-model="title"
                            v-validate="'required'"
                            name="title"
                            type="text"
                            :placeholder="$t('modal.save-user-selection.title-placeholder')"
                            resize="vertical"
                        >
                    </form-field>
                </form>
            </section>

            <section>
                <llq-button
                    class="btn btn--secondary"
                    :state="requestState"
                    @click.prevent="handleSubmit"
                >
                    {{ $t('modal.save-user-selection.submit') }}
                </llq-button>
            </section>
        </modal-content>
    </dn-modal>
</template>

<script>
'use strict';

import { mapGetters, mapActions } from 'vuex';

import LlqButton from '~/patterns/atoms/button/button.vue';
import DnModal from '~/patterns/organisms/modal/modal.vue';
import ModalContent from '~/patterns/organisms/modal-content/modal-content.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';

import createUserSelection from '~/graphql/mutations/createUserSelection.graphql';

export default {
    components: {
        LlqButton,
        DnModal,
        ModalContent,
        FormField
    },

    data() {
        return {
            title: null,
            requestState: null
        };
    },

    computed: {
        ...mapGetters({
            modalData: 'modal/data'
        }),

        selection() {
            return this.modalData?.selection;
        }
    },

    methods: {
        ...mapActions({ closeModal: 'modal/closeModal' }),

        async saveUserSelection() {
            this.requestState = 'loading';
            try {
                const { data } = await this.$executeGqlQuery(createUserSelection, { title: this.title, selection: this.selection });

                this.$eventBus.$emit('user-selection-saved', { id: data.createUserSelection?.id });
                this.requestState = 'success';
            } catch (error) {
                console.error(error);
                this.requestState = 'fail';
            }
        },

        handleSubmit() {
            this.$validator.validateAll('save-user').then(async(noErrors) => {
                if (!noErrors) {
                    return;
                }

                await this.saveUserSelection();
                if (this.requestState === 'success') {
                    this.$store.dispatch('modal/closeModal', { name: 'save-user-selection-modal' });

                    this.$store.dispatch('toast/add', {
                        content: this.$i18n.t('modal.save-user-selection.success'),
                    });
                    this.requestState = null;
                    this.title = null;
                }
            });
        }
    },
};
</script>
