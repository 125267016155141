<template>
    <div class="aside-view">
        <header class="aside-view__header">
            <button
                type="button"
                class="aside-view__toggle"
                @click="toggleSidebar"
            >
                <svg-icon icon="filter" /> {{ $t('asideTemplate.show-filters') }}
            </button>
        </header>

        <div
            v-if="!!$slots.intro"
            class="aside-view__intro"
        >
            <slot name="intro" />
        </div>

        <aside
            class="aside-view__sidebar"
            :data-sticky="sticky ? 'true' : 'false'"
        >
            <div class="aside-view__sidebar__body">
                <slot name="sidebar" />
            </div>

            <div class="aside-view__sidebar__footer">
                <button
                    type="button"
                    class="aside-view__toggle"
                    @click="toggleSidebar"
                >
                    <svg-icon icon="filter" /> {{ $t('asideTemplate.show-results') }}
                </button>
            </div>
        </aside>

        <div class="aside-view__body">
            <div
                v-if="!!$slots.actions"
                class="aside-view__actions"
            >
                <slot name="actions" />
            </div>

            <slot name="body" />
        </div>
    </div>
</template>

<script>
'use strict';

export default {
    props: {
        sticky: {
            default: false
        }
    },

    computed: {
        sidebarOpen() {
            return this.$store.getters['ui/sidebarOpen'];
        }
    },

    methods: {
        toggleSidebar() {
            this.$store.commit('ui/sidebarOpen', !this.sidebarOpen);
        }
    }
};
</script>

<style lang="less">
@import './aside-view.less';
</style>
