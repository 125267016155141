export function formValidationHandler(response) {
    if (response.data.errors) {
        for (const fieldName in response.data.errors) {
            if (Object.prototype.hasOwnProperty.call(response.data.errors, fieldName)) {
                for (const fieldError of response.data.errors[fieldName]) {
                    // @TODO run code trough i18n for human readable messages
                    const message = `There was a validation error for ${fieldName} with code "${fieldError.code}"`;
                    this.$store.dispatch('toast/add', message);
                }
            }
        }
    } else if (response.data.message) {
        this.$store.dispatch('toast/add', response.data.message);
    }
}
