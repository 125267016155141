export const VIEW_ASSIGNMENT = 'view-assignment';
export const RESPOND_TO_ASSIGNMENT = 'respond-to-assignment';
export const UPDATE_ASSIGNMENT = 'update-assignment';
export const OPEN_ASSIGNMENT = 'open-assignment';
export const ASSIGNMENT_DETAILS = 'assignment-details';
export const CLOSE_ASSIGNMENT = 'close-assignment';
export const CLOSE_ASSIGNMENT_SILENT = 'close-assignment-silent';
export const DELETE_ASSIGNMENT = 'delete-assignment';
export const FREEZE_ASSIGNMENT = 'freeze-assignment';
export const PUSH_ASSIGNMENT = 'push-assignment';
export const SHARE_PROPOSALS = 'share-proposals';
export const MESSAGE_CLIENT = 'message-client';
export const VIEW_CLIENT_PROFILE = 'view-client-profile';
export const SHOW_WITHDRAWN_PROPOSALS = 'show-withdrawn-proposals';
export const HIDE_WITHDRAWN_PROPOSALS = 'hide-withdrawn-proposals';
