
<div
    class="return-link"
    :class="{
        'return-link--hidden': isHidden
    }"
>
    <transition
        name="fade-absolute"
        mode="out-in"
    >
        <router-link
            v-if="returnLink && returnLink.route"
            :key="returnLink.label"
            :to="returnLink.route"
            class="return-link__link"
        >
            <svg-icon icon="chevron-left" /> {{ returnLink.label || '' }}
        </router-link>

        <span
            v-else-if="isHomePage"
            key="title"
            class="return-link__title"
        >
            {{ community.name || 'LawyerlinQ' }}
        </span>
    </transition>
</div>
