<template>
    <div
        v-show="withToggle ? true : active"
        class="note-field"
        :class="{
            'note-field--active': active
        }"
    >
        <v-button
            v-if="!active && withToggle"
            class="note-field__add btn--medium btn--small"
            @click="toggleNoteField"
        >
            <svg-icon icon="note-plus" />
            {{ $t('noteField.button') }}
        </v-button>



        <form-field v-if="active">
            <label class="form-field__label">{{ $t('noteField.label') }}</label>
            <div class="note-field__field-wrapper">
                <MarkupTextarea
                    v-model="note"
                    :placeholder="$t('noteField.placeholder')"
                    rows="5"
                />

                <v-button
                    v-if="withToggle"
                    class="note-field__remove"
                    @click="removeNote"
                >
                    <svg-icon icon="chat-error" />
                </v-button>
            </div>
        </form-field>
    </div>
</template>

<script>
import FormField from '~/patterns/molecules/form-field/form-field.vue';
import VButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        FormField,
        VButton,
        MarkupTextarea: () => import('~/patterns/molecules/markup-textarea/markup-textarea.vue'),
    },

    props: {
        forceActive: {
            type: Boolean,
            default: undefined,
            required: false
        },
        withToggle: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            active: false,
            note: null
        };
    },

    watch: {
        forceActive: {
            handler(val) {
                if (val !== undefined) {
                    this.active = val;
                }
            },
            immediate: true
        },

        note() {
            this.$emit('input', this.note);
        },

        '$attrs.value': {
            handler() {
                if (this.$attrs.value && this.$attrs.value?.length) {
                    this.active = true;
                    this.note = this.$attrs.value;
                }
            },
            immediate: true
        }
    },

    methods: {
        toggleNoteField() {
            this.active = !this.active;
            this.$emit('update:forceActive', this.active);
        },

        removeNote() {
            this.note = null;
            this.toggleNoteField();
        }
    }
};
</script>

<style lang="less">
.note-field {
    margin-top: 1.75rem;
    width: 100%;
}

.note-field__field-wrapper {
    display: flex;
    align-items: end;

    .markup-textarea {
        flex: 1 0 auto;
    }
}

.note-field__add {
    .icon {
        position: relative;
        top: 2px;
        margin-right: 4px;
    }
}

.note-field__remove {
    flex: 0 0 2.6875rem;
    padding: 0;
    width: 2.6875rem;
    height: 2.6875rem;

    @media @q-md-min {
        margin-left: 1rem;
    }

    .icon {
        position: relative;
        top: 2px;
    }
}

.note-field--active {
    border-top: 1px solid @color-gray-7;
    padding-top: 1.75rem;
}

.note-field--inline {
    border-top: 0;
    padding-top: 0;
    margin-top: .5rem;
}
</style>
