<template>
    <article
        class="card"
        :data-load-status="loadStatus"
        :data-has-extras="hasExtras"
    >
        <div class="card__body">
            <activity-indicator :is-active="isUnread" />

            <slot
                v-if="!!$slots.intro"
                name="intro"
            />

            <template v-if="previewLink">
                <router-link
                    v-if="!!$slots.body"
                    tag="div"
                    :to="previewLink"
                    class="card__preview"
                    data-actionable
                >
                    <slot name="body" />
                </router-link>
            </template>

            <template v-else>
                <div
                    v-if="!!$slots.body"
                    class="card__preview"
                >
                    <slot name="body" />
                </div>
            </template>

            <div
                v-if="hasExtras"
                class="card__extra"
            >
                <div
                    v-if="!!$slots.actions"
                    class="card__actions"
                >
                    <slot name="actions" />
                </div>
            </div>
        </div>

        <div
            v-if="!!$slots.meta || !!$slots.meta2 || !!$slots.status"
            class="card__meta"
        >
            <div
                v-if="!!$slots.meta"
                class="card__meta__left"
            >
                <slot name="meta" />
            </div>

            <div
                v-if="!!$slots.meta2"
                class="card__meta__right"
            >
                <slot name="meta2" />
            </div>

            <div
                v-if="!!$slots.status"
                class="card__status"
            >
                <slot name="status" />
            </div>
        </div>

        <transition name="fade-absolute">
            <div
                v-if="showLoadStatus"
                class="card__load-status"
            >
                <transition
                    name="fade-absolute"
                    mode="out-in"
                >
                    <svg-icon
                        v-if="loadStatus === 'fail'"
                        key="fail"
                        icon="close"
                    />

                    <svg-icon
                        v-if="loadStatus === 'success'"
                        key="success"
                        icon="check"
                    />

                    <spinner
                        v-if="loadStatus === 'loading'"
                        key="loading"
                        class="spinner--small"
                    />
                </transition>
            </div>
        </transition>
    </article>
</template>

<script>
'use strict';

import ActivityIndicator from '~/patterns/atoms/activity-indicator/activity-indicator.vue';
import Spinner from '~/patterns/atoms/spinner/spinner.vue';

export default {

    components: {
        ActivityIndicator,
        Spinner
    },
    props: {
        loadStatus: {
            type: String,
            default: null
        },

        previewLink: {
            default: null
        },

        isUnread: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        hasExtras() {
            return !!this.$slots.actions || !!this.$slots.status;
        },

        showLoadStatus() {
            return !!this.loadStatus;
        }
    }
};
</script>

<style lang="less">
.card {
    position: relative;

    .card-frame();


    + .card {
        margin-top: .75rem;
    }

    @media @q-sm-min {
        &:hover {
            transform: translateX(.15rem);
            z-index: 150;
        }

        + .card {
            margin-top: 1rem;
        }
    }
}

.card__body {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding: 1.25rem;

    @media @q-sm-min {
        padding: 1.875rem;
    }
}

.card__extra {
    flex-shrink: 0;
}

.card__body .activity-indicator {
    position: absolute;
    top: 50%;
    left: 1.25rem;
    transform: translate(-50%, -50%);

    @media @q-sm-min {
        left: 1rem;
    }
}

.card__actions {
    @media @q-md-min {
        margin-left: 2.375rem;
    }
}

.card__preview {
    position: relative;
    .font-primary-light();
    font-size: .875rem;
    line-height: 1.666;

    &[data-actionable] {
        cursor: pointer;
    }

    p {
        font-size: inherit;
    }

    &:first-child {
        margin-top: 0;
    }

    @media @q-sm-min {
        margin-top: 0;
        font-size: 1rem;
    }

    @media @q-md-min {
        font-size: 1.125rem;
    }
}

.card__heading {
    margin: 0 auto;
    padding: 0 3rem 0 0;

    .font-primary-regular();
    font-size: 1.125rem;

    &:last-child {
        margin-bottom: 0;
    }

    @media @q-sm-min {
        font-size: 1.25rem;
    }
}

.card__meta {
    width: 100%;
    padding: 1.25rem;

    background: @color-gray-1;

    @media @q-md-min {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 1.875rem;
        gap: 2.375rem;
    }
}

.card__meta__left {
    @media @q-md-min {
        display: flex;
        flex: 1 1 100%;
        max-width: 32rem;
    }
}

.card__meta__right {
    margin-top: 1.5rem;
    padding-top: 1.25rem;
    border-top: 1px solid @color-gray-7;

    @media @q-md-min {
        display: flex;
        flex: 1 0 auto;
        margin: 0;
        padding: 0;

        border-left: 1px solid @color-gray-7;
        padding-left: 2.375rem;

        border-top: none;
    }
}

.card__status {
    margin-top: 1.5rem;

    @media @q-md-min {
        margin-top: 0;
        border-left: 1px solid @color-gray-7;
        padding-left: 2.375rem;
    }
}

.card__load-status {
    .cover-block();
    background: fade(@color-darkest, 1%);

    .spinner,
    .icon {
        position: absolute;
        top: calc(50% - 1.25rem);
        left: calc(50% - 1.25rem);
    }

    .icon {
        font-size: 2.5rem;
    }

    .icon[data-name='check'] {
        color: @color-highlight-success;
    }

    .icon[data-name='close'] {
        color: @color-highlight-error;
    }
}


/**
 * Modifiers
 */

.card[data-load-status='loading'],
.card[data-load-status='success'],
.card[data-load-status='fail'] {
    .card__body,
    .card__meta {
        filter: blur(2px);
    }
}
</style>
