<template>
    <sub-navigation :links="links" />
</template>

<script>
'use strict';

import { mapGetters } from 'vuex';
import SubNavigation from '~/patterns/molecules/sub-navigation/sub-navigation.vue';

export default {
    computed: {
        ...mapGetters({
            isDashboardTabUpdated: 'activity/isDashboardTabUpdated',
            isCommunityTabUpdated: 'activity/isCommunityTabUpdated',
            hideMembers: 'community/hideMembers'
        }),

        links() {
            const links = [];

            links.push({
                routeName: 'dashboard',
                prettyName: this.$t('subNavigation.main.dashboard'),
                isUnread: this.isDashboardTabUpdated
            });

            if (!(this.hideMembers && (this.isLawyer() || this.isSupplier()))) {
                links.push({
                    routeName: 'community',
                    prettyName: this.$t('subNavigation.main.community'),
                    isUnread: this.isCommunityTabUpdated
                });
            }

            links.push({
                routeName: 'assignments',
                prettyName: this.$t('subNavigation.main.assignments')
            });

            return links;
        }
    },

    components: {
        SubNavigation
    }
};
</script>
