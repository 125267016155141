'use strict';

import { UPDATED_COMMUNITIES, NEW_ASSIGNMENTS, UPDATED_ASSIGNMENTS, UPDATED_THREADS } from '~/constants/activities';

export default {
    [UPDATED_COMMUNITIES]: false,
    [NEW_ASSIGNMENTS]: false,
    [UPDATED_ASSIGNMENTS]: false,
    [UPDATED_THREADS]: false
};
