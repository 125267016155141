'use strict';

// Main dependencies
import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import Cookies from 'js-cookie';

// VueX modules
import activity from './modules/activity/module.js';
import assignments from './modules/assignments/module.js';
import community from './modules/community/module.js';
import enums from './modules/enums/module.js';
import notes from './modules/notes/module.js';
import proposals from './modules/proposals/module.js';
import search from './modules/search/module.js';
import threads from './modules/threads/module.js';
import toast from './modules/toast.js';
import ui from './modules/ui/module.js';
import users from './modules/users/module.js';
import navigation from '~/patterns/organisms/navigation/store/module.js';
import modal from '~/patterns/organisms/modal/store/module.js';
import auth from '~/store/modules/auth/module.js';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        activity,
        assignments,
        auth,
        community,
        enums, // @TODO persist?
        modal,
        navigation,
        notes,
        proposals,
        search,
        threads,
        toast,
        ui,
        users
    },

    plugins: [
        createPersistedState({
            key: 'vuex-persist-short',
            paths: ['ui.haircutTooltipClosed'],
            storage: {
                getItem: key => Cookies.get(key),
                // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
                setItem: (key, value) =>
                    Cookies.set(key, value, { expires: 1, secure: true }),
                removeItem: key => Cookies.remove(key)
            }
        }),
        createPersistedState({
            key: 'vuex-persist-long',
            paths: ['ui.haircutTooltipDoNotShow'],
            storage: {
                getItem: key => Cookies.get(key),
                // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
                setItem: (key, value) =>
                    Cookies.set(key, value, { expires: 365, secure: true }),
                removeItem: key => Cookies.remove(key)
            }
        })
    ],

    strict: import.meta.env.DEV
});

export default store;
