<template>
    <div
        v-if="!notesNotAllowed"
        class="user-notes"
    >
        <h2 class="user-notes__title">
            {{ $t('userNotes.title', {
                communityName: community.name
            }) }}
        </h2>

        <form class="user-notes__form">
            <textarea
                v-model="userNote.note"
                class="user-notes__input input input--textarea input--light input--shadowed"
                :placeholder="$t('userNotes.textarea.placeholder')"
                rows="3"
                :disabled="submitting"
                required
            />

            <v-button
                ref="button"
                :class="`btn--medium ${buttonClass} user-notes__button`"
                :state="buttonState"
                @click.prevent="submitNote"
            >
                {{ $t('userNotes.submit') }}
            </v-button>
        </form>

        <loading-indicator v-if="loading || submitting" />

        <template v-if="!loading">
            <div
                v-if="notes && notes.length"
                class="user-notes__notes"
            >
                <message
                    v-for="note in notes"
                    :key="note.id"
                    class="message--note"
                    :message="note"
                    author-property="author"
                    avatar-position="below"
                >
                    <template #default>
                        <p>{{ note.note }}</p>
                    </template>

                    <template #actions="{ message }">
                        <icon
                            v-if="message.author.id === authUser.id"
                            class="user-notes__delete"
                            icon="trash"
                            @click.native="handleDeleteNote(message)"
                        />
                    </template>

                    <template #meta="{ message }">
                        <span>{{ message.author.full_name }} - <time>{{ message.created_at | dateFormat($t('date-format.date')) }}</time></span>
                    </template>
                </message>
            </div>
        </template>
    </div>
</template>

<script>
'use strict';

import { mapActions, mapGetters } from 'vuex';
import LoadingIndicator from '~/patterns/molecules/loading-indicator/loading-indicator.vue';
import Message from '~/patterns/molecules/message/message.vue';
import Icon from '~/patterns/atoms/icon/icon.vue';
import Button from '~/patterns/atoms/button/button.vue';

export default {
    components: { LoadingIndicator, Message, Icon, VButton: Button },

    props: {
        userId: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            submitting: false,
            buttonState: null,
            notesNotAllowed: false,
            userNote: {
                note: ''
            }
        };
    },

    computed: {
        ...mapGetters({
            getNotes: 'notes/notesForUser',
            authUser: 'auth/user',
            community: 'community/community'
        }),

        notes() {
            return this.getNotes(this.userId);
        },

        buttonClass() {
            return this.userNote.note.length ? 'btn--secondary' : '';
        }
    },

    methods: {
        ...mapActions({
            fetchNotesForUser: 'notes/fetchNotesForUser',
            addNote: 'notes/addNote',
            deleteNote: 'notes/deleteNote',
            addToast: 'toast/add'
        }),

        loadNotes() {
            this.loading = true;

            return this.fetchNotesForUser(this.userId).then(() => {
                this.loading = false;
            }).catch((err) => {
                this.loading = false;
                if (err.response && err.response.status === 403) {
                    this.notesNotAllowed = true;
                } else {
                    this.addToast('Something went wrong when trying to fetch notes.');
                }
            });
        },

        submitNote() {
            this.submitting = true;
            this.buttonState = 'loading';

            this.addNote({ userId: this.userId, note: this.userNote }).then(() => {
                this.submitting = false;
                this.buttonState = 'success';
                this.userNote.note = '';
            }).catch(() => {
                this.buttonState = 'fail';
                this.submitting = false;
                this.addToast('Something went wrong when trying to add your note, please try again.');
            });
        },

        handleDeleteNote(note) {
            if (window.confirm(this.$t('userNotes.delete-confirmation'))) {
                this.deleteNote({ userId: this.userId, id: note.id }).catch((err) => {
                    console.error(err);
                    this.addToast('Something went wrong when trying to delete this note, please try again.');
                });
            }
        }
    },

    created() {
        this.loadNotes();
    }
};
</script>

<style lang="less">
@import './user-notes.less';
</style>
