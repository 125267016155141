
<div class="timeline-field">
    <timeline
        v-for="(highlight, index) in sortedTimeline"
        :key="index"
        :highlight="highlight"
        :editable="true"
        @click-edit="onClickEditHighlight"
        @change="onChangeHighlight"
    >
        <template #label>
            <div class="highlight__date">
                {{ highlight.start }} <template v-if="highlight.end && (highlight.start !== highlight.end)">- {{ highlight.end }}</template>
            </div>
        </template>
    </timeline>

    <div class="timeline-field__actions">
        <button
            type="button"
            class="btn btn--link-secondary btn--small"
            @click="onClickAddHighlight('study')"
        >
            <svg-icon icon="plus" /> Opleiding toevoegen
        </button>

        <button
            type="button"
            class="btn btn--link-secondary btn--small"
            @click="onClickAddHighlight('experience')"
        >
            <svg-icon icon="plus" /> Werkervaring toevoegen
        </button>
    </div>
</div>
