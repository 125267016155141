
<a
    class="file-download"
    :href="downloadUrl"
    :title="`Download ${attachment.name}`"
>
    <svg-icon
        v-if="!hideIcon"
        icon="attachments"
    />

    <span class="file-download__label">
        <slot>{{ attachment.name }}</slot>
    </span>
</a>
