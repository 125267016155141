<template>
    <div class="user-highlight">
        <h5 class="user-highlight__title">{{ highlight.title }}</h5>
        <p
            v-if="highlight.description"
            class="user-highlight__description"
        >
            {{ highlight.description }}
        </p>
        <img
            v-if="imageUrl"
            class="user-highlight__image"
            :src="imageUrl"
            :alt="highlight.title"
        >
    </div>
</template>

<script>
'use strict';

export default {
    props: {
        highlight: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        userId: {
            type: String,
            required: true
        }
    },

    computed: {
        imageUrl() {
            if (this.highlight.image) {
                return this.$root.getImage(`${window.appConfig.api.url}/v1.0/users/${this.userId}/highlights/${this.index}/image/highlight`);
            }
            return null;
        }
    }
};
</script>

<style lang="less">
@import './user-highlight.less';
</style>
